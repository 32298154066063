$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.challenge-filter {
  margin: 40px 0 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  &__item {
    min-height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    font-size: 16px;
    color: #A5AFC0;
    background-color: #162334;
    margin: 0 4px 8px;
    cursor: pointer;

    &.is-active {
      background-color: #EFF5FD;
      color: #162334;
    }
  }
}
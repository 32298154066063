$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.footer__license {
    width: 100%;
    text-align: center;

    &-trigger {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background: none;
        border: none;

    }

    &-trigger-title {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.25em;
        color: $wh;

        .is-open & {
            text-decoration: underline;

            &:after {
                transform: rotate(180deg);
            }
        }

        body.rtl-lang & {
            &:after {
                margin-left: 0;
                margin-right: 2px;
            }
        }

        &:after {
            content: '';
            display: block;
            width: 16px;
            height: 16px;
            flex-shrink: 0;
            margin-left: 2px;
            background-image: var(--icon-i-arrow);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 22px;
            filter: invert(100%) sepia(99%) saturate(2%) hue-rotate(106deg) brightness(105%) contrast(100%);
            transition: transform .2s ease;
        }
    }
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.game-page-wrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.game-page {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 28px;

  .game-page-wrap & {
    flex-grow: 1;
    padding-bottom: 10px;
  }

  @include s {
    padding-top: 40px;
  }

  background-size: cover;
  background-position: center;

  &__game {
    margin: 0 auto;
    background-color: rgba(0, 0, 0, 0.2);
  }

  &__game-wrap {
    margin: 0 auto;
    position: relative;
    display: flex;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    margin: 4px;
  }

  &__fav-btn {
    width: 20px;
    height: 20px;
    background-image: var(--icon-star);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;

    &:hover {
      background-image: var(--icon-star-hover);
    }

    &.active {
      background-image: var(--icon-star-active);
    }
  }

  &__fullscreen-btn{
    width: 14px;
    height: 14px;
    background-image: var(--icon-fullscreen);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
    margin: 10px auto 0;

    &:hover {
      filter: brightness(0) saturate(100%) invert(75%) sepia(99%) saturate(513%) hue-rotate(12deg) brightness(109%) contrast(105%);
    }
  }

  &__frame {
    width: 100%;
    height: 100%;
  }

  &__cta {
    margin-top: 49px;
    position: absolute;
    left: 0;
    bottom: 44px;
    width: 100%;
  }

  &__bottom {
    .game-page-wrap & {
      flex-shrink: 0;
    }
    body.rtl-lang & {
      direction: rtl;
    }
  }

  &__bottom-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 19px 30px;
    background-color: #091322;
    background-image: url($cdn + '/rabona/quick-dep/bg-desktop.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  &__mga-text {
    font-weight: 300;
    font-size: 16px;
    line-height: 1.4;
    color: white;
    opacity: 0.7;

    a {
      color: #ecfd00;

      &:hover {
        color: $green;
      }
    }
  }

  body.rtl-lang & {
    direction: rtl;
  }
}

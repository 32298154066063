$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.widget-events{
  display: block;
  width: 100%;
  height: 100%;

  &__list{
    display: flex;
    align-items: center;
    gap: 42px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-x: auto;
    padding-left: 64px;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__item{
    width: 270px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    gap: 15px;
  }
  &__img{
    display: block;
    width: 88px;
    height: 72px;
    background-size: cover;
    background-position: center;
    flex-shrink: 0;
    border-radius: 2px;
    background-color: rgba(0,0,0,0.2);
  }
  &__content{
    width: 167px;
    flex-shrink: 0;
  }
  &__sum{
    display: flex;
    gap: 5px;
    justify-content: flex-start;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    text-transform: uppercase;
    color: #ECFD00;
  }
  &__sum-currency {
    body.rtl-lang & {
      order: 1;
    }
  }
  &__text{
    margin-top: 2px;
    font-size: 14px;
    line-height: 16px;
    color: #909BAD;
  }
  &__text-login {
    body.rtl-lang & {
      display: inline-flex;
      direction: ltr;
    }
  }
  &__link{
    margin-top: 4px;
    display: block;
    color: #909BAD;
    text-decoration: underline;
    font-size: 14px;
    line-height: 16px;
  }
}
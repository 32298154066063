$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.limit-block {
  padding: 20px 5px;
  width: 210px;
  margin-left: auto;
  margin-right: auto;
  background-color: #293241;
  text-align: center;

  &__icon {
    width: 20px;
    height: 20px;
    margin: 0 auto 16px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    &.is-money {
      background-image: url('data:image/svg+xml,%3Csvg fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 20 20\'%3E%3Cpath d=\'M8.4 11.24c-.1 0-.2 0-.32-.02a2.4 2.4 0 01-.7-.24l-.84.84a.74.74 0 01-1.06 0 .75.75 0 010-1.06l.8-.8a2.25 2.25 0 01-.21-1.73c.11-.4.53-.62.92-.51.4.11.63.53.52.92-.08.27-.01.56.19.75.19.2.41.32.61.35.2.03.31-.05.39-.12.2-.2.4-.4.01-.87a3.56 3.56 0 01-.26-.3c-.74-.94-.7-1.99.12-2.81a1.99 1.99 0 012.37-.33l.67-.67a.75.75 0 011.05 1.05l-.63.64c.28.51.36 1.13.2 1.7a.75.75 0 01-1.44-.4.75.75 0 00-.2-.74c-.06-.06-.6-.58-.97-.2-.16.16-.37.38 0 .83 1.02 1.04 1.07 2.22.13 3.16-.36.37-.83.56-1.35.56z\' fill=\'%23fff\'/%3E%3Cpath fill-rule=\'evenodd\' clip-rule=\'evenodd\' d=\'M3.77 14.81A8.34 8.34 0 018.98 0a8.27 8.27 0 018.08 6.34A8.06 8.06 0 015.64 17.68a8.03 8.03 0 01-1.87-2.87zm2.53 1.4a8.34 8.34 0 0010.95-7 6.57 6.57 0 01-10.95 7zm-1.52-2.5a6.84 6.84 0 014.2-12.2c1.82-.02 3.54.7 4.83 1.98A6.84 6.84 0 014.78 13.7z\' fill=\'%23fff\'/%3E%3C/svg%3E');
    }
    &.is-time {
      background-image: url('data:image/svg+xml,%3Csvg fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 22 20\'%3E%3Cpath d=\'M12.31 3.24V1.7h.72a.85.85 0 100-1.69H8.97a.85.85 0 00-.85.84c0 .47.38.85.85.85h.72v1.55a8.46 8.46 0 00-7.19 8.33A8.47 8.47 0 0011 20c4.69 0 8.5-3.78 8.5-8.43 0-4.2-3.12-7.7-7.19-8.33zM11 18.31a6.78 6.78 0 01-6.8-6.74A6.78 6.78 0 0111 4.83c3.75 0 6.8 3.02 6.8 6.74a6.78 6.78 0 01-6.8 6.74z\' fill=\'%23fff\'/%3E%3Cpath d=\'M11.85 11.22V7.24A.85.85 0 0011 6.4a.85.85 0 00-.85.84v4.47c0 .13.05.27.15.36l2.38 2.35c.34.33.87.33 1.2 0a.84.84 0 000-1.2l-2.03-2z\' fill=\'%23fff\'/%3E%3C/svg%3E');
    }
  }

  &__title {
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 20px;
    line-height: 1.1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__value {
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__text {
    margin-top: 8px;
    font-size: 10px !important;
    line-height: 1.5 !important;
    color: #eff5fd;
  }
}

$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 50%;
  width: fit-content;
  text-align: center;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  padding: 0 20px 12px;
  z-index: 500;
  max-width: 534px;

  body.rtl-lang & {
    direction: rtl;
  }

  &__wrap {
    display: flex;
    align-items: center;
    padding: 6px 8px;
    background-color: #2a394e;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    border: 1px solid #394557;
    @include text(16px, 22px, 400, #fff);
  }

  &__text {
    flex: 1 1 auto;
    margin-inline-end: 12px;

    a {
      color: $yellow;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__btn {
    min-width: 134px;
    flex-shrink: 0;
    padding: 12px 16px;
    @include text(20px, 24px, 400, #fff);
  }
}

$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.accordion {
    counter-reset: section;

    section {
        counter-increment: section;

        h2,
        h1 {
            cursor: pointer;

            &:before {
                content: counter(section);
                position: absolute;
                z-index: 0;
                left: -20px;
                top: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 60px;
                height: 32px;
                font-size: 20px;
                line-height: 20px;
                font-weight: 700;
                color: $wh;
                background-color: $red;
                box-shadow: 4px 5px 1px rgba(0, 0, 0, .25);
                transform: skewX(-22deg);
                body.rtl-lang & {
                    left: auto;
                    right: -15px;
                }
            }

            &:after {
                content: '';
                position: absolute;
                right: 20px;
                top: 31px;
                width: 22px;
                height: 22px;
                background-image: var(--icon-arr-down);
                transition: var(--animation-duration) transform;
                filter: $whFilter;
                body.rtl-lang & {
                    right: inherit;
                    left: 20px;
                }
            }

            & ~ * {
                @include combine(width, 600px, 776px);
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 0;
                max-height: 0;
                overflow: hidden;
                transition: var(--animation-duration) mex-height, var(--animation-duration) margin;
            }

            &.open {
                background-size: 100% 100%;
                margin-bottom: 20px;

                & ~ * {
                    max-height: 100%;
                    margin-bottom: 20px;
                }

                &:after {
                    transform: rotate(180deg)
                }

                & ~ p{
                    body.rtl-lang & {
                    }
                    & > span{
                        font-weight: 700;
                        margin-right: 12px;
                        body.rtl-lang & {
                            margin-right: 0;
                            margin-left: 12px;
                            float: right;
                        }
                    }
                }
            }
        }
    }

    &.info_article {
        section {
            h2,
            h1 {
                &:before {
                    display: none;
                }
            }
        }
    }
}
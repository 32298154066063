$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.bankid {
  position: relative;
  display: flex;
  flex: 1;
  &__section {
    flex: 1 0 50%;
    width: 50%;
    &_left {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 80px 60px 0 60px;

      @include m {
        padding-top: 0;
      }
    }

    &_right {
      @include combine(padding, 0 50px, 0 60px);
      background-color: $red;
    }
  }

  &__back {
    position: absolute;
    top: 40px;
    left: 30px;
  }

  &__main {
    width: 100%;
    max-width: 600px;
    margin: auto 0;
  }

  &__header {
    display: grid;
    grid-template-areas:
      'title side'
      'subtitle subtitle'
      'line line';
    margin-bottom: 40px;
  }

  &__title {
    grid-area: title;
    @include combine(font-size, 38px, 42px);
    line-height: 120%;
    color: $wh;
  }

  &__subtitle {
    grid-area: subtitle;
    font-size: 16px;
    line-height: 19px;
    color: $blueGray;
    margin-top: 7px;
  }

  &__line {
    grid-area: line;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 4px;
    margin-top: 20px;

    &::after,
    &::before {
      content: '';
      flex: 0 calc(50% - 2px);
      background: $blueLight linear-gradient($red, $red) left top / 0 100% no-repeat;
      transition: var(--animation-duration) background-size;
      transform: skew(-20deg);
    }

    &::before {
      background-size: 100% 100%;
    }

    &_1::after {
      background-size: 100% 100%;
    }
  }

  &__side {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 25px;
  }

  &__icon-trustly {
    width: 80px;
    height: 30px;
    background-size: contain;
    background-position: right center;
    background-repeat: no-repeat;
  }

  &__dep {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    color: $blueDark;

    padding: 0 10px;

    background-color: $yellow;
    border-radius: 2px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    min-height: 244px;
  }

  &__btns {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  &__btn {
    font-size: 18px;
    font-weight: 700;
    line-height: 54px;
    text-align: center;
    padding: 0;
    background-color: $blueStroke;
    border: 1px solid $blueLight;
    border-radius: 2px;
    cursor: pointer;

    &.is-active {
      color: $blueDark;
      background-color: $yellow;
    }
  }

  &__input {
    width: 100%;
    max-width: 408px;
    margin: 28px auto auto auto;
  }

  &__text {
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: $blueGray;

    margin-top: 40px;

    a {
      color: $yellow;
      text-decoration: none;
    }
  }

  &__submit {
    align-self: center;
    min-width: 258px;
  }

  &__frame {
    height: 436px;
  }
}

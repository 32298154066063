$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.quick-dep {
  $root: &;

  padding-left: 30px;
  padding-right: 30px;
  margin-left: auto;

  &__btn {
    display: inline-flex;
    align-items: center;
    padding: 10px 20px;
    height: 53px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 0;
    min-width: 0;

    font-weight: bold;
    font-size: 20px;
    letter-spacing: 0.01em;
    color: #0b1222;

    &::before {
      content: '';
      width: 28px;
      height: 28px;
      margin-right: 10px;
      flex-shrink: 0;
      background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28 17'%3E%3Cpath d='M27.4 4.32A3.08 3.08 0 0024.37.5H12.53c-1.3 0-2.47.8-2.92 2.01l-.67 1.8H27.4z' fill='%230B1222'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.96 6.96L6.8 10.1c-.74 2 .75 4.13 2.91 4.13h7.48a5.16 5.16 0 018.1-4.22l1.15-3.06H7.96zm8.06 3.69l-.1.24a.6.6 0 01-.56.37H8.7a.6.6 0 01-.56-.84l.1-.23c.1-.23.32-.38.56-.38h6.67a.6.6 0 01.56.84z' fill='%230B1222'/%3E%3Cpath d='M2.15 7.26h3.77c.3 0 .56-.17.67-.44.2-.48-.15-1-.67-1H2.15c-.3 0-.56.17-.67.44-.2.48.15 1 .67 1zM7.34 3.24H3.57a.72.72 0 110-1.45h3.77a.72.72 0 110 1.45zM.73 11.28H4.5c.3 0 .56-.17.67-.44.2-.48-.15-1-.67-1H.73c-.3 0-.56.18-.67.44-.2.48.15 1 .67 1z' fill='%230B1222'/%3E%3Cpath d='M19.96 14.2h.07l3.92.01-.4.46-1.04 1.03a.53.53 0 00-.05.61c.13.2.37.25.55.1a.42.42 0 00.04-.04l1.96-2.3a.51.51 0 000-.64l-1.96-2.3a.36.36 0 00-.56 0 .45.45 0 00-.03.05c-.11.2-.09.45.05.61l1.05 1.05.4.45h-3.9c-.2 0-.38.16-.42.39-.04.25.1.48.32.52z' fill='%230B1222' stroke='%230B1222' stroke-width='.3'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% auto;
    }

    &::after {
      border-radius: 0;
    }

    span {
      min-width: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &__form {
    display: flex;
    align-items: center;
  }

  &__label {
    display: block;
    max-width: 100%;
    margin-bottom: 2px;
    font-size: 12px;
    line-height: 14px;
    color: white;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__select,
  &__input {
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px 25px;
    height: 53px;
    max-width: 176px;
    background-color: #273242;
    border-right: 1px solid #162334;
  }

  &__select {
    display: none;
    width: 180px;
    max-width: none;
    position: relative;
    z-index: 2;

    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transform: translateX(5px);
    animation-name: showSelect;
    animation-duration: 200ms;
    animation-fill-mode: forwards;

    &:after {
      content: '';
      position: absolute;
      right: 4px;
      bottom: 10px;
      width: 16px;
      height: 16px;
      background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 6'%3E%3Cpath d='M.84.2l4.1 4.04L9.1.2l.88.76L4.94 5.8.02.96.84.2z' fill='%23A5AFC0'/%3E%3C/svg%3E");
      background-size: 10px auto;
      background-repeat: no-repeat;
      background-position: center;
    }

    &.is-opened {
      &:after {
        transform: rotate(180deg);
      }
    }

    .is-show & {
      display: flex;
    }

    .is-hide & {
      display: flex;
      animation-name: hideSelect;
    }

    &.ng-hide  {
      display: none !important;
      transition-delay: 0ms !important;
      animation-delay: 0ms !important;
    }
  }

  &__input {
    display: flex;
    width: 176px;
  }

  &__grid-row {
    display: grid;
    grid-template-columns: min-content 1fr;
    align-items: center;
    column-gap: 6px;

    &.is-input {
      column-gap: 4px;
    }
  }

  &__value,
  &__currency {
    font-size: 16px;
    line-height: 1.1;
  }

  &__value {
    color: #F5F6F9;
  }

  &__currency {
    color: #768088;
  }

  &__field {
    @extend #{$root}__value;

    display: block;
    padding: 0;
    min-width: 0;
    background-color: transparent;
    border: none;
    outline: none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type="number"] {
      appearance: textfield;
    }

    &.ng-invalid-min,
    &.ng-invalid-max,
    &.ng-invalid-required {
      & ~ #{$root}__input-error {
        display: block;
      }
    }

    &.ng-invalid-min ~ #{$root}__input-error .g-min,
    &.ng-invalid-max ~ #{$root}__input-error .g-max,
    &.ng-invalid-required ~ #{$root}__input-error .g-required {
      display: block;
    }
  }

  &__input-error {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 1px 20px 0 0;
    min-height: 18px;
    background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Ccircle cx='8' cy='8' r='7.2' fill='%23FF446C'/%3E%3Cpath d='M8.02 12.6a.82.82 0 100-1.65.82.82 0 000 1.64zM8.65 3.2h-1.3v6.53h1.3V3.2z' fill='%23fff'/%3E%3C/svg%3E");
    background-position: right top 1px;
    background-repeat: no-repeat;
    background-size: 16px auto;
    z-index: 10;
  }

  &__input-error-text {
    font-size: 10px;
    line-height: 12px;
    color: #ff2469;
    @include ellipsis;
    padding-top: 2px;
  }

  &__card {
    width: 22px;
    height: 22px;
  }

  &__card-image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  &__select-window {
    display: none;
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 100%;
    // box-shadow: 0px -8px 16px 0 rgba(0, 0, 0, 0.35);
    max-height: 209px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.35);

    overflow: hidden;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 37px;
      background-image: linear-gradient(to top, #273242 1.04%, rgba(39, 50, 66, 0) 100%);
      z-index: 1;
    }

    .is-opened & {
      display: flex;
    }

  }

  &__select-scroll {
    width: 100%;
    overflow-y: auto;
  }

  &__add-card {
    display: inline-flex;
    align-items: center;
    padding-left: 25px;
    padding-right: 12px;
    width: 100%;
    height: 53px;
    font-size: 14px;
    line-height: 1.1;
    color: white;
    overflow: hidden;
    cursor: pointer;
    outline: none;
    border: none;
    background-color: #273242;
    border-bottom: 1px solid #162334;

    &:hover {
      background-color: #162334;
    }

    &::before {
      content: '';
      width: 22px;
      height: 22px;
      flex-shrink: 0;
      margin-right: 6px;
      background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 20'%3E%3Cpath d='M11 6H.57v12.8h16.29v-7.15A6.4 6.4 0 0110.99 6z' fill='%23fff' fill-opacity='.15'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.8 8h4.65v3.1H2.79V8zm1.39 1.4H6v.3H4.2v-.3z' fill='%23A5AFC0'/%3E%3Cpath d='M2.8 15.03v1.5h3.48v-1.5H2.8zM6.78 15.03h3.5v1.5h-3.5v-1.5zM10.76 15.03v1.5h3.5v-1.5h-3.5z' fill='%23A5AFC0'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.95 11.1v6.88H1.4V6.68h9.77v-1.4H0v14.1h17.35v-8.29h-1.4z' fill='%23A5AFC0'/%3E%3Cpath d='M19.93 8.63l-2.7.58-2.87-1.04-1.3-3.02 1.17-2.4a4.05 4.05 0 017.16 2.53c0 .87-.28 1.71-.8 2.4l-.66.95z' fill='%23fff' fill-opacity='.15'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.04 2.95h-1.4v1.63h-1.52v1.4h1.59V7.6h1.4V5.98h1.57v-1.4h-1.64V2.95z' fill='%23A7AFBE'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.35.62a4.65 4.65 0 100 9.31 4.65 4.65 0 000-9.3zm0 8a3.35 3.35 0 110-6.7 3.35 3.35 0 010 6.7z' fill='%23A7AFBE'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% auto;
    }

    span {
      min-width: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &__select-row {
    height: 53px;
    background-color: #273242;
    padding-left: 25px;
    padding-right: 12px;

    &:hover {
      background-color: #162334;
    }
    &:last-child {
      position: relative;
      z-index: 5;
    }
    &:not(:last-child) {
      border-bottom: 1px solid #162334;
    }
  }

  &__status-btn {
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    min-width: 180px;
    height: 53px;
    background-color: transparent;

    font-weight: bold;
    font-size: 20px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    border-radius: 0;
    line-height: 1.1;

    cursor: pointer;
    outline: none;
    border: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    opacity: 0;
    visibility: hidden;
    transform: translateX(5px);
    animation-name: showSelect;
    animation-duration: 200ms;
    animation-fill-mode: forwards;

    .is-show & {
      display: inline-flex;
    }

    .is-hide & {
      display: inline-flex;
      animation-name: hideSelect;
    }

    &.ng-hide  {
      display: none !important;
      transition-delay: 0ms !important;
      animation-delay: 0ms !important;
    }

    &:hover {
      background-color: #273242;
    }

    &.is-success {
      color: #ecfd00;
    }
    &.is-rejected {
      color: #cc0034;
    }
  }

  &__preloader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 53px;
    width: 53px;
  }

  &__preloader-item {
    width: 34px;
    height: 34px;
    border: 3px solid white;
    animation-name: preloading;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    border-radius: 50%;
  }

  @include s {
    padding-right: 120px;
    padding-left: 120px;
  }
}

@keyframes preloading {
  50% {
    transform: scale(.8);
  }
}

@keyframes showSelect {
  100% {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
}

@keyframes hideSelect {
  0% {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    visibility: hidden;
    transform: translateX(5px);
  }
}

$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.profile-header{
  display: flex;
  align-items: flex-end;
  justify-content: space-between;


  &__session{
    font-size: 14px;
    line-height: 150%;
    color: #A7AFBE;

    strong {
      display: inline-block;
      direction: ltr !important;
    }
  }
}
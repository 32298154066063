$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.season-popup {
  .popup {
    &__wrap {
      padding-bottom: 24px;
      padding: 24px;
      width: 520px;
    }

    &__content {
      text-align: center;
    }
    
    &__close {
      top: 24px;
      inset-inline-end: 24px;
    }
  }

  &__title {
    font-size: 32px;
    color: #fff;
    line-height: 1;
    margin-bottom: 20px;
  }

  &__text {
    font-size: 16px;
    line-height: 1.5;
    color: #A5AFC0;
    margin-bottom: 12px;

    > span {
      display: inline-block;
    }
  }

  &__image {
    margin-bottom: 20px;
  }

  &__btns {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    align-items: center;
  }

  &__cta {
    --btnHeight: 64px;
    font-size: 24px;
    min-width: 184px;
  }

  &__details {
    text-decoration: underline;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.14px;
    font-size: 14px;
  }
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.profile-bonus-bnr{

  margin-top:27px;
  height: 284px;
  display: flex;



}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.has-text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

body.linda-has-user .show-guest {
  display: none;
}

body:not(.linda-has-user) .show-user {
  display: none;
}





.has-mt-40 {
  margin-top: 40px;
}

.has-mt-32 {
  margin-top: 32px;
}

.has-mt-20 {
  margin-top: 20px;
}

.has-mt-16 {
  margin-top: 16px;
}

.has-mt-8 {
  margin-top: 8px;
}

.is-center {
  text-align: center;
}


.is-centered {
  margin: 0 auto;
}

.has-mt-40{ margin-top:40px; }
.has-mt-32{ margin-top:32px; }
.has-mt-20{ margin-top:20px; }
.has-mt-16{ margin-top:16px; }
.has-mt-8{ margin-top:8px; }
.is-center{ text-align: center; }
.is-left{ text-align: left !important; }
.is-centered{ margin: 0 auto; }




/* ANIMATION NG-SHOW */
.is-animate-show-hide.ng-hide {
  opacity: 0;
}

.is-animate-show-hide.ng-hide-add,
.is-animate-show-hide.ng-hide-remove {
  transition: opacity linear 0.2s;
}


@keyframes rotate360 {
  100% {
    transform: rotate(-360deg)
  }
}



.from-s {
  @include s-max {
    display: none;
  }
}

.hide-text {
  @include s-1499 {
    display: none;
  }
}

.from-m {
  @include m-max {
    display: none;
  }
}

:root {
  @include combine(--header-h, 106px, 116px);
  @include combine(--header-h-with-verification, 136px, 146px);
}

.linda-view,
.linda-layer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.has-header {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.text-accent {
  color: $yellow;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  @include combine(padding, 0 24px, 0 42px, 0 40px);

  &.is-vip {
    @include combine(padding, 0 24px, 0 42px, 0 83px);
  }
  &.sport-tournament-page {
    @include combine(padding, 0 16px, 0 36px, 0 40px);
  }
}

.full-screen {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--header-h) + 6px);
  margin-top: -6px;

  .has-header:has(.is-verification-changed) & {
    min-height: calc(100vh - var(--header-h-with-verification) + 6px);
  }

  @include s {
    margin-top: -16px;
    min-height: calc(100vh - var(--header-h) + 16px);
    .has-header:has(.is-verification-changed) & {
      min-height: calc(100vh - var(--header-h-with-verification) + 16px);
    }
  }
}

.is-overflow-hid {
  overflow: hidden;
}

$margin-list: 0 2 4 6 8 10 12 15 16 18 19 20 22 24 25 26 28 30 32 34 36 40 48 60 92;

@for $i from 1 through length($margin-list ) {
  .has-margin-bottom-#{nth( $margin-list, $i )} {
    margin-bottom: #{nth( $margin-list, $i )}px !important;
  }

  .has-margin-top-#{nth( $margin-list, $i )} {
    margin-top: #{nth( $margin-list, $i )}px !important;
  }

  .has-margin-left-#{nth( $margin-list, $i )} {
    margin-left: #{nth( $margin-list, $i )}px !important;
  }
}

.has-reset-type-button-styles {
  background: none;
  border: none;
  padding: 0;
  font-size: 1rem;
  color: inherit;
}

.has-content-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-capitalize {
  text-transform: capitalize;
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.sport-ligue {
  display: flex;
  flex-direction: column;
  margin-bottom: 44px;

  &__title {
    @include text(20px, 24px, 700, $wh);
    text-transform: uppercase;
    margin-bottom: 8px;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px 24px;
    margin-bottom: 16px;
  }
  &__item-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 132px;
    height: 132px;
    background-color: #0f1727;
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.14));
    cursor: pointer;
    &:hover {
        filter: none;
        &>img {
            transform: scale(1.03);
            transition: .3s;
        }
    }
  }
  &__img {
    width: 100%;
    height: 36px;
    object-fit: contain;
    object-position: center;
  }
}

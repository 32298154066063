$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.cup-mark {
  position: absolute;
  right: 0;
  bottom:calc(100% - 120px);

  width: 100%;
  max-width: 111px;
  min-height: 60px;

  z-index: 50;
  font-size: 16px;
  line-height: 19px;
  color: rgba(255,255,255,0.5);
  transform: translateY(47px) translateX(180px);
  &.is-center{
    width: 100% ;
    background-image: linear-gradient(270deg, rgba(255,255,255,0) 20%,  rgba(255, 255, 255, 0.5) 50% , rgba(255,255,255,0) 80%);
  }
  &__key{
    color: #fff;
    font-weight: 500;
    margin-bottom: 4px;
    text-align: left !important;
  }
  &__val{
    .is-current &{ font-weight: bold;}
    margin-bottom:6px;
    display: flex;
    align-items: center;
    direction: ltr !important;
  }
  &::before {
    content: '';
    position: absolute;
    top: 5px;
    left: 0;
    width: 7px;
    height: 7px;
    background-color: #576477;
    transform: translateX(-13.5px) rotate(45deg);

  }
  &__point {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 100%;
    width: 16px;
    height: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin-right: 2px;
    color: rgba(255, 255, 255, 0.5);
    padding-top: 2px;
  }
  &.is-complete {
    &::before {
      display: none;
    }
    .cup-mark__key {
      --p: 11px;

      color: #0B1222;
      font-weight: 700;
      background-color: #ECFD00;
      align-items: center;
      display: inline-flex;
      padding: 2px 17px 3px 24px;
      transform: translateX(-26px);
      clip-path: polygon(var(--p) 0, 100% 0, calc(100% - var(--p)) 100%, 0 100%);
      &::before {
        content: "";
        width: 10px;
        height: 10px;

        left: 10px;
        position: absolute;

        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-image: var(--icon-i-check);
        filter: brightness(0) saturate(100%) invert(6%) sepia(8%) saturate(5170%) hue-rotate(185deg) brightness(92%) contrast(98%);
      }
    }
    .cup-mark__val {
      display: none;
    }
  }
}

.cup-progress {
  right: 0;
  bottom: 0;
  width: calc(100% + 120px);
  transform: translateX(62px);
  position:absolute;
  background-position: top;
  background-repeat: no-repeat;
  background-size: calc(100% - 2px) 1px;
  background-image: linear-gradient(270deg, #ECFD00 0%, #ECFD00 53.13%, rgba(236, 253, 0, 0) 100%);
  z-index: 25;
  color:$yellow;
  &__line {
    position: absolute;
    bottom: 0;
    width: 2px;
    background-color: #ECFD00;
    right: -60px;
    z-index: 10;

  }
  &::before{
    content: '';
    top: 0;
    left: 0;
    height: 183px;
    width: 100%;
    display: block;

    position: absolute;
    background-image: url( $cdn + '/rabona/cup-page/cup-%20lens_effect.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    transform: translateY(-50%);
    z-index: 30;
  }
}
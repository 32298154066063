$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.tournament-sport-info {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 326px;
  grid-gap: 0 16px;
  margin-top: 24px;
  margin-bottom: 56px;
  @include s {
    grid-template-columns: 1fr 408px;
    grid-gap: 0 24px;
  }
  &__content {
    padding: 32px 32px 40px;
    height: fit-content;
    background-color: $blue2;
    border-bottom: 1px solid $red1;
    border-top: 1px solid $red1;
    @include m {
      padding: 32px 86px 40px;
    }
  }
  &__leaderboard {
    background-color: $blue2;
    height: fit-content;
  }
  &__subtitle {
    width: fit-content;
    text-align: center;
    margin: 0 auto 12px;
    @include text(20px, 24px, 400, $wh);
    text-transform: uppercase;
    &.has-underline {
        border-bottom: 2px solid $red1;
    }
  }
  &__counter {
    display: flex;
    justify-content: center;
    margin-bottom: 44px;
  }
  &__leaderboard-text {
    @include text(24px, 28px, 400, $wh);
    text-transform: uppercase;
    text-align: center;
  }
  &__howto-description {
    @include text(18px, 27px, 400, $wh);
    margin-bottom: 24px;
    text-align: center;
  }
  &__howto-content {
    display: grid;
    margin-bottom: 24px;
    grid-gap: 24px;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
    grid-auto-flow: column;

    @include s {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
  }
  &__howto-item {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  &__howto-ico {
    width: 64px;
    height: 64px;
    flex-shrink: 0;
    background-color: #0F1727;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 30px;
  }
  &__howto-text {
    @include text(18px, 27px, 400, $wh);
  }
}

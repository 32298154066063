$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.vip-card {
  margin: 40px 0 100px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat( 2 , 1fr );

  @include s {
    margin: 60px 0 100px;
  }

  @include m {
    margin: 100px 0;
    grid-gap: 32px;
  }

  body.rtl-lang & {
    direction: rtl;
  }

  &__item {
    border: 1px solid #293241;
    padding: 20px;
    padding-inline-start: 80px;

    background-repeat: no-repeat;
    background-position: 31px 31px;

    &:nth-child(1) { background-image: url( $cdn + '/rabona/vip/icon-vip/manager.svg')}
    &:nth-child(2) { background-image: url( $cdn + '/rabona/vip/icon-vip/finance.svg')}
    &:nth-child(3) { background-image: url( $cdn + '/rabona/vip/icon-vip/bonuses.svg')}
    &:nth-child(4) { background-image: url( $cdn + '/rabona/vip/icon-vip/nolimits.svg')}

    body.rtl-lang & {
      background-position: top 31px right 31px;
    }

    @include s {
      padding: 24px;
      padding-inline-start: 80px;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 20px;
    line-height: 48px;

    color: #FFFFFF;
    text-transform: uppercase;
  }

  &__text {
    margin-top: 6px;
    color: #A5AFC0;
  }
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.footer {
  padding: 40px 20px;
  background-color: #162334;

  @include s {
    padding: 40px 42px;
  }

  body:has(.footer-casino) & {
    padding-bottom: 70px;
  }

  body:has(.footer-casino__item.active) & {
    padding-bottom: 185px;
  }

  &__content {
    width: 100%;
    max-width: 1844px;
    margin: 0 auto;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;

    body.rtl-lang & {
      direction: rtl;
    }
  }

  &__top-side {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
  }

  &__info-button {
    $this: &;
    height: 44px;
    min-width: 150px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    padding: 6px 10px;
    font-size: 16px;
    line-height: 1em;
    color: $wh;
    background-color: #273242;
    border: 1px solid #2a394e;
    transition-property: background-color, border-color, color;
    transition-duration: 0.2s;
    position: relative;
    cursor: pointer;

    &:hover {
      background-color: #2a394e;
    }
  }

  &__info-button-img {
    width: 23px;
    height: 16px;
    object-fit: cover;
    object-position: center;
    margin-right: 8px;

    body.rtl-lang & {
      margin-left: 8px;
      margin-right: 0;
    }
  }

  &__social {
    width: 44px;
    min-width: auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 28px;
    &.is-instagram {
      background-image: var(--icon-instagram);
    }
    &.is-youtube {
      background-image: var(--icon-youtube);
    }
    &.is-telegram {
      background-image: var(--icon-telegram);
    }
  }

  &__chat {
    cursor: pointer;
    margin-left: auto;
    display: flex;
    align-items: center;
    color: $yellow;
    font-size: 14px;

    body.rtl-lang & {
      &::before {
        margin-right: 0;
        margin-left: 8px;
      }
    }

    &::before {
      margin-right: 8px;
      content: "";
      display: block;
      width: 18px;
      height: 20px;
      background-image: var(--icon-i-support-hover2);
      background-position: -6px center;
      background-repeat: no-repeat;
      filter: brightness(0) $yellowFilter;
    }
  }

  &__inner {
    display: grid;
    grid-auto-columns: 1fr;
    gap: 20px;
    margin-bottom: 42px;

    body.rtl-lang & {
      direction: rtl;
    }
  }

  &__iconbox {
    width: 100%;
    margin-bottom: 8px;
  }

  &__icons {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
  }

  &__icons-wrap {
    display: flex;
  }

  &__icon {
    height: 32px;
    margin-left: 16px;
    margin-right: 16px;
    background-repeat: no-repeat;
    background-position: center;

    &.icon-f-18 {
      width: 32px;
    }

    &.icon-iso {
      width: 28px;
    }

    &.icon-f-7 {
      width: 96px;
    }

    &.is-license {
      width: 30px;

      & img {
        height: 32px;
        width: auto;
      }
    }

    &.icon-igate {
      width: 72px;
    }
    &.icon-pagcor {
      background: url($cdn + "/footer-icons/pagcor-seal.png") center / contain
        no-repeat;
      width: 28px;
    }

    &.icon-gcb {
      height: 31px;
      width: 54px;
      background: url($cdn + "/gcb/icon.png") center / contain no-repeat;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.15em;
    color: rgba(#a5afc0, 0.5);
    margin-top: 16px;

    .footer__license-copyright & {
      margin-top: 0;
    }

    body.rtl-lang & {
      text-align: center;
      direction: ltr;
    }

    &_hidden {
      display: none;

      .is-open & {
        display: block;
      }
    }

    &_visible {
      .is-open & {
        display: none;
      }
    }

    &_capitalize {
      &::first-letter {
        text-transform: capitalize;
      }
    }

    a {
      font-size: 14px;
      line-height: 1.15em;
      text-decoration: underline;
    }
  }

  &__live-chat {
    position: fixed;
    width: 48px;
    height: 48px;
    bottom: 24px;
    right: 24px;
    border: 1px solid #2a394e;
    border-radius: 50%;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    background: #273242 50% / 28px no-repeat;
    z-index: 999;

    &:hover {
      border-color: #394557;
      background-color: #2a394e;
    }

    &:focus {
      border-color: #273242;
      background-color: #162334;
    }

    @include s {
      right: 40px;
    }
  }
}

.footerLicense {
  &-hiddenText {
    font-size: 16px;

    &.is-open {
      button span {
        text-decoration: underline;
      }
    }

    p {
      color: #a5afc080;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.2em;
    }
  }
  .footerLicense-icons {
    gap: 0 24px;
    body.rtl-lang & {
      direction: rtl;
    }
    li {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    a {
      display: flex;
    }

    img {
      width: 100%;
      max-height: 34px;
    }
  }
  .footerLicense-text {
    color: #a5afc080;
    margin-top: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.15em;
  }
}

$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.auth-deposit-interruption {
    width: 100%;
    padding: 6px;
    display: grid;
    gap: 20px;

    .btn {
        width: 100%;
    }

    &__top {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 16px;
        border-bottom: 1px solid #2A394E;
        color: #ffffff;
        text-align: center;
        font-size: 32px;
        font-weight: 400;
        line-height: 1.2em;
    }

    &__bonus {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        min-height: 78px;
        padding: 16px 24px 16px 16px;
        gap: 12px;
        border-radius: 2px;
        background: rgba(236, 253, 0, 0.08);
    }

    &__bonus-img {
        width: 42px;
        height: 42px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
        }
    }

    &__bonus-inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
    }

    &__bonus-title {
        color: #ECFD00;
        font-size: 16px;
        font-weight: 400;
        line-height: 1em;
        letter-spacing: 0.32px;
    }

    &__bonus-text {
        color: #ffffff;
        font-size: 22px;
        font-weight: 700;
        line-height: 1em;
    }

    &__cta {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        text-align: center;
        color: #A5AFC0;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.2em;
        gap: 5px;

        a {
            color: #ECFD00;
            font-size: 18px;
            font-weight: 400;
            line-height: 1.2em;
            text-decoration-line: underline;
        }
    }

    &__exit {
        text-align: center;
    }

    &__link {
        color: #FFF;
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        line-height: 1em;
        text-decoration-line: underline;
        text-transform: uppercase;
    }
}

$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.info-wrap {
  color: white;

  h1 {
    margin-bottom: 22px;
    font-size: 32px;
    line-height: 1.25;
  }

  h2 {
    margin-top: 40px;
    margin-bottom: 12px;
    font-size: 24px;
    line-height: 1.2;
    text-align: center;
  }

  p,
  li {
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 1.5;
  }

  ul,
  ol {
    list-style-position: outside;
    margin-left: 1em;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  a {
    color: $yellow;

    &:hover {
      color: $green;
    }
  }
}

$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;

.notifications{
  position:fixed;
  z-index: 4000;
  top: 10px;
  right: 10px;
  width:425px;

  body.rtl-lang & {
    direction: rtl;
  }

  &__item{
    position:relative;
    width: 420px;
    margin-top:8px;
    opacity:1;
    transition:0.3s;
    max-height: auto;

    &.ng-move,
    &.ng-enter {  transition: 0.5s; }
    &.ng-leave{   transition:0.001s !important;}
    &.ng-leave.ng-leave-active,
    &.ng-move,
    &.ng-enter {

      max-height: 0px;
      transform: translateX(100%) scale(0.6);


    }
    &.ng-leave,
    &.ng-move.ng-move-active,
    &.ng-enter.ng-enter-active { transform: translateX(0%) scale(1);  max-height: 100px; }
  }
  &__row{
    display:flex;
    align-items: center;
    justify-content: center;
    min-height: 64px;
    padding-block: 10px;
    padding-inline-end: 20px;
    padding-inline-start: 13px;
    background: #FFFFFF;

    .notifications__success &{ border-top:solid 2px $green; }
    .notifications__error &{ border-top:solid 2px #CC0034; }
    .notifications__warninf &{ border-top:solid 2px #CC0034; }
  }

  &__close{
    cursor:pointer;
    width: 24px;
    height: 24px;
    transition:0.1s;
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center;
    margin-inline-start: auto;
    flex-shrink: 0;
    align-self: flex-start;
    opacity:0.6;

  }

  &__text{
    flex-grow: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    letter-spacing: 0.02em;
    font-size: 16px;
    line-height: 20px;
    color: #2D394C;

    & span{
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      color: #BFB5C8;
    }
    & p{
      margin-top: 2px;
    }
  }

  &__icon{
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    margin-inline-end: 12px;
    position:relative;
    overflow: hidden;
    border-radius: 4px;
    &::before{
      content: '';
      position:absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }


    //.notifications__success &{
    //  //background: $grNotification ,  linear-gradient(0.17deg, #5AB000 0.14%, #B5F015 99.86%) }
    //.notifications__error &{
    //  //background:  $grNotification  , linear-gradient(4.11deg, #F82B38 -2.33%, #FF838B 84.45%);
    //}
    //.notifications__info &{ background:   $grNotification , linear-gradient(180deg, #3ED7F3 0%, #00BEE1 100%);}
    //.notifications__warning &{ background:  $grNotification , linear-gradient(189.59deg, #FFD600 7.2%, #FF9300 92.75%);}
  }

}



@keyframes moveNot {
  0%{ max-height:0;}
  100%{max-height:400px;}
}
@keyframes showNot {
  0%{ opacity:0;}
}


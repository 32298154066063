$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.bnr-reg {
    position: relative;

    display: flex;
    flex: 1 100%;
    justify-content: center;

    padding-top: 112%;
    margin:  0 auto;
    max-width: 815px;
    
    background-size: contain;
    background-position: center top;
    background-repeat: no-repeat;
    z-index: 2;

    @include m {
        padding-top: 100%;
    }

    &__info {
        position: absolute;
        left: 50%;
        @include combine(bottom, -36px, 84px);
        transform: translateX(-50%);

        display: flex;
        flex: 1;
        flex-direction: column;
        
        max-width: 750px;
        @include combine(width, calc(100% - 100px), calc(100% - 212px));
        @include combine(padding, 22px 29px, 25px 43px);
        

        @include border(8px);
        background-color: rgba($red, .7);
    }

    &__title {
        @include combine(font-size, 28px, 42px);
        font-weight: 700;
        line-height: 120%;
        color: $yellow;

        .small-font & {
          @include combine(font-size, 25px, 35px)
        }
    }
    &__subtitle {
        @include combine(font-size, 36px, 52px);
        @include max-height(--lh, 42px, 5);
        font-weight: 700;
        text-transform: uppercase;
        color: $wh;
        white-space: break-spaces;

        .small-font & {
          @include combine(font-size, 23px, 28px)
        }
        
        @include combine(margin-top, 11px, 3px);

        @include s {
            --lh: 61px;
        }
    }
}
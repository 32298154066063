$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.card-guide-bnr {
  margin: 50px 0;

  body.rtl-lang & {
    direction: rtl;
  }

  &__img {
    padding-top: 36.5%;
    background-color: rgba(255,255,255,0.5);
    background-size: cover;
    background-position: center;
  }

  &__bar {
    position: relative;
    min-height: 110px;
    padding: 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.is-grey {
      background-color: #162334;

      &::before {
        content: '';
        position: absolute;
        inset: 0;
        background-image: url('https://joxi.imgsrcdata.com/rabona/elements/pattern-collections.svg');
        background-repeat: no-repeat;
        background-size: 400px;

        body.rtl-lang & {
          scale: -1 1;
        }
      }
    }

    &.is-red {
      background-color: $red;

      &::before{
        content: '';
        display: block;
        width: 400px;
        height: 100%;
        position:absolute;
        inset-inline-end: 0;
        top: 0;
        z-index: 10 ;
        background-image: url('https://joxi.imgsrcdata.com/rabona/elements/pattern-collections.svg');
        background-repeat: no-repeat;
        background-size: 400px;
        scale: -1 1;

        body.rtl-lang & {
          scale: 1 1;
        }
      }
    }
  }

  &__title {
    position:relative;
    z-index: 20;
    max-width: 500px;
    overflow: hidden;
    font-weight: bold;
    font-size: 38px;
    line-height: 45px;
    text-transform: uppercase;
    color: #FFFFFF;
    white-space: break-spaces;

    > span {
      margin-inline-end: 5px;
      display: inline-block;

      &:last-child {
        margin-inline-end: 0;
      }
    }
  }

  &__btns {
    position:relative;
    z-index: 20;
    display: flex;
    align-items: center;
  }
}

$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.vip-verification {
    position: absolute; 
    top: 120px;
    left: 50%; 
    transform: translateX(-50%);
    max-width: 1839px;
    width: calc(100% - 80px);
    @include m {
        top: 132px;
    }
}
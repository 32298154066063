$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.header-game {
    position: relative;
    display: grid;
    grid-template-areas:
        'back game empty btns';
    grid-template-columns: auto auto 1fr auto;
    align-items: center;
    height: 60px;
    padding-inline-end: 26px;
    filter: drop-shadow( 0px -1px 3px #1D2534);

    background-color: $blue;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 50;

    .game-page-wrap & {
        flex-shrink: 0;
    }

    @include s {
        height: 72px;
    }

    &__game {
        grid-area: game;
        display: flex;
        flex-direction: column;
        margin-inline-start: 35px;

        font-size: 14px;
        line-height: 16px;
        color: $blueGray;

        span:nth-child(1) {
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 4px;
        }

    }

    &__logo {
        position: absolute;
        top: 0;
        left: 50%;
        width: 135px;
        height: 83px;
        transform: translateX(-50%);

        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: center bottom;
        background-image: var(--icon-logo);

        @include s {
            width: 150px;
            height: 90px;
        }
    }

    &__back {
        grid-area: back;
        display: flex;
        align-items: center;
        gap: 5px;
        height: 100%;
        //padding:  0 35px 0 32px;
        padding-inline: 32px 35px;

        font-size: 16px;
        line-height: 16px;
        color: $wh;

        background-color: $blueLight;
        cursor: pointer;

        &::before {
            content: '';
            width: 22px;
            height: 22px;
            flex-shrink: 0;

            background-image: var(--icon-i-arrow);
            background-position: center;
            background-size: 36px;
            filter: $whFilter;
            transform: rotate(90deg);

            body.rtl-lang & {
                scale: -1 1;
            }
        }
    }

    &__btns {
        grid-area: btns;
        display: flex;
        gap: 13px;
    }
    &__btn {
        //margin-right: 13px;
    }

    body.rtl-lang & {
        direction: rtl;
    }
}

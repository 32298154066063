$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.form-error,
.ng-untouched+.form-label .form-error,
.ng-valid+.form-label .form-error,
.datepicker-open+.form-label .form-error {
    display: none
}

.ng-invalid:focus+.form-label .form-error {
    display: none !important;
}

.ng-invalid.datepicker-open+.form-label .form-error {
    display: none;
}

.ng-invalid.ng-touched+.form-label .form-error {
    display: flex;
}

.form-check.ng-untouched+label+.form-label .form-error {
    display: none;
}

.form-check.ng-touched.ng-invalid+label+.form-label .form-error {
    display: flex;
}

.form-check.ng-dirty.ng-invalid+label+.form-label .form-error {
    display: flex;
}


.g-minlength,
.g-maxlength,
.g-pattern,
.g-required,
.g-login-same,
.g-min,
.g-max,
.g-passwordVer,
.g-patternLetter{
    display: none;
}

.ng-invalid-required+.form-label .form-error .g-required~p {
    display: none;
}

.ng-invalid-pattern+.form-label .form-error .g-pattern~p {
    display: none;
}

.ng-invalid-minlength+.form-label .form-error .g-minlength,
.ng-invalid-maxlength+.form-label .form-error .g-maxlength,
.ng-invalid-min+.form-label .form-error .g-min,
.ng-invalid-max+.form-label .form-error .g-max,
.ng-invalid-pattern+.form-label .form-error .g-pattern,
.ng-invalid-postcode+.form-label .form-error .g-pattern,
.ng-invalid-required+.form-label .form-error .g-required,
.ng-invalid-required+.form-label .form-error .g-required,
.ng-invalid-number+.form-label .form-error .g-pattern,
.ng-invalid-email+.form-label .form-error .g-pattern,
.ng-invalid-login+.form-label .form-error .g-login-same,
.ng-invalid-letter+.form-label .form-error .g-patternLetter{
    display: flex;
}


.ng-invalid-password-verify+.form-label .form-error .g-passwordVer {
    display: flex;
}

.ng-invalid-password-verify+.form-label .form-error {
    display: flex;
}

//.ng-invalid-password-verify + .g-form-label .g-form-error .g-passwordVer{ display:flex;}
//.ng-invalid-password-verify + .g-form-label .g-form-error { display:flex;}

.form-check.ng-invalid+label+.form-label .g-required {
    display: flex;
}



/* EXCHANGE VALIDATION */


.g-pay-min,
.g-pay-max,
.g-amount,
.g-pay-required {
    display: none;
}


.is-exchange.ng-invalid-required+.form-label .form-error .g-pay-required,
.is-exchange.ng-invalid-min+.form-label .form-error .g-pay-min,
.is-exchange.ng-invalid-max+.form-label .form-error .g-pay-max,
.is-exchange.ng-invalid-amount+.form-label .form-error .g-amount {
    display: flex;
}

.ng-invalid-phone:not(.ng-empty).ng-touched ~ .form-label .form-error { display:flex;}

.ng-invalid-phone.ng-empty ~ .form-label .form-error .g-required { display:inline;}

.ng-invalid-phone:not(.ng-empty) ~ .form-label .form-error .g-pattern { display:inline;}


.form-error {
    .ng-invalid.ng-touched~&:not(.is-only-required),
    .ng-invalid-required.ng-touched~&.is-only-required {
        display: block;
    }

    &__pattern {
        display: none;

        .ng-invalid-pattern~.form-error &,
        .ng-invalid-email~.form-error &,
        .ng-invalid-number~.form-error &,
        .ng-invalid-phone~.form-error &,
        .ng-invalid-cpf~.form-error & {
            display: inline;
        }
    }
}

$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.games {
    display: grid;
    grid-template-areas: 'empty1 sidebar content empty2';
    grid-template-columns: 1fr minmax(229px, 229px) minmax(720px, 1528px) 1fr;
    @include combine(gap, 20px 20px, 20px 24px, 20px 32px);

    margin-top: 12px;

    @include s {
        margin-top: 8px;
    }
    @include m {
        margin-top: 4px;
    }

    &_slider {
        grid-template-areas: 
            'empty1 slider slider empty2'
            'empty1 sidebar content empty2';
    }

    &__sidebar {
        grid-area: sidebar;
    }
    &__content {
        grid-area: content;
        @include combine(padding-top, 12px, 14px, 22px)
    }
    &__slider {
        grid-area: slider;
    }
    &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
        padding-right: 7px;
    }
    &__tournament {
        display: block;
        margin: 60px 0 15px;
        @include m {
            margin: 68px 0 24px;
        } 
    }
    &__collection {
        margin-bottom: 32px;
        @include m {
          margin-bottom: 40px;
        }
    }
}

.empty-category-caption {
  &__wrapper {
    color: $wh;
    max-width: 620px;
  }
  &__title {
    font-size: 32px;
    font-weight: 700;
    line-height: 45px;
    text-transform: uppercase;
  }
  &__text {
    font-weight: 400;
    font-size: 18px; 
    line-height: 27px;
  }
  &__img {
    position: relative;
    width: 620px;
    height: 230px;
    background-repeat: no-repeat;

    body.rtl-lang & {
      scale: -1 1;
    }
    
    &::after {
      content: "";
      position: absolute;
      inset: 0;
      background: linear-gradient(180deg, rgba(12, 18, 33, 0) 0%, $blueDark 100%);
    }
  }
  @include xs {
    &__wrapper {
      max-width: 100%;
    }
  }
}
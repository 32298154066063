$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.quick-registration-dropdown {
    border-radius: 2px;
    position: relative;
    z-index: 0;
    background-color: #273242;
    box-shadow: 0 0 0 1px #ECFD00;

    .quick-registration-promo {
        position: relative;
        display: none;

        &.is-active {
            display: flex;
        }
    }

    &__bind {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
    }

    &.open {
        z-index: 35;
        min-height: 80px;
        box-shadow: none;
    }
}

html:not(.linda-has-popup) .entrance-bnr-wrapper,
.auth-popup {
    .quick-registration-dropdown.open {
        .quick-registration-promotions {
            position: absolute;
            height: unset;
            background-color: #162334;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        }

        .quick-registration-promo {
            display: flex;
            min-height: 52px;
            background-color: #1C2738;

            &:not(.is-active):hover {
                background-color: #273242;
            }

            &::before {
                opacity: 1;
            }

            &__circle {
                display: block;
            }

            &__inner {
                &::after {
                    display: none;
                }
            }

            &.is-active {
                .quick-registration-promo {
                    &__inner {
                        background-color: rgba(236, 253, 0, 0.08);
                    }

                    &__img {
                        &.img-active {
                            display: block;
                        }

                        &.img-default {
                            display: none;
                        }
                    }

                    &__title {
                        color: #ECFD00;
                    }

                    &__text {
                        color: #ffffff;
                    }
                }
            }
        }
    }
}
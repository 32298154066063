$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.cash-crab-popup-container {
  position: relative;
  box-sizing: border-box;
  margin: 0;
  top: 50%;
  transform: translateY(-50%);
  & .cash-crab-close {
    top: 25px;
    inset-inline-end: 25px;
  }
  &.cash-crab-slider {
    width: 564px;
    min-height: 476px;
    padding: 25px 80px 35px 80px;
    margin: 0 auto;
  }
  &.cash-crab-lose {
    width: 345px;
    min-height: auto;
    text-align: center;
    padding: 20px;
    background: #162334;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    & .btn {
      width: 257px;
      height: 48px;
      bottom: 0;
      margin: 20px auto 0;
      display: block;
      line-height: 48px;
    }
  }
  & a {
    display: block;
    position: absolute;
    right: 0;
    margin: 10px 10px 0 0;
    z-index: 10;
  }
}
.cash-crab-popup {
  overflow: hidden;
  position: relative;
  &.cc-slide-container {
    height: 100%;
  }
  &__slide {
    position: relative;
    flex-direction:column !important;
    justify-content:flex-start !important;
    padding: 0 0 0 0;
    &.is-modal{
      transition: 0 !important;
      animation: none !important;
    }
  }
  &__title {
    font-weight: normal;
    font-size: 38px;
    text-align: center;
    color: #fff;
    padding: 0 0 10px 0;
    margin: 0;
  }
  &__description {
    margin: 0;
    font-weight: normal;
    font-size: 16px;
    line-height: 15px;
    text-align: center;
    color: #A5AFC0;
    padding: 10px 0 0 0;
  }
  &__box{
    text-align: center;
    color: #8C959C;
    margin-top: 0px;
  }
  &__pic-first{
    margin-top: 50px;
  }
  &__img1{
    margin-top: 60px;
    max-width: 80%;
  }
  &__img2 {
    margin-top: 30px;
    width: 70%;
  }
  &__btns {
    position: relative;
    bottom: 0;
    z-index: 10;
    &.btn-deposit,
    &.btn-fail {
      bottom: 0;
       margin: 40px 0 0 0;
    }
    &.btn-play {
      bottom: 0;
    }
    &.btn-slide {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -30px;
    }
    & button {
      width: 300px;
    }
    &.btns-cc-popup {
      bottom: 40px;
    }
  }
  &__btn {
    min-width: 180px;
  }
  &__row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    &.row-cash-crab {
      margin: 0 auto;
    }
  }
  &__item {
    position: relative;
    width: 135px;
    height: 172px;
    text-align: center;
    padding-top: 120px;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
    &__label {
      position: absolute;
      top: 0;
      left: -10px;
      z-index: 30;
      .game-item:not(.linda-has-label) &{ display:none !important;}
    }
    &.is-1 {
      background-image: url($cdn + '/rabona/crab/icon1-min.png');
      background-size: 130px;
    }
    &.is-2 {
      background-image: url($cdn + '/rabona/crab/icon2-min.png');
      background-size: 80px;
      background-position-y: 25px
    }
    background-size: 110px;
    background-position: center 25px;
    background-repeat: no-repeat;
    & span {
      display: inline-block;
      background: #FF0000;
      box-shadow: 0px 3.77778px 0px #990101;
      border-radius: 4px;
      padding: 0 8px;
      height: 34px;
      font-weight: 900;
      font-size: 20px;
      line-height: 34px;
      text-transform: uppercase;
    }
  }
  & .cash-crab-slider-txt {
    position: relative;
    height: -webkit-fill-available;
  }
  & .cash-crab-slider-img {
    position: relative;
    height: 270px;
    min-height: 270px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.slider-margin {
      margin: 15px 0;
    }
    & img {
      display: block;
      padding: 0;
      margin: 0 auto;
    }
    & .cash-crab-popup {
      &__img1 {
        margin-top: 40px;
        &.img1-margin {
          margin-top: 60px;
        }
      }
      &__img2 {
        margin-top: -30px;
      }
      &__row {
        margin-top: -30px;
      }
    }
  }
  &__symbol {
    font-weight: 900;
    font-size: 36px;
    line-height: 42px;
    color: #fff;
    margin: 0 40px;
  }
  &__timer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin: 0 auto;
    & span {
      text-align: center;
      font-weight: normal;
      font-size: 20px;
      line-height: 24px;
      color: #fff;
      margin: 0 0 25px 0;
    }
    & strong {
      font-weight: 900;
      font-size: 42px;
      line-height: 51px;
      text-align: center;
      color: #F4F2FB;
      text-shadow: 0px 1px 2px #4372DE;
    }
  }
  & .swiper-pagination {
    position: relative;
    width: max-content;
    height: 2px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible;
    margin: 40px auto 0;
  }
  & .swiper-pagination-bullet {
    display: block;
    opacity: 1;
    width: 94px;
    height: 2px;
    margin: 0 4px;
    background-color: #243750;
    &.swiper-pagination-bullet-active {
      opacity: 1;
      width: 94px;
      height: 2px;
      background: #ECFD00;
    }
  }
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.entrance-promo {
  display: block;
  overflow: hidden;
  padding-bottom: 40px;

  &__item {
    display: flex;
    min-height: 360px;
    margin-top: 42px;

    &:nth-child(2) {
      flex-direction: row-reverse;
    }
  }

  &__bnr {
    background-size: cover;
    flex-shrink: 0;

    &.is-1 {
      width: 454px;
      background-position: right center;
    }

    &.is-2 {
      width: 501px;
    }

    &.is-cashcrab {
      background-position: center;
      background-size: cover, 0;
      background-repeat: no-repeat;
      @include s {
        background-size: 0, cover;
      }
      @include m {
        background-size: cover, 0;
      }
    }
  }

  &__panel {
    background: #192333;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    &.is-1 {
      padding: 21px 28px 30px;
      background-position: 2px 6px;
      background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9InllcyI/Pjxzdmcgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxjaXJjbGUgY3g9IjEwIiBjeT0iMTAiIHI9IjEiIGZpbGw9IiNFRkZCMzciIGZpbGwtb3BhY2l0eT0iLjM1IiBzdHlsZT0iIi8+PC9zdmc+');
    }

    &.is-2 {
      padding: 25px;
    }
  }

  &__offer {
    font-weight: bold;
    font-size: 42px;
    line-height: 49px;
    text-transform: uppercase;
    color: #ffffff;
    white-space: break-spaces;

    &.is-2 {
      width: fit-content;
      padding: 16px 28px 16px 28px;
      background-image: $grWh, $grWh, $grWh, $grWh;
      background-size: 100% 6px, 6px 100%, calc(100% - 100px) 6px, 6px calc(100% - 70px);
      background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
      background-position: left top, right top, right bottom, left top;
    }

    .small-font & {
      font-size: 32px;
      line-height: 40px;
    }
  }

  &__type {
    font-weight: bold;
    font-size: 42px;
    line-height: 49px;
    color: #ecfd00;
    padding-top: 15px;

    .small-font & {
      font-size: 32px;
      line-height: 40px;
    }
  }

  &__btn {
    margin-top: auto;
    padding-top: 15px;
  }

  @include s {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;

    &__bnr {
      width: auto !important;
      flex-grow: 1;
    }

    &__panel {
      flex-grow: 0;
      flex-shrink: 0;

      &.is-1 {
        width: 426px;
      }

      &.is-2 {
        width: 390px;
        padding: 30px 7px 30px 30px;
        z-index: 1;
      }
    }
  }

  @include m {
    grid-gap: 32px;
  }
}

$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.popup-fp{

  display: flex;

  &__content{
    padding: 24px 46px 44px;
    width: 532px;
    display: flex;
    flex-direction: column;

  }
  &__bnr{
    width: 392px;
    flex-shrink: 0;
    background-color: $red;
  }
  &__point{
    display: inline-block;
    margin: 46px auto 0;
    position:relative;
  }
  &__name{
    margin-top:15px;
    font-weight: bold;
    font-size: 18px;
    line-height: 36px;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;
  }
}
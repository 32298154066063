$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.autobonus-popup {
    padding: 24px 80px 37px;
    &__title {
        min-height: 48px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 8px;
        padding-bottom: 0;
        line-height: 1.15em;
    }

    &__info {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
        margin-top: 24px;
    }

    &__info-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5em;
        color: #A5AFC0;
        margin-bottom: 16px;
    }

    &__info-link {
        font-weight: 700;
        font-size: 14px;
        line-height: 1.15em;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        color: #FFFFFF;
        text-decoration-line: underline;
        text-underline-offset: 4px;
    }
}
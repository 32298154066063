$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.info {
  display: flex;
  flex-direction: column;

  body.rtl-lang & {
    direction: rtl;
  }

  &_article {
    margin-bottom: 56px;
    &:not(.no-margin) {
      margin-top: 56px;
    }
  }

  &_article h1,
  h2 {
    position: relative;
    left: -1px;
    top: -1px;

    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: $wh;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    height: 84px;
    width: calc(100% + 2px);
    padding-left: 64px;
    padding-right: 64px;

    background-image: linear-gradient(0deg, $red, $red);
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 100% 2px;

    transition: var(--animation-duration) background-size, var(--animation-duration) margin;
    body.rtl-lang & {
      flex-direction: row-reverse;
    }
  }

  section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    background-color: $blue;
    border: 1px solid $blueStroke;
    margin-top: 20px;
    transition: var(--animation-duration) max-height;

    &:first-child {
      margin-top: 0;
    }
  }

  p,
  ul,
  table,
  h3 {
    font-size: 18px;
    line-height: 27px;
    color: $wh;
    @include combine(width, 600px, 776px);
    margin: 0 auto 20px;
    transition: var(--animation-duration) max-height, var(--animation-duration) margin;
    body.rtl-lang & {
      text-align: right;
    }
  }

  ul {
    list-style: disc;
    padding-left: 30px;
    body.rtl-lang & {
      padding-left: 0;
      padding-right: 30px;
    }
  }

  li {
    display: block;
    padding-left: 16px;
    position: relative;

    body.rtl-lang & {
      padding-left: initial;
      padding-right: 16px;
    }

    &::before{
      display: block;
      width: 6px;
      height: 6px;
      content: '';
      background-color: #fff;
      border-radius: 50%;
      display: block;
      position: absolute;
      left: 0;
      top: 11px;

      body.rtl-lang & {
        left: initial;
        right: 0;
      }
    }
    body.rtl-lang & {
      flex-direction: row-reverse;
    }
  }

  a {
    color: $yellow;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  table {
    @include scroll;

    thead {
      tr {
        font-size: 16px;
        line-height: 19px;
        color: $blueGray;
        opacity: 0.5;

        td,
        th {
          padding: 6px 6px;
          text-align: center;
        }
      }
    }

    tbody {
      tr td {
        font-size: 16px;
        line-height: 27px;
        text-align: center;
        color: $wh;
        padding: 6px 6px;
        vertical-align: middle;
        &:last-child {
          word-break: break-word;
        }
      }
    }

    td, th {
      border: 1px solid grey;
      overflow: hidden;
    }
  }

  &.no-accordion {
    & > div {
      & > div:not(.change-cookies-link-wrapper) {
        display: flex;
        gap: 16px;
        justify-content: center;
        padding-bottom: 26px;

        body.rtl-lang & {
          direction: rtl;
        }

        a {
          align-items: center;
          background-color: #162334;
          border: 1px solid #273242;
          display: flex;
          font-size: 16px;
          font-weight: 700;
          height: 56px;
          justify-content: center;
          line-height: 16px;
          color: $wh;
          padding-block: 0;
          padding-inline: 16px 10px;

          &:hover {
            text-decoration: none;
          }

          img {
            display: none;
          }

          & > div {
            position: relative;

            &:before {
              position: absolute;
              top: 0;
              inset-inline-start: 0;

              content: '';
              display: inline-block;

              flex-shrink: 0;

              background-position: center;
              background-repeat: no-repeat;
            }

            & > div:first-child {
              color: #a5afc0;
              display: flex;
              align-items: center;
              position: relative;

              &:after {
                content: '';
                display: inline-block;
                width: 6px;
                height: 9px;
                margin-inline-start: 10px;

                flex-shrink: 0;

                filter: $blueGrayFilter;
                background-image: var(--icon-i-arrow);
                background-position: center;
                transform: rotate(-90deg);

                body.rtl-lang & {
                  transform: rotate(90deg);
                }
              }

              &:hover {
                color: $yellow;
                &:after {
                  filter: $yellowFilter;
                }
              }
            }

            & > div:last-child {
              cursor: default;
              &:not(:empty) {
                padding-top: 4px;
              }
            }
          }

          &:first-child {
            order: 1;

            & > div {
              padding-inline-start: 38px;

              &:before {
                background-image: var(--icon-i-support);
                background-size: 165% 140%;
                height: 30px;
                top: 2px;
                width: 25px;
              }
            }
          }

          &:last-child {
            & > div {
              padding-inline-start: 40px;

              &:before {
                background-image: var(--icon-info-email);
                background-size: 140% 200%;
                height: 22px;
                top: 50%;
                margin-top: -11px;
                width: 29px;
              }
            }
          }
        }
      }

      .change-cookies-link {
        font-size: 18px;
        font-weight: 400;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.quick-registration {
    position: relative;
    z-index: 1;
    width: 360px;
    border-radius: 4px;
    border: 1px solid #273242;
    background-color: #162334;
    box-shadow: 0px 2px 8px 0px #0B1222;
    padding: 12px 12px 24px 12px;

    @include s {
        width: 460px;
        padding: 16px;
    }

    &__plug {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;
        grid-column: 1 / 11;
        min-height: 90px;
        padding: 12px 24px 20px 16px;

        body.rtl-lang & {
            flex-direction: row-reverse;
        }
    }

    &__plug-img {
        width: 42px;
        height: 42px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
        }
    }

    &__plug-inner {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 8px;
    }

    &__plug-title {
        color: #576477;
        font-size: 16px;
        font-weight: 400;
        line-height: 1em;
        letter-spacing: 0.32px;
    }

    &__plug-text {
        color: #A5AFC0;
        font-size: 22px;
        font-weight: 700;
        line-height: 1em;
    }

    &__currency-wrap {
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        column-gap: 6px;
        grid-column: 1 / 11;

        body.rtl-lang & {
            direction: rtl;
        }
    }

    &__input {
        grid-column: 1 / 7;
        text-align: center;
        font-size: 26px;
        font-weight: 700;
        height: 54px;
        padding: 5px 16px;
        background-image: none;

        &.ng-valid.ng-dirty:not(.not-status),
        &:not(.not-status).ng-invalid.ng-touched:not(:focus) {
            background-image: none;
        }

        body.rtl-lang & {
            text-align: center;
            padding: 5px 16px;
        }

        &::placeholder {
            font-size: 20px;
        }
    }

    &__select {
        height: 54px;
        grid-column: 7 / 11;
        color: #A5AFC0;
        font-size: 20px;
        font-weight: 500;
        background-color: #273242;
        background-position: right 12px center;

        &:disabled {
            background-position: right 12px center;
            background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9InllcyI/Pjxzdmcgd2lkdGg9IjI4IiBoZWlnaHQ9IjI4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik05LjAyNCAxMWw1LjEyNyA1LjA1NkwxOS4zNTIgMTFsMS4wOTIuOTVMMTQuMTUxIDE4IDggMTEuOTUgOS4wMjQgMTF6IiBmaWxsPSIjQTdBRkJFIiBzdHlsZT0iIi8+PC9zdmc+);
            background-size: 40px;
            cursor: default;
        }
    }

    &__crypto {
        grid-column: 1 / 11;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        margin-top: 12px;
    }

    &__bonus {
        grid-column: 1/11;
    }

    &__submit {
        grid-column: 1 / 11;
        display: flex;
        justify-content: center;
        
        @include s {
            --btnHeight: 76px;
        }

        .is-text {
            margin-right: 8px;

            body.rtl-lang &,
            [lang="in"] &,
            [lang="hi-IN"] & {
                order: 1;
                margin-left: 8px;
                margin-right: 0;
            }
        }

        &.is-kk {
            .is-text {
                order: 1;
                margin-right: 0;
            }
        }

        &.is-pln {
            .is-text {
                order: 1;
            }

            .is-amount {
                order: 2;
            }

            .is-currency {
                order: 3;
            }
        }
    }

    &__form {
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        gap: 12px;
        padding: 6px;

        &.ng-invalid {
            .quick-registration__submit {
                background-color: #a5afc0;
                pointer-events: none;
            }
        }
    }
}

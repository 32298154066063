$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.entrance-menu {
  position: relative;
  z-index: 5;
  height: 800px;
  padding: 40px 14px 50px;
  display: flex;
  justify-content: center;
  background-image: url($cdn + "/rabona/entrance-page/background.webp");
  background-size: 2308px auto;
  background-position: center;
  background-repeat: no-repeat;
  width: calc(100vw - 4px);
  min-width: 1024px;
  left: 50%;
  transform: translateX(-50%);

  &__item {
    position: relative;
    height: 710px;
    margin: 0 14px;
    width: calc(33.3334% - 28px);
    max-width: 484px;
    overflow: hidden;
  }

  &__wrap {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }


  &__front {
    position: absolute;
    width: 474px;
    height: 468px;
    top: 18px;
    left: calc(50% - 237px);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: auto 468px;
    transition: 0.3s;
    z-index: 10;

    .entrance-menu__item:hover & {
      transform: scale(1.08);
    }
  }

  &__bg-wrap {
    position: absolute;
    top: 33px;
    left: 0;
    height: 404px;
    width: 100%;

  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 107% auto;
    background-position: center center;

    transition: 0.3s;
    z-index: 9;

    .entrance-menu__item:hover & {
      transform: translateY(15px);
      background-size: 100% auto;
    }
  }


  &__panel {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 410px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 20px 30px 20px;

    & div {
      position: relative;
      z-index: 15;
    }

    &::before {
      content: '';
      display: block;
      width: 484px;
      height: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 12;
      background-color: $red;
      clip-path: polygon(0 144px, 100% 44px, 100% 100%, 0 100%);

      .entrance-menu__item:nth-child(1) & {
        background-image: url($cdn + "/rabona/entrance-page/xxx.svg");
        background-repeat: no-repeat;
        background-position: 236px 110px;
      }

      .entrance-menu__item:nth-child(2) & {
        background-color: #192333;
        background-image: url($cdn + "/rabona/elements/pattern-collections.svg");
        background-repeat: no-repeat;
        background-position: left bottom 10px;
        background-size: 90% auto;
        transform: scale(-1, 1);
        clip-path: polygon(0 0, 100% 144px, 100% 100%, 0 100%, );
      }
    }

    &::after {
      content: '';
      display: block;
      width: 484px;
      height: 144px;
      position: absolute;
      top: 0;
      left: 0;
      background: $yellow;
      z-index: 13;
      clip-path: polygon(0 100px, 100% 0px, 100% 44px, 0 144px);
    }



  }

  &__title {
    height: 112px;
    font-weight: bold;
    font-size: 32px;
    line-height: 37px;
    text-transform: uppercase;
    color: #FFFFFF;
    overflow: hidden;
    white-space: break-spaces;

    .small-font & {
      font-size: 22px;
    }

    > span {
      margin-inline-end: 5px;
    }
  }

  &__btn {
    margin-top: 20px;
  }

  &__btns {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;

    body.rtl-lang & {
      direction: rtl;
    }

    .btn {
      [lang="el"] & {
        padding: 0 14px;
        font-size: 17px;
        min-width: 222px;
      }
    }
  }

  &__info-link {
    display: block;
    width: 176px;
    height: 48px;
    font-size: 20px;
    line-height: 48px;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: #FFFFFF;
    cursor: pointer;
    text-align: start;
    padding-inline-start: 10px;

    @include combine(min-width, 170px, 0, 170px);

    [lang="el"] & {
      font-size: 17px;
    }
  }

  &__label {
    position: absolute !important;
    width: calc(100% - 34px);
    height: 40px;
    top: 98px;
    left: 16px;
    transform: rotate(-11.7deg);
    transform-origin: 0 0;
  }

  &__label-text {
    text-align: right;
    display: inline-block;
    font-size: 35px;
    text-transform: uppercase;
    line-height: 45px;
    font-weight: 800;
    min-width: 20px;
    transition: 0.36s;
    transition-timing-function: cubic-bezier(0.52, 0.81, 0.63, 1.23);

    .small-font & {
      font-size: 22px;
    }

    .entrance-menu__item:hover & {
      min-width: 100%;
    }
  }

  @include s {
    padding: 40px 30px 50px;

    &__item {
      margin: 0 30px;
      width: calc(33.3334% - 60px);
      height: 717px;
    }

    &__panel {
      height: 450px;
    }

    &__info-link {
      text-align: center;

      body.rtl-lang & {
        text-align: center;
      }
    }

    &__title {
      height: 150px;
      font-size: 42px;
      line-height: 49px;

      .small-font & {
        font-size: 30px;
      }
    }
  }

  @include m {
    padding: 40px 0 50px;

    &__item {
      margin: 0 70px;
    }
  }

  body.rtl-lang & {
    direction: rtl;
  }
}

body.rtl-lang .entrance-menu {
  &__label-text {
    min-width: 100%;
    font-weight: 900;
    font-family: system-ui;
    font-size: 21px;
  }

  &__title {
    > span {
      display: inline-block;
    }
  }
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.cashbox-pay {
  min-height: 350px;

  &__content {
    padding-top: 4px;
  }

  &__title {
    font-size: 20px;
    line-height: 120%;
    color: #0C1221;
    text-align: center;
    padding: 20px 0 8px;

    body.rtl-lang & {
      padding-inline-end: 45px;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    padding: 0 33px 33px;

    body.rtl-lang & {
      direction: rtl;
    }
  }

  &__item {
    width: 154px;
    height: 84px;
    margin: 12px;
    background: #F0F5FC;
    border: solid 1px #F0F5FC;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    & img {
      width: 134px;
      height: 34px;
    }

    &:hover {
      border: solid 1px $yellow;
    }
  }

  &__empty {
    width: 100%;
    max-width: 298px;
    min-height: 546px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    padding: 20px 0;
  }

  &__empty-title {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.2em;
    color: #0B1222;
  }

  &__empty-text {
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.4em;
    color: #2A394E;
  }

  &__empty-btn {
    margin-bottom: 20px;
  }

  &__empty-chat {
    height: 24px;
    min-width: 107px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.25em;
    text-align: center;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: #576477;
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 12px 41px 0 41px;
  }

  &__crypto {
    margin: 4px;
    width: calc(50% - 8px);
    background-color: #EFF5FD;
    border-radius: 5px;
    border: 1px solid transparent;
    padding: 24px;
    transition: border-color .2s ease;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &:hover {
      border-color: $yellow;
    }
  }

  &__crypto-image {
    width: 114px;
    margin-right: 24px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__crypto-inner {
    flex-grow: 1;
  }

  &__crypto-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2em;
    color: #0B1222;
    margin-bottom: 4px;
  }

  &__crypto-info {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.4em;
    color: #2A394E;
  }

}

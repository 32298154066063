$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.missed-data {
  background-color: #192333;
  width: 100%;
  min-height: 100vh;
  padding: 32px 0 45px;
  box-sizing: border-box;
  position: relative;
  color: #A5AFC0;

  --side-padding: 20px;

  &__container {
    padding: 0 20px;
    max-width: 560px;
    width: 100%;
    margin: 0 auto;
  }

  &__header {
    padding-bottom: 16px;
    margin-bottom: 24px;
    border-bottom: 2px solid $red;
  }

  &__logo {
    margin: 0 auto 36px;
    max-width: 150px;
  }

  &__title {
    @include text(38px, 45px, normal, #ffffff);
    text-align: center;
    margin: 0 var(--side-padding) 8px;
  }

  &__description {
    font-size: 16px;
    text-align: center;
    line-height: 1.5;
  }

  &__form {
    max-width: 280px;
    width: 100%;
    text-align: center;
    margin: 20px auto 0;
  }

  &__submit {
    display: block;
    max-width: 274px;
    width: 100%;
  }

  &__decline {
    @include text(14px, 16px, 700, #ffffff);

    background-color: transparent;
    border: none;
    outline: none;
    margin: 0 auto;
    letter-spacing: 0.01em;
    text-decoration: underline;
  }
}

.missed-data-info {
  position: relative;
  max-height: 555px;
  padding-right: 16px;
  padding-bottom: 1px;
  overflow: hidden;
  overflow-y: auto;

  &__content {
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &.is-open {
      .missed-data-info__inner {
        max-height: fit-content;
      }
      .missed-data-dropdown {
        &::after {
          transform: rotate(180deg);
        }

        &__text {
          &_less {
            display: block;
          }

          &_more {
            display: none;
          }
        }
      }
    }
  }

  &__inner {
    display: block;
    max-height: 226px;
    overflow: hidden;
  }

  &__dropdown {
    position: relative;
    margin: 20px auto 0;
  }

  .info-wrap {
    @include text(18px, 27px, normal, #A5AFC0);

    body.rtl-lang & {
      direction: rtl;
      text-align: start;
    }
  }

  h1, h2, p, li, table {
    margin-bottom: 16px;
  }

  h1, h2 {
    @include text(18px, 2, 700, #ffffff);
  }

  h2 {
    font-size: 16px;
    line-height: 1;
    text-align: start;
    margin-top: 0;
  }

  table {
    display: block;
    max-width: 100%;
    border-width: 0;
    overflow-x: auto;
  }

  td {
    min-width: 120px;
    padding: 5px;
  }

  a {
    font-weight: bold;
    text-decoration: underline;
  }
}

.missed-data-dropdown {
  $self: &;

  @include text(16px, 19px, normal, #ffffff);

  position: relative;
  display: flex;
  align-items: center;
  padding-inline-end: 26px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;

  body.rtl-lang & {
    direction: rtl;
  }

  &::after {
    content: '';
    position: absolute;
    inset-inline-end: 0;
    width: 20px;
    height: 20px;
    background-image: var(--icon-arr-white);
    background-size: cover;
    transform: rotate(0deg);
  }

  &__arrow {
    width: 9px;
    height: 9px;
    margin-left: 4px;
    transform: rotate(0deg);
  }

  &__text {
    &_less {
      display: none;
    }

    &_more {
      display: block;
    }
  }
}

.missed-data-checkbox {
  max-width: 204px;
  margin: 0 auto 16px;
  .form-checkbox__text {
    color: #A5AFC0;
  }
}

.popup.is-missed-data {
  padding: 0;
}

.missed-data-cancellation {
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: #192333;

  &__content {
    max-width: 274px;
    width: 100%;
  }

  &__title {
    @include text(22px, 26px, 700, #ffffff);

    text-transform: uppercase;
    margin-bottom: 8px;
  }

  &__text {
    @include text(16px, 1.5, normal, #A5AFC0);

    margin-bottom: 24px;
  }

  &__btn {
    width: 100%;
  }
}

.no-scroll {
  overflow: hidden;
}

$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.game {
    position: relative;
    border-radius: 2px;
    background-position: top center;
    background-size: cover;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1);
    padding-top: 149%;

    &__body {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        z-index: 1;
        align-items: center;
        flex-direction: column;
        padding: 12px;
        background: rgba($red, .85);
        opacity: .5;
        clip-path: polygon(0 50%, 100% 50%, 100% 50%, 0 50%);
        transition-duration: var(--animation-duration);
        transition-property: clip-path, opacity;
    }
    
    &:hover .game__body {
        clip-path: polygon(0 0, 101% 0, 101% 101%, 0 101%);
        opacity: 1;
    }

    &:hover .game__label-wrap{
        display: none;
    }

    &__star {
        position: absolute;
        inset-inline-end: 12px;
        top: 12px;

        width: 28px;
        height: 28px;
        
        background-image: var(--icon-star);
        cursor: pointer;

        &:hover {
            background-image: var(--icon-star-hover);
        }
        &.active {
            background-image: var(--icon-star-active);
        }
    }

    &__title {
        font-size: 22px;
        font-weight: 700;
        line-height: 23px;
        text-align: center;
        color: $wh;

        width: 100%;
        margin-top: 28px;
        margin-bottom: 8px;
        body.rtl-lang & {
            text-align: center;
            direction: ltr;
        }
    }

    &__subtitle {
        font-size: 12px;
        line-height: 14px;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
        color: $yellow;

        @include s {
            margin-top: 5px;
        }
        @include m {
            margin-top: 8px;
        }
    }

    &__button {
        margin: auto 0;

        width: 100%;
        @include combine(padding, 0 18px, 0 22px, 0 33px)
    }

    &__demo {
        margin-top: auto;
        @include combine(margin-bottom, 18px, 28px, 33px);

        font-size: 18px;
        line-height: 21px;
        text-decoration: none;
        color: $wh;

    }

    &__label-wrap {
        position: absolute;
        display: block;
        top: 7px;
        right: 11px;
    }

    &__label {
        min-width: 96px;
        height: 27px;
        padding: 0 10px;
        display: none; 
        justify-content: center;
        align-items: center;
        clip-path: polygon(13px 0, 100% 0, calc(100% - 13px) 100%, 0 100%);
        font-size: 16px;
        line-height: 19px;
        font-weight: 700;
        color: #FFFFFF;
        text-transform: uppercase;

        &_new {
            display: flex;
            background-color: #00C400;
        }
        &_exclusive {
            display: flex;
            background-color: #BC1F39;
        }
        &-dropAndWins {
            display: block;
            width: 64px;
            height: 58px;
            background-image: url($cdn + '/rabona/ribbons/drops-wins.svg');
            background-repeat: no-repeat;
            background-size: contain;
            background-color: transparent;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.promo-page {
  position: relative;
  margin-top: -20px;
  padding-top: 55px;
  color: #fff;
  background-image: url($cdn + '/rabona/promotions/promotions_background_image.jpg');
  background-size: 1920px;
  background-position: center top;
  background-repeat: no-repeat;

  &__back {
    color: #fff;
    position: absolute;
    top: 62px;
    inset-inline-start: 40px;
    display: flex;
    align-items: center;
    height: 40px;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 2px;
    padding: 12px;
    padding-inline-end: 16px;
    cursor: pointer;

    &::before {
      content: '';
      width: 12px;
      height: 16px;
      background-size: 17px;
      filter: $whFilter;
      margin-inline-end: 7px;

      body.rtl-lang & {
        scale: -1 1;
      }
    }

    &:hover {
      background: none;
    }
  }

  &__offer {
    font-weight: bold;
    font-size: 40px;
    line-height: 37px;
    text-align: center;
    text-transform: uppercase;
    margin: 0 auto;
    width: 904px;
    padding-left: 75px;

    @media screen and (min-width: 1280px) {
      padding-left: 0;
    }

    span:first-child {
      color: #ECFD00;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 4px;
      display: block;
    }

    span:not(:first-child) {
      margin-bottom: 8px;
    }

    span {
      display: inline-block;
    }

    body.rtl-lang & {
      direction: rtl;
    }
  }

  &__box {
    position: relative;
    background: #162334;
    border: 1px solid #273242;
    width: 934px;
    margin: 20px auto 80px;
    border-top: solid 2px $red;
    padding: 340px 77px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__img {
    position: absolute;
    left: calc(50% - 327px);
    top: 80px;
    width: 655px;
    height: 240px;

    transform: rotate(-9deg);
    z-index: 80;

    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__content {
    position: relative;
    z-index: 85;
  }

  &__get {
    width: 300px;
    margin: 50px auto 0;
  }

  &__steps-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 34px;
    color: $wh;
    text-align: center !important;
    margin-bottom: 4px;
  }

  &__btn {
    display: flex;
    width: 296px;
    margin: 12px auto 0;
  }

  &__socials {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 296px;
    gap: 8px;
    margin: 12px auto 0;
  }

  &__socials-link {
    width: calc(50% - 4px);
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 28px;
    background-color: #273242;
    border: 1px solid #2a394e;
    transition-property: background-color, border-color, color;
    transition-duration: .2s;
    cursor: pointer;

    &.is-instagram {
      background-image: var(--icon-instagram);
    }
    &.is-telegram {
      background-image: var(--icon-telegram);
    }

    &:hover {
      background-color: #2A394E;
    }
  }

  &__link {
    text-align: center;
    margin-top: 12px;

    & a {
      color: $yellow;
      display: inline-flex;
      cursor: pointer;
      padding: 14px 4px;

      &::before {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        margin-inline-end: 4px;
      }
    }
  }

  &__subtitle {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #a7afbe;
    text-align: center;
    margin: 30px 0 20px;
  }

  &__terms {
    font-size: 16px;
    line-height: 24px;
    color: #a7afbe;

    & p {
      position: relative;
      padding-inline-start: 30px;
      margin: 10px 0;

      & > span {
        position: absolute;
        top: 0;
        inset-inline-start: 0;
        font-weight: 700;
        min-width: 24px;
      }
    }

    ul,
    ol {
      padding-inline-start: 46px;

      li {
        margin-bottom: 6px;
      }
    }

    ul {
      list-style: disc;
      visibility: visible;
    }

    table {
      margin-left: 26px;
      width: calc(100% - 26px) !important;

      td {
        padding: 6px 12px;
      }
      th,
      td {
        border: 1px solid grey;
        text-align: center;
      }
    }
    & a {
      color: $yellow;
    }
  }

  &__up {
    margin-top: 30px;
    text-align: center;
  }

  &__shadow {
    background: linear-gradient(180deg, rgba(25, 35, 51, 0) 0%, #192333 100%);
    position: absolute;
    top: 250px;
    left: 0;
    width: 100%;
    height: 130px;
    z-index: 83;
  }

  &__status{
    width: 296px;
    margin: 0 auto;
    background: #1C2738;
    padding: 8px 12px;
    span{
      @include text(12px, 140%, 400, #A5AFC0);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      &:before {
        content: "";
        width: 16px;
        height: 16px;
        display: block;
      }
    }

    &-text {
      @include text(12px, 140%, 400, #A5AFC0);
      text-align: center;
      margin-top: 4px;

      &.is-link {
        text-decoration: underline;
        display: block;
        margin-top: 0;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  &__cancel{
    text-align: center;
    margin-top: 20px;
    a{
      @include text(16px, 140%, 400, #A5AFC0);
      text-decoration-line: underline;
    }
  }

  &__copy-promocode {
    width: 296px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 12px;
    padding: 7px 20px;
    background-color: #2A394E;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.17;
    color: #A5AFC0;
    column-gap: 8px;
    cursor: pointer;
  }

  &__copy-promocode-text {
    text-transform: uppercase;
    color: #FFFFFF;
  }
}

.promo-tooltip-wrapper {
  position: relative;
  width: 24px;
  height: 24px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: -4px;

  .promo-tooltip {
    &.hover-tooltip,
    &.copy-tooltip {
      position: absolute;
      background-color: #EFF5FD;
      font-weight: 400;
      font-size: 12px;
      line-height: 1.333;
      color: #0B1222;
      padding: 4px 10px;
      bottom: calc(100% + 6px);
      left: 50%;
      transform: translateX(-50%);
      transition: opacity .3s;
      opacity: 0;

      &.is-visible{
        opacity: 1;
      }

      &::before {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        content: '';
        border-style: solid;
        border-width: 6px 6px 0 6px;
        border-color: #EFF5FD transparent transparent transparent;
      }
    }
  }

  .promo-page__copy-promocode:hover & {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcuNzE0ODQgMTcuNTcySDE3LjE0MzRWNy43MTQ4NEwxNC4xNDM0IDQuNzE0ODRINy43MTQ4NFYxNy41NzJaIiBmaWxsPSJ3aGl0ZSIgZmlsbC1vcGFjaXR5PSIwLjE1Ii8+CjxwYXRoIGQ9Ik02Ljg1NzA1IDE4LjQwOTJIMTQuOTkzMVYxOS4yODQ5SDZMNiA3Ljc3NTM5SDYuODU3MDVWMTguNDA5MloiIGZpbGw9IiNFQ0ZEMDAiLz4KPHBhdGggZD0iTTE2Ljc0NjcgOC4xNjg1M0gxNC4xNzUyVjUuNTk3MUg4LjYwMzc5VjE2LjcxNDhIMTYuNzQ2N1Y4LjE2ODUzWk0xNC42MzYzIDQuNzE0ODRMMTcuNjg0MSA3Ljc2MjYzVjE3LjU3Mkg3LjcxNDg0VjQuNzE0ODRIMTQuNjM2M1oiIGZpbGw9IiNFQ0ZEMDAiLz4KPC9zdmc+Cg==);

    &:hover .hover-tooltip {
      opacity: 1;
    }
  }
}

$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.profile-title-row{

  display: flex;
  align-items: flex-end;
  justify-content: space-between;


  &__link{
    font-size: 14px;
    line-height: 150%;
    color: #A5AFC0;
    & a{ color: $yellow; margin-inline-start:3px;}
  }
}
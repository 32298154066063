$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.autobonus{

  display: flex;
  flex-direction: column;
  align-items: center;

  &__label{
    display: inline-block;
    margin:14px auto 0;
    background: #FFFFFF;
    height: 28px;
    transform: skew(-23deg);
    padding:6px 13px;
    box-shadow: 2px 3px 0px rgba(42, 51, 65, 0.25);

    &.is-status-active{ background: $yellow; }
    & span{
      font-style: italic;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      text-transform: uppercase;
      color: #0C1221;
      display:block;
      transform: skew(23deg);

    }
  }
  &__offer{
    margin-top:13px;
    font-weight: bold;
    font-size: 40px;
    text-transform: uppercase;
    color: #FFFFFF;
  }
  &__name{
    margin-top:10px;
    font-size: 28px;
    line-height: 24px;
    color: #FFFFFF;
    & a{
      text-decoration: none;
      color: $yellow;
    }

    body.rtl-lang & {
      &::before {
        content: 'علاوة';
        display: contents;
        font-size: 0;
        line-height: 0;
        pointer-events: none;
      }
    }
  }
}
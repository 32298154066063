$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;

.autobonus-tape{
    position: absolute;
    top: -2px;
    inset-inline-start: -30px;
    background-color: $yellow;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);

    text-transform: uppercase;
    font-size: 8px;
    font-weight: 700;
    line-height: 9px;
    text-align: center;
    color: #0B1222;

    clip-path: polygon(25% 0%, 75% 0%, 100% 100%, 0% 100%);
    width: 51px;
    height: 13px;
    padding: 2px;

    transform: rotate(-45deg);
    
    body.rtl-lang & {
        transform: rotate(45deg);
    }

}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.tournament-sport-pool {
    padding: 24px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: fit-content;
    background-color: $blue2;
    &__text {
        @include text(20px, 24px, 700, $wh);
        margin-bottom: 24px;
    }
    &__wrap {
        height: 110px;
        display: grid;
        grid-template-columns: 1fr 82px 1fr;
        grid-gap: 0 24px;
    }
    &__item {
        @include text(16px, 20px, 700, $yellow);
        min-width: 60px;
        text-align: center;
        padding-top: 86px;
        background-position: center top 32px;
        background-repeat: no-repeat;
        background-size: contain;
        &.is-silver {
            background-image: var(--icon-silver-place);
        }
        &.is-gold {
            padding-top: 83px;
            font-size: 24px;
            line-height: 24px;
            background-image: var(--icon-gold-place);
            background-position: center top;
            background-size: 82px 62px;
        }
        &.is-bronze {
            background-image: var(--icon-bronze-place);
        }
    }
    &__note {
        @include text(17px, 24px, 700, #A5AFC0);
        text-align: center;
        margin-top: 24px;
    }
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.cashbox-popup{

  width: 956px;

  animation: popupShowWrap .2s linear ;


  &__header{
    height: 80px;
    background: #192333;
    display: flex;
    align-items: center;
    padding:10px 24px 10px 38px;

    body.rtl-lang & {
      direction: rtl;
    }
  }
  &__link{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    padding:0 27px ;
    font-style: italic;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #A7AFBE;
    transform: skew(-25deg);
    cursor:pointer;
    margin-right:1px;
    & span {  display:block; transform: skew(27deg); }

    &:hover{ background: rgba(255, 255, 255, 0.045); }
    &.active{
      background: rgba(255, 255, 255, 0.045);
      border-bottom: solid 1px $red;
      color: #FFFFFF;
      pointer-events: none;
    }
  }

  &__link-label {
    position: absolute;
    background-color: #BC1F39;
    z-index: 1;
    inset-inline-end: -7px;
    top: -5px;
    height: 11px;
    text-transform: uppercase;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    line-height: 1em;
    padding: 0 6px;
    transform: skew(27deg);
    clip-path: polygon(0 100%, 6px 0, 100% 0, calc(100% - 6px) 100%);
  }

  &__history{
    margin-inline-start:auto;

    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #A7AFBE;
    &::before{
      content: '';
      display: block;
      width: 28px;
      height: 28px;
      margin-inline-end:8;
      background-image:  var(--icon-i-history);
    }
    &:hover::before{ background-image:  var(--icon-i-history-hover); }
    &:hover{ color:#fff;}
  }
  &__close{
    width: 32px;
    height: 32px;
    margin-inline-start: 24px;
    filter: $whFilter;
    opacity:0.8;
    cursor:pointer;
    background-repeat: no-repeat;
    background-position: center;
    &:hover{ opacity:1;}
  }

  &__footer{

  }

  &__content{
    background: #fff;
    min-height: 484px;

    animation: popupShowWrapInside .2s linear ;
  }

}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.profile-title{
  font-size: 32px;
  line-height: 40px;
  color: #FFFFFF;

  @include s{
    font-size: 42px;
    line-height: 40px;
  }
}

$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.collection-bonus {
  margin-top: 16px;
  height: 155px;

  &__bg {
    position: absolute;
    min-height: 155px;
    background-color: $red;
    left: 0;
    width: 100%;
    z-index: 2;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: relative;
    z-index: 3;
    margin: auto;
    height: 100%;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"><circle cy="10" cx="10" r="1" fill="%23DA6436" /></svg>');
    background-repeat: repeat;
    background-position: center top;
    text-align: center;
  }

  &__count {
    @include text(40px, 48px, 700, $wh);
    &_money {
      direction: ltr !important;
    }
  }

  &__text {
    @include text(28px, 24px, 400, $wh);
  }
}
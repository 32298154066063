$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.cashbox-qr {
    display: flex; 
    flex-direction: column;
    margin: 20px 0 0;
    align-items: center;
    span {
        @include text(20px, 24px, 400, #0B1222);
    }
    img {
        margin-top: 80px;
        width: 164px; 
        height: 164px;
    }
}
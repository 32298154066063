$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.payments-pays {
  display: grid;
  grid-template-columns: repeat(5, 168px);
  justify-content: center;
  align-items: stretch;
  grid-gap: 20px;

  &__item {
    position: relative;
    padding: 16px;
    text-align: center;
    background-color: #eff5fd;
    cursor: pointer;

    &-logo {
      width: 100%;
      background-size: contain;
      height: 36px;
      background-repeat: no-repeat;
      background-position: center;
    }

    &-title {
      font-size: 14px;
      line-height: 16px;
      font-weight: bold;
      color: $blueGray;
      word-wrap: break-word;
    }

    &-backbox {
      opacity: 0;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      padding: 16px 12px;
      text-align: center;
      background-color: $wh;
      z-index: 99;
      clip-path: polygon(0 50%, 100% 50%, 100% 50%, 0 50%);
    }

    &:hover .payments-pays__item-backbox {
      opacity: 1;
      clip-path: polygon(0 0, 101% 0, 101% 101%, 0 101%);
      transition-duration: var(--animation-duration);
      transition-property: clip-path, opacity;
    }
  }
  &__sub-item {
    font-size: 18px;
    line-height: 21px;
    font-weight: bold;
    color: #2a394e;
  }
  &__sum {
    body.rtl-lang & {
      display: inline-block;
      &::before {
        content: 'ف';
        display: inline;
        font-size: 0;
        line-height: 0;
        pointer-events: none;
      }
    }
  }
  &__btn {
    min-width: 144px;
    margin-top: 46px;
  }
  &__stub {
    grid-column: 1 / -1;
    font-size: 18px;
    line-height: 21px;
    font-weight: 500;
    color: #fff;
    text-align: center;
  }
}

$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.sitemap {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 22px 20px;

    margin-left: 64px;
    margin-right: 64px;
    margin-bottom: 32px;

    &__item {
        font-size: 14px;
        line-height: 20px;
        text-decoration: none;
        color: $blueGray2 !important;
        
        &:hover {
            color: $yellow !important;
            text-decoration: none;
        }
    }
}
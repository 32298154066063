$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.promo {
  padding: 0 24px;
  max-width: 1920px;
  min-height: 600px;
  margin: 24px auto 50px;
  color: #ffffff;

  &__title {
    font-size: 32px;
    line-height: 38px;
    text-align: center !important;
    color: $wh;
    padding-top: 2px;

    @include s {
      padding-top: 8px;
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;

    &.is-reverse {
      flex-direction: column-reverse;
    }
  }

  &__group {
    margin-top: 64px;

    &:first-of-type {
      margin-top: 37px;
    }

    body.rtl-lang & {
      direction: rtl;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
    margin-top: 18px;

    @include s {
      grid-template-columns: 1fr 1fr;
      gap: 24px 20px;
    }

    @include m {
      gap: 32px;
    }
  }

  &__item {
    &:nth-child(4n + 3) {
      background-color: #192333;
    }

    &:nth-child(2n + 3) .promo-item-sm {
      flex-direction: row-reverse;
    }

    &:nth-child(4n + 2) {
      background-color: $red;
    }

    &:nth-child(4n + 4) {
      background-color: #192333;
    }

    &:nth-child(4n + 5) {
      background-color: $red;
    }

    &.is-summer-promotion {
      background-color: $red;
    }

    &.is-easter {
      background-color: $red;
    }
  }

  @include s {
    padding: 0 40px;

    &__item {
      &.is-first-promo {
        grid-column: 1 / -1;
      }
    }
  }
}

$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.profile-info{
  padding-inline-end: 312px;

  &__row{
    display: flex;
    border-bottom:solid 1px #293241;
    padding:12px 0;
    &.is-first{
      margin-top: 39px;
    }
  }
  &__key{
    font-size: 12px;
    line-height: 24px;
    text-transform: uppercase;
    color:#fff;
    width: 140px;
    padding-right:5px;
    flex-shrink: 0;
  }

  &__val{
    font-size: 14px;
    line-height: 24px;
    color: #A7AFBE;
    flex-grow: 1;
    body.rtl-lang & {
      text-align: right;
    }
  }
  &__link{
    line-height: 24px;
    font-size: 14px;
    color: $yellow;
    cursor:pointer;
    body.rtl-lang & {
      padding-left: 10px;
    }
  }

  &__change{
    margin-top: 135px;
    text-align: center;
    font-size: 16px;
    line-height: 19px;
    color: #A7AFBE;
    & a{
      color: $yellow;
      text-decoration: underline;
      margin-inline-start:4px;
      cursor:pointer;
    }
  }

  @include s{
    padding-inline-end: 324px;
    &__row{ padding:16px 0; }
    &__key{ width: 294px; font-size: 14px; }
    &__val{ font-size: 16px; }
    &__link{ font-size: 16px; }
  }
  @include m{
    padding-inline-end: 384px;
    &__key{ width: 319px; }
  }
}

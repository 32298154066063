$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.cashbox-wcancel{

  min-height: 484px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;



  &__stub{
    width: 400px;
    text-align: center;
  }
  &__stub-btn{
    margin:48px auto 0;
    width: 300px;
  }

  &__item{
    border: 1px solid #F0F5FC;
    box-sizing: border-box;
    margin:10px 0;
    padding:24px;
    width: 828px;
    display: flex;
    line-height: 28px;
  }

  &__i{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    margin-right:20px;
    text-align: center;
    & strong{
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      color: #0C1221;
    }
    &:last-child{ margin-right:0; }

  }
  &__date {
    body.rtl-lang & {
      direction: ltr;
    }
  }
  &__amount {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
  }
  &__currency {
    order: 0;
  }
  &__amount {
    order: 1;
  }
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.entrance-wins{
  width: 100%;
  height: 100%;
  margin-bottom: 40px;
  background-color: #111928;
  border-radius: 2px;
  padding: 20px 28px;
  padding-inline-end: 0;

  & .widget-events__list{
    overflow: scroll;
    padding-left: 0;
  }

  body.rtl-lang & {
    direction: rtl;
  }
}
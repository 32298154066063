$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.search {

    &__input-box {
        position: relative;
        margin: 16px 12px;
    }

    &__input {
        body.rtl-lang & {
            direction: rtl;
        }
        font-size: 14px;
        line-height: 16px;
        color: $blueGray;

        display: flex;
        align-items: center;
        height: 40px;       
        width: 100%;
        padding-inline-start: 47px;
        padding-inline-end: 15px;

        background-color: $blueStroke;
        border-radius: 2px;
        border-width: 0;
        box-shadow: inset 0 0 0 1px $blueLight;

        cursor: pointer;

        transition-duration: var(--animation-duration);
        transition-property: padding;
        
        &::placeholder {
            color: inherit;
        }

        &:hover {
            color: $wh;
            box-shadow: inset 0 0 0 1px transparent;
            background-color: $blueLight;

            &+.search__icon {
                filter: $whFilter;
            }
        }

        &:focus {
            color: $wh;
            box-shadow: inset 0 0 0 1px $yellow;
            padding-inline-start: 15px;

            &+.search__icon {
                width: 0;
            }
        }
    }


    &__icon {
        position: absolute;
        top: 6px;
        inset-inline-start: 7px;
        width: 28px;
        height: 28px;
        filter: $blueGrayFilter;
        background-image: var(--icon-i-search-black);
        background-position: left top;

        transition-duration: var(--animation-duration);
        transition-property: width;
    }

    &__result {
        display: none;
        flex-direction: column;

        border-left: 1px solid $blueStroke;  
        border-right: 1px solid $blueStroke;  
        background-image: linear-gradient(to bottom, $blue, $blue);
        background-repeat: no-repeat;
        background-size: 100% calc(100% - 24px);

        .linda-has-games:focus-within &,
        .linda-not-found:focus-within &,
        &:hover {
            display: flex;
        }



        &::after {
            content: '';
            width: 100%;
            height: 25px;
            margin-top: 16px;
            border-top: 1px solid $blueStroke;
            background: linear-gradient(180deg, #0C1221 0%, rgba(12, 18, 33, 0) 100%);
            opacity: .5;
        }
    }

    &__empty {
        font-size: 14px;
        line-height: 16px;
        color: $blueGray;
        padding: 8px 12px 4px 12px;

        display: none;

        .linda-not-found & {
            display: initial;
        }
    }
    &__games {
        display: none;
        flex-direction: column;
        padding-inline-start: 8px;
        margin-inline-end: 8px;

        max-height: 364px;
        overflow-y: scroll;
        overscroll-behavior: none;

        .linda-has-games & {
            display: flex;
        }

        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
            background-color: transparent;
            border-radius: 2px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $blueLight;
            border-radius: 8px;
        }
    }
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.quick-registration-promotions {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 9px;
    cursor: pointer;
    box-shadow: none;
    padding: 6px;

    &__input {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    &:has(.is-drop-opened) {
        background-color: rgba(236 253 0 / 0.08);

        &+.tooltip {
            animation: hide 0.5s forwards;
        }
    }

    &~.tooltip {
        display: block;
        text-transform: none;
        padding: 12px 20px;
        font-size: 16px;
        line-height: 16px;
        opacity: 0;
        animation: show 0.5s 2.2s forwards;
    }
}

.quick-registration-promo {
    $root: &;
    display: flex;
    align-items: center;
    position: relative;

    &:not(:last-child) {
        &::before {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            left: 0;
            top: calc(100% + 4px);
            height: 1px;
            background-color: #273242;
            opacity: 0;
        }
    }

    &.is-promocode {
        flex-direction: column;
    }

    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        min-height: 68px;
        border-radius: 2px;

        &:after {
            display: block;
            content: '';
            width: 20px;
            height: 20px;
            pointer-events: none;
            background-image: var(--icon-arr-down);
            background-repeat: no-repeat;
            background-size: 40px;
            background-position: center;
            filter: brightness(0) saturate(100%) invert(81%) sepia(23%) saturate(188%) hue-rotate(179deg) brightness(84%) contrast(86%);
            position: absolute;
            inset-inline-end: 17px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &__img-wrap {
        width: 42px;
        height: 42px;
        display: grid;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
    }

    &__img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        grid-area: 1/1;

        &.img-active {
            display: none;
        }
    }

    .wrap {
        overflow: hidden;
    }

    &__label {
        width: 100%;
        padding: 10px 16px;
        display: flex;
        align-items: center;
        gap: 12px;
        cursor: pointer;
        overflow: hidden;

        &:not(.quick-registration-dropdown.open &) {
            padding-inline-end: 40px;
        }

        .radio-block {
            flex-shrink: 0;
        }
    }

    &__wrap {
        color: #FFFFFF;
        overflow: hidden;
        display: grid;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        margin-inline-end: 10px;
    }

    &__title {
        color: #A5AFC0;
        font-size: 16px;
        font-weight: 400;
        line-height: 1em;
        letter-spacing: 0.32px;
    }

    &__text {
        color: #FFFFFF;
        font-size: 22px;
        font-weight: 700;
        line-height: 1em;

        span {
            display: inline-block;
            margin-inline-end: 3px;

            &:last-child {
                margin: 0;
                display: inline;
            }
        }
    }

    &__circle {
        display: none;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #162334;
        border: 1px solid #2A394E;
        margin-inline-start: auto;
        flex-shrink: 0;
        position: relative;
        transition: background-color .3s ease, border-color .3s ease;

        &::after {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(0);
            background-color: #162334;
            border-radius: 50%;
            filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.30));
            opacity: 0;
            transition: opacity .3s ease, transform .3s ease;
        }

        .quick-registration-promo.is-active & {
            background-color: #ECFD00;
            border-color: #ECFD00;

            &::after {
                opacity: 1;
                transform: translate(-50%, -50%) scale(1);
            }
        }
    }

    &__input-wrap {
        width: 100%;
        margin-top: 1px;
        display: none;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        height: 52px;
        padding: 10px 12px 10px 16px;
        border-radius: 0px 0px 2px 2px;
        background-color: #273242;

        &.is-opened {
            display: flex;
        }

        .btn {
            padding: 8px 12px;
            height: 32px;
            min-width: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: 400;
            text-transform: capitalize;

            &:disabled {
                color: #576477;
                pointer-events: none;

                &::after {
                    background-color: transparent;
                    border: 1px solid #2A394E;
                }
            }
        }
    }

    &__input {
        height: 100%;
        flex-grow: 1;
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        background: transparent;
        border: none;

        &::placeholder {
            color: #576477;
        }

        body.rtl-lang & {
            direction: rtl;
        }
    }

    .hidden-text {
        font-size: 0;
        line-height: 0;
        pointer-events: none;
        display: contents;
    }
}

@keyframes show {
    to {
        opacity: 1;
    }
}

@keyframes hide {
    to {
        opacity: 0;
    }
}
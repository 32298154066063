$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.entrance-live{
    display: flex;
    gap: 12px;
    margin-top: 24px;
    &__left{
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 12px;

    }
    &__right{
        flex: 0 0 450px;        
        display: flex;
        flex-direction: column;
        gap: 12px;
        @media (min-width: 1280px) {
            flex: 0 0 500px;        
        }
        @include s {
            flex: 0 0 549px;        
        }
        @media (min-width: 1680px) {
            flex: 0 0 650px;
        }
        @include m {
            flex: 0 0 766px;
        }
    }
    .entrance-sport__title{
        width: fit-content;
    }

    body.rtl-lang & {
        direction: rtl;
    }
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.modal-global {
  &__wrap {
    display: grid;
    row-gap: 20px;
    max-width: 520px;
    width: 100%;
    padding: 24px;
    background-color: #162334;
  }

  &__title {
    text-align: center;
    font-weight: 400;
    font-size: 32px;
    line-height: 1;
    color: #ffffff;
    padding-bottom: 16px;
    border-bottom: 1px solid #2A394E;
  }

  &__list {
    display: grid;
    row-gap: 12px;
    list-style: none;
    counter-reset: counter;
    font-size: 16px;
    color: #A5AFC0;

    li {
      position: relative;
      padding-inline-start: 20px;
      counter-increment: counter;

      body.rtl-lang & {
        text-align: start;
      }

      &:before {
        content: counter(counter)'.';
        position: absolute;
        top: 0;
        inset-inline-start: 0;
      }
    }
  }

  &__vbtns {
    text-align: center;
    margin: 0 auto;
    width: 100%;
  }

  .btn {
    font-size: 24px;
  }

  .link {
    color: #fff;
    font-weight: 400;
    text-decoration: underline;
    min-height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    
    &:hover {
      color: #ecfd00;
    }
  }
}

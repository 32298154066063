$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.card-item{

  --card-radius:4px;
  --card-radius-1:3px;

  position:absolute;
  top: 0;
  left: 0;
  width: 100% ;
  height: 100%;

  background-size: 0px;

  &.is-open,
  &.is-bonus_received {
    background-size: 100% 100%;
  }








  &__back{
    position:absolute;
    left: 0;
    top: 0;


    background-color: #162334;
    box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.27);
    border-radius: 6px;
    cursor:pointer;
    transition: 0.4s;
    padding:4px;
    width: 100% ;
    height: 100%;
    position:relative;
    .card-item:hover &{
      background-color: #545F70;
      transform: scale(1.05) translateY(-10px);
    }
  }


  &__bg{
    position: relative;
    width: 100% ;
    height: 100%;
    background-color: #545F70;
    clip-path: polygon( 0 var(--card-radius) , var(--card-radius) 0 , 100% 0 , 100% calc(100% - var(--card-radius)) , calc(100% - var(--card-radius)) 100% , 0 100% );
    &::before{
      content: '';
      display: block;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      position: absolute;
      top: 1px;
      left: 1px;
      background: url( $cdn + "/rabona/cards-page/card-fon.jpg") 50% 50%;
      background-size: cover;
      clip-path: polygon( 0 var(--card-radius-1) , var(--card-radius-1) 0 , 100% 0 , 100% calc(100% - var(--card-radius-1)) , calc(100% - var(--card-radius-1)) 100% , 0 100% );
    }
  }

  &__logo{
    position:absolute;
    width: 32%;

    top: 19.5%;
    left: 50%;
    transform: translateX( -50%);


    &::before{
      content: '';
      display: block;
      padding-top: 150% ;
      border: solid 2px #CC0034;
      transform: skewY(-20deg);
      transition: 0.2s;
      .card-item:hover &{ background: #CC0034; }
    }
    &::after{
      content: '';
      display: block;
      position:absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: 50% auto;
      background-position: center;
      background-repeat: no-repeat;

    }
  }

  &__content{
    position:absolute;
    bottom: 14%;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  &__icon{
    font-weight: 500;
    font-size: 24px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 3px solid $wh;
    text-indent: 2px;

    body.rtl-lang & {
      text-align: center;
    }
  }
  &__count{
    font-size: 56px;
    line-height: 66px;
    font-weight: 700;
    text-align: center;
    color: #FFFFFF;
    body.rtl-lang & {
      text-align: center;
    }
  }

  @include m-max{
    --card-radius:6px;
    --card-radius-1:6px;
    &__back{ padding:8px; }
    &__content {
      gap: 6px;
    }
    &__icon{
      font-size: 20px;
      border: 2px solid $wh;
      text-indent: 1px;
      width: 30px;
      height: 30px;
    }
    &__count{
      font-size: 42px;
      line-height: 49px;
    }
  }

  @include s-max{
    --card-radius:6px;
    --card-radius-1:6px;
    &__back{ padding:8px; }
    &__content {
      gap: 4px;
    }
    &__icon{
      font-size: 16px;
      text-indent: 0;
      border: 1.5px solid $wh;
      width: 20px;
      height: 20px;
    }
    &__count{
      font-size: 29px;
      line-height: 34px;
    }
  }
}







//.card-list:hover .card-list__item:nth-child(1) .card-item{
//
//
//  position:fixed;
//  top: 200px;
//  left: 400px;
//}
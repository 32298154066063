$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.footer-app {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;

    &__item {
        height: 44px;
        background-color: #273242;
        border: 1px solid #2A394E;
        border-radius: 2px;
        font-style: normal;
        font-weight: 400;
        background-repeat: no-repeat;
        background-size: 28px;
        position: relative;
        cursor: pointer;
        transition-property: background-color, border-color, color;
        transition-duration: .2s;
        $item: &;

        &.is-desktop {
            padding-block: 6px;
            padding-inline: 44px 9px;
            background-image: var(--icon-rabona-square);
            background-position: 8px center;

            body.rtl-lang & {
                background-position: calc(100% - 8px) center;
            }
        }

        &.is-ios,
        &.is-android {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            background-position: 8px;
            padding-block: 6px;
            padding-inline: 44px 9px;

            body.rtl-lang & {
                background-position: calc(100% - 8px) 8px;
            }
        }

        &.is-ios {
            background-image: var(--icon-ios-square);

            & #{$item}-qr-image {
                background-image: url('/joxi/rabona/pwa-instructions/qr-ios.png');
            }

            & .footer-app__item-qr {
                inset-inline-start: 0;

                &::after {
                    inset-inline-start: 10%;
                }
            }
        }

        &.is-android {
            background-image: var(--icon-android-square);

            & #{$item}-qr-image {
                background-image: url('/joxi/rabona/pwa-instructions/qr-code-android.png');
            }
        }
        &.is-telegram {
            background-image: url($cdn + '/rabona/apps/telegram.svg');
            background-position: center;
        }
        &.is-twitter {
            background-image: url($cdn + '/rabona/apps/twitter.svg');
            background-position: center;
        }
        &.is-facebook {
            background-image: url($cdn + '/rabona/apps/facebook.svg');
            background-position: center;
        }
        &.is-youtube {
            background-image: url($cdn + '/rabona/apps/youtube.svg');
            background-position: center;
        }
        &:hover {
            background-color: #2A394E;
            
            .footer-app__item-qr {
                visibility: visible;
            }
        }
    }
    &__item-title {
        font-size: 12px;
        line-height: normal;
        color: #a5afc0;
    }

    &__item-os {
        font-size: 14px;
        line-height: 16px;
        color: #eff5fd;
    }

    &__item-qr {
        position: absolute;
        visibility: hidden;
        display: flex;
        align-items: center;
        flex-flow: column;
        justify-content: center;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
        border-radius: 2px;
        background-color: #fff;
        width: 192px;
        height: 186px;
        z-index: 50;
        bottom: calc(100% + 10px);
        inset-inline-start: -75px;
        padding: 8px 16px;

        &::after {
            content: '';
            position: absolute;
            bottom: -7px;
            width: 12px;
            height: 8px;
            left: 50%;
            transform: translateX(-50%);
            background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.99941 7.81641L11.3162 0.628025L0.682617 0.628025L5.99941 7.81641Z' fill='white'/%3E%3C/svg%3E%0A");
        }
    }

    &__item-qr-image {
        width: 100%;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    &__item-qr-description {
        font-weight: 700;
        font-size: 14px;
        color: #2A394E;
        text-align: center;
    }
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.promo-popup{
  width: 934px;
  background: #192333;
  padding: 272px 77px 40px;
  color:#fff;
  position:relative;
  animation: popupShowWrap .2s linear ;


  &::before{
    content: '';
    display: block;
    width: 100%;
    height: 130px;
    position: absolute;
    top: 261px;
    left: 0;
    background: linear-gradient(180deg, rgba(25, 35, 51, 0) 0%, #192333 100%);
    z-index: 1002;
  }



  &__img{
    position:absolute;
    left: calc( 50% - 327px);
    top:80px;
    width: 655px;
    height: 240px;

    transform: rotate(9deg);
    z-index: 1001;

    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;


    animation: popupShowWrapInside .5s linear ;
    animation-delay: .1s;
    animation-fill-mode: backwards;

  }
  &__content{
    position:relative;
    z-index: 1003;

    animation: popupShowWrapInside .5s linear ;
    animation-delay: .1s;
    animation-fill-mode: backwards;

  }
  &__offer{
    font-weight: bold;
    font-size: 32px;
    line-height: 37px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom:50px;
  }

  &__get{
    width: 300px;
    margin:50px auto 0;
  }
  &__str{
    width: 280px;
    font-size: 16px;
    line-height: 19px;
    margin:12px auto;
  }
  &__btn{
    width: 293px;
    margin:20px auto 0;
  }
  &__link{
    text-align: center;
    margin-top:20px;
    & a{
      color:$yellow;
      display: inline-flex;
      cursor: pointer;
      &::before{
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        margin-right:4px;

      }
    }
  }

  &__subtitle{
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #A7AFBE;
    text-align: center;
    margin: 30px 0 20px;
  }
  &__terms{
    font-size: 16px;
    line-height: 24px;
    color: #A7AFBE;
    & p{ margin:10px 0;}
  }
  &__up{
    margin-top:30px ;
    text-align: center;
  }
}
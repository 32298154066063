$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.payments-list {
    &-wrap {
        padding: 34px 0 56px;
        margin: 0 auto;
        width: 920px;
        min-width: 920px;

        body.rtl-lang & {
            direction: rtl;
        }
    }
    &__title {
        font-size: 24px;
        line-height: 28px;
        font-weight: bold;
        color: $wh;

        text-align: center;
    }
    &__description {
        width: 780px;
        margin: 0 auto;
        font-size: 18px;
        line-height: 24px;
        text-align: left;
        color: $blueGray;
    }
    &-select {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 24px;

        &__country {
            text-overflow: ellipsis;
            text-align: end;

            body.rtl-lang & option {
                direction: ltr;
            }
        }
    }
    &-tabs {
        display: flex;
        justify-content: center;
        align-items: center;
        &__item {
            font-style: italic;
            font-size: 18px;
            line-height: 21px;
            text-align: center;
            text-transform: uppercase;
            color: #A7AFBE;

            transform: skew(-15deg);
            cursor:pointer;
            margin-right: 2px;

            & label {  
                display: inline-flex;
                align-items: center;
                justify-content: center;
                height: 56px;
                width: 216px;
                transform: skew(15deg);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                cursor: pointer; 
            }
            &:hover{ background: rgba(255, 255, 255, 0.045); }
            &.active{
            background: rgba(255, 255, 255, 0.045);
            border-bottom: solid 1px $red;
            color: #FFFFFF;
            }
            &>input {
                display: none;
            }
        }
    }
}
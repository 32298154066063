$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.challenge-info {
     &__title {
         font-size: 44px;
         font-weight: 700;
         line-height: 54px;
         text-transform: uppercase;
         color: $yellow;

         margin-bottom: 6px;
     }

     &__subtitle {
         font-size: 18px;
         font-weight: 700;
         line-height: 22px;
         text-transform: uppercase;
         color: $wh;
     }

     &__value {
         font-size: 28px;
         font-weight: 700;
         line-height: 34px;
         color: $wh;

         span:first-child {
             color: $yellow;
         }
     }
}
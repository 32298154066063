$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.game-s1 {
    display: block;
    padding-inline-end: 5px;
    margin-bottom: 16px;

    &:last-child {
        margin-bottom: 0;
    }

    body.rtl-lang & {
        direction: rtl;
    }

    &__link {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 5px;

        &:hover {
            background-color: rgba(0,0,0,0.1);
        }
    }

    &__img {
        flex-shrink: 0;
        width: 76px;
        height: 60px;
        margin-inline-end: 13px;

        background-size: cover;
        background-position: center;
        border-radius: 4px;
        background-color: rgba(0,0,0,0.2);
    }
    &__title {
        font-size: 16px;
        line-height: 19px;
        color: $blueGray;
        .game-s1__link:hover &{ color:#fff;}
    }
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.profile-bonuses{


  &__stub{
    margin-top:66px;
  }
  &__stub-img{
    height: 284px;
    background-color: $red ;
    background-image: url( $cdn + "/rabona/profile/pic-profile-nobonus.jpg");
    background-size: auto 100%;
    background-position: left center;
  }
  &__stub-btn{
    margin-top: 39px;
    text-align: center;
  }

  &__section{
    margin-bottom:4px;
  }

  &__subtitle{
    display: inline-block;
    margin: 32px 0 10px;
    padding-bottom:2px;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #FFFFFF;
    border-bottom:solid 2px $red;
    &.is-first{
      margin-top: 38px;
    }
  }

  &__row{
    display: flex;
    flex-wrap: wrap;
    margin:0 -10px;
  }
  &__item{
    width: calc(50% - 20px);
    margin:10px;

  }



  @include s{
    &__item{
      width: calc(33.3334% - 20px);
    }
  }


}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.profile-sidebar {
  &__menu {
    background: $red;
    padding: 26px 30px 67px;
    color: #fff;
  }
  &__links {
    padding: 0px 30px;
    position: relative;
    &::after {
      width: 281px;
      content: '';
      position: absolute;
      left: 0;
      top: -137px;
      z-index: 10;

      display: block;
      height: calc(100% + 196px);

      clip-path: polygon(0 120px, 100% 0, 100% calc(100% - 120px), 0 100%);
      background: #293241;

      body.rtl-lang & {
        scale: -1 1;
      }
    }
  }
  &__wrap {
    position: relative;
    z-index: 12;
  }

  &__title {
    font-size: 20px;
    line-height: 23px;
    text-transform: uppercase;
    color: #ffffff;
    padding-bottom: 3px;
    border-bottom: solid 2px #fff;
    margin-bottom: 13px;
    &.is-dark {
      border-bottom: solid 2px $red;
      margin-bottom: 18px;
    }
  }

  &__item {
    --fz: 14px;
    position: relative;
    display: flex;
    align-items: center;
    z-index: 12;
    line-height: 24px;
    min-height: 48px;
    margin-bottom: 4px;
    font-weight: bold;
    font-size: var(--fz);
    text-transform: uppercase;
    cursor: pointer;
    color: #fff;
    &.active,
    &:hover {
      color: $yellow;
    }
    text-decoration: none;
    @include m {
      font-size: 16px;
    }
  }
  &__link {
    display: block;
    color: #fff;
    min-height: 44px;
    line-height: 24px;
    padding: 10px 0;
    cursor: pointer;
    text-decoration: none;
    &.has-icon {
      &::before {
        content: '';
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-block-end: -2px;
        margin-inline-end: 12px;
        background-size: 24px;
        background-position: center;
        background-repeat: no-repeat;
        filter: $whFilter;
      }
    }
    &:hover {
      color: $yellow;
      &::before {
        filter: $yellowFilter;
      }
    }

    &.is-chat {
      display: flex;
      align-items: center;

      &::before {
        margin-inline-end: 6px;
        content: '';
        display: block;
        width: 20px;
        height: 28px;
        background-image: var(--icon-i-support-hover2);
        background-position: -6px center;
        background-repeat: no-repeat;
        filter: brightness(100);
      }
    }
    &:hover::before {
      filter: brightness(0) $yellowFilter;
    }

    body.rtl-lang & {
      &::before {
        order: 1;
      }
    }
  }

  body.rtl-lang &__link {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
  }
}

$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.holiday-promo-wrapper {
  @include xs {
    margin-top: -5px;
  }
  @include s {
    margin-top: -15px;
  }
}


body.rtl-lang {
  .easter-box{
    &__num,
    &__panel-label,
    &__panel-title,
    &__panel-text,
    &__panel-info,
    &__row-title,
    &__row-bonus,
    &__row-divider {
      text-align: center;
    }
    &__requirements-item-descr{
      text-align: left;
    }
  }
}
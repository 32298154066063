$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.dropdown {
    $this: &;
    position: relative;
    // z-index: 1;
    display: inline-flex;
    align-items: center;
    height: 36px;
    padding: 4px 4px 4px 15px;

    font-size: 16px;
    line-height: 16px;
    color: $wh;

    background-image: linear-gradient(to top,
            #2D394C,
            #2D394C);
    background-repeat: no-repeat;
    background-size: 100% 0;
    background-position: top center;
    transition: var(--animation-duration) background-size var(--animation-duration);

    &::before,
    &::after {
        content: '';
        width: 28px;
        height: 28px;
        flex-shrink: 0;
    }

    &::before {
        order: 0;
        background-image: var(--icon-i-money);
    }

    &::after {
        order: 2;
        background-image: var(--icon-i-arrow);
        filter: $blueGrayFilter;
        transition: transform var(--animation-duration);
        transform: rotate(0);
    }

    &__text {
        order: 1;
        padding: 0 8px;
    }

    &__list {
        position: absolute;
        inset-inline-start: 0;
        top: 100%;

        display: flex;
        flex-direction: column;
        min-width: 250px;
        max-height: 0;
        padding-top: 0;
        padding-bottom: 0;

        transition:
            var(--animation-duration) max-height,
            var(--animation-duration) padding;
        overflow: hidden;

        background-color: $blueLight;

        font-size: 16px;
        line-height: 24px;
        color: $blueGray;
    }

    &__item {
        display: flex;
        flex-shrink: 0;
        padding: 10px 20px;
        justify-content: space-between;
        align-items: center;
        color: inherit;
        cursor: pointer;

        &:hover {
            background-color: $blueMenu;
        }

        &_logout {
            cursor: pointer;

            &::before {
                content: '';
                width: 15px;
                height: 15px;
                margin-inline-end: 11px;

                background-image: var(--icon-i-logout-black);
                filter: $whFilter;
            }
        }
    }

    &__item-value {
        color: $wh;
        padding-left: 10px;
        background-image: var(--money);

        body.rtl-lang & {
            direction: ltr;
            padding-left: initial;
            padding-right: 10px;
        }
    }

    &__item-y {
        text-transform: uppercase;
        color: $yellow;
        padding: 10px 20px;

        &:hover {
            text-decoration: underline;
        }
    }

    &__group {
        display: flex;
        flex-direction: column;
        background-color: #293241;
        border-top: 1px solid $blueMenu;

        margin-bottom: -10px;

        cursor: pointer;
    }

    &:hover {
        background-size: 100% 100%;
        transition: var(--animation-duration) background-size;

        &::after {
            filter: invert(100%);
            transform: rotateX(-180deg);
        }

        &::before {
            background-image: var(--icon-i-money-hover);
        }

        #{$this}__text {
            color: $yellow;
        }

        #{$this}__list {
            max-height: unset;
            padding-top: 10px;
            padding-bottom: 10px;

            transition: var(--animation-duration) max-height var(--animation-duration),
                var(--animation-duration) padding var(--animation-duration);
        }
    }
    &_settings {
        #{$this}__list {
            color: $wh;

            min-width: 200px;
        }
        #{$this}__item {
            justify-content: flex-start;
        }
        @include m-max {
            #{$this}__list {
                inset-inline-start: auto;
                inset-inline-end: 0;
            }

        }
        &::before {
            background-image: var(--icon-i-settings);
        }

        &:hover {
            &::before {
                background-image: var(--icon-i-settings-hover);
            }
        }
    }

    body.rtl-lang {
        &_settings {
            #{$this}__item {
                justify-content: flex-start;
            }
        }

        &_logout {
            flex-direction: row-reverse;
            justify-content: flex-start;
        }
    }
}

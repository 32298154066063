$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    list-style: none;
    padding: 0;
    z-index: 1;
  }

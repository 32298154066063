$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.real-money-crab {
  position: relative;
  z-index: 0;
  width: 100%;
  min-height: calc(100vh - var(--header-h));
  display: flex;

  .crab-area-73aos {
    min-height: 100%;
    height: auto !important;
    display: flex;

    iframe {
      width: 900px;
      height: 574px;
      border-radius: 20px;
      margin: 40px auto;

      @media screen and (min-width: 1274px) {
        height: 764px;
        width: 1274px;
        margin: 20px auto;
      }
    }
  }

  .has-header:has(.is-verification-changed) & {
    min-height: calc(100vh - var(--header-h-with-verification));
  }
}

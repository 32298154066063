$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.bankid-form {
    position: relative;

    &__btn-block {
        border: 1px solid #FFFFFF;
        border-radius: 2px;
        margin-bottom: 16px;

    }

    &__btn-item {
        background: rgba(255, 255, 255, 0.5);
        font-size: 24px;
        line-height: 44px;

        &.is-active {
            background-color: #fff;
            color: #192333;
        }
    }

    &__input {
        font-size: 18px;
        font-weight: 700;
        line-height: 56px;
        text-align: center;
        color: $wh;

        padding: 0 20px;

        height: 56px;
        width: 100%;

        //background: rgba(12, 18, 33, 0.8) var(--icon-trustly-form) right 13px center / 20px no-repeat;
        background-color: rgba(12, 18, 33, 0.8);
        box-shadow: inset 0 0 0 1px $yellow;

        border-radius: 2px;
        border: 0;

        &:focus {
            box-shadow: inset 0 0 0 2px $yellow;
        }

        &.ng-invalid {
            background-color: $blueStroke;
            //background-image: var(--icon-trustly-form-invalid);
            box-shadow: inset 0 0 0 1px $red;
            color: $red;
            & + .form-label .bankid-form__icon{
                filter:brightness(0) invert(100);
            }
        }

        &::placeholder {
            font-size: inherit;
            font-weight: inherit;
            color: inherit;
        }

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }


    }
    &__icon{
        width: 28px;
        height: 28px;
        position:absolute;
        right: 10px;
        top: 14px;
        background-image: var(--icon-pay_bank-s);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        filter:brightness(0) invert(95%) sepia(68%) saturate(5731%) hue-rotate(8deg) brightness(105%) contrast(106%);
    }
    &__currency {
        display: inline-block;
        position: absolute;
        left: 19px;
        top: 20px;

        font-size: 18px;
        font-weight: 700;
        line-height: 16px;
        color: $yellow;
    }
    &__input.ng-invalid +.form-label &__currency {
        color: $blueGray;
    }

    &__form-error p {
        padding: 6px 20px;
        background-color: $blueDark;
        border-radius: 0px 0px 1px 1px;
    }
    &__form-error_bnr {
        @include s {
            position: absolute;
            right: 0;
            top: 0;
            transform: translateX(100%);

            p {
                align-items: center;
                min-height: 56px;
            }
        }
    }

    &__btn {
        margin-top: 20px;
    }
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.bankid-form-banner {
  position: relative;

  &__label-block {
    position: relative;
    display: block;
  }

  &__span-currency {
    display: inline-block;
    position: absolute;
    left: 24px;
    top: -54px;
    font-size: 24px;
    font-weight: bold;
    line-height: 54px;
    color: #192333;
  }

  &__btn-block {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    border: 1px solid #FFFFFF;
    border-radius: 2px;
    margin-bottom: 16px;
    background-image: linear-gradient(to right, #fff, #fff), linear-gradient(to right, #fff, #fff);
    background-repeat: no-repeat;
    background-size: 1px 44px;
    background-position: left 119px center, right 119px center;
  }

  &__btn-item {
    background: rgba(255, 255, 255, 0.5);
    font-size: 24px;
    line-height: 44px;
    height: 44px;
    border: none;
    color: #fff;

    &.is-active {
      background-color: #fff;
      color: #192333;
      font-weight: bold;
    }

    &:hover {
      font-weight: bold;
    }
  }

  &__form-label {
    position: relative;
  }

  &__input {
    font-size: 24px;
    font-weight: bold;
    line-height: 54px;
    color: #192333;
    text-align: center;
    padding: 0 48px;
    height: 54px;
    width: 100%;
    //background: rgba(255, 255, 255, .8) var(--icon-trustly-black) right 24px center/24px no-repeat;
    background-color: rgba(255, 255, 255, .8);
    box-sizing: border-box;
    border-radius: 2px;
    box-shadow: inset 0 0 0 1px #fff;
    border: none;

    &:focus {
      box-shadow: inset 0 0 0 2px #fff;
    }

    &.ng-invalid {
      box-shadow: inset 0 0 0 1px $red;
      //background-image: var(--icon-trustly-grey);
      color: $red;

      & + .bankid-form-banner__form-label .bankid-form-banner__span-currency {
        color: rgba(25, 35, 51, 0.5);
      }
      & + .bankid-form-banner__form-label .bankid-form-banner__icon {
        opacity: 0.5;
      }
    }

    &::placeholder {
      font-size: inherit;
      font-weight: inherit;
      color: inherit;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

  }
  &__icon{
    width: 28px;
    height: 28px;
    position:absolute;
    right: 14px;
    top: -40px;
    background-image: var(--icon-pay_bank-s);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    filter:brightness(0) ;
  }

  &__input.ng-invalid + .form-label &__currency {
    color: $blueGray;
  }

  &__form-error {

    p {
      padding: 6px 20px;
      background-color: $blueDark;
      border-radius: 0px 0px 1px 1px;
      justify-content: center;
      text-align: center;
    }
  }

  &__form-error_bnr {

    max-height: 54px;
    overflow: hidden;

    @include s {
      //position: absolute;
      //right: 0;
      //top: 0;
      //transform: translateX(100%);

      //p {
      //  align-items: center;
      //  min-height: 56px;
      //}
    }
  }

  &__btn {
    margin-top: 16px;
    padding: 0 45px;

    &:before {
      content: "";
      position: absolute;
      right: 23px;
      top: 16px;
      width: 20px;
      height: 20px;
      background-image: var(--icon-arr-r);
      background-repeat: no-repeat;
      background-size: 20px;
      background-position: center;
    }
  }

  &__text-reg {
    @include text(16px, 19px, null, #fff);
    margin-top: 12px;
    @include ellipsis;
    text-transform: uppercase;
  }

  &__link {
    text-decoration: underline;

    &:hover {
      color: $yellow;
    }
  }

}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.entrance-bnr-wrapper .slick-slider:has(.slick-slide.slick-active .is-oktoberfest) + batman-banner9 {
  display: none;
}

.entrance-bnr {
  &__bg {
    position: absolute;
    z-index: -2;
    left: 0;
    top: 0;
    width: 100%;
    height: 643px;
    object-fit: cover;
    background-position: top;
  }

  &.is-oktoberfest {
    padding-left: 36px;
    .entrance-bnr__img {
      transform: none !important;
      top: 0;
      right: 0;
      width: 100%;
      background-size: cover;
      left: inherit;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 50%;
        background: linear-gradient(90deg, #570f22 0%, rgba(58, 18, 0, 0) 100%);
      }
    }
    .entrance-bnr__overlay {
      display: none;
    }
  }

  body.rtl-lang & {
    direction: rtl;
  }
}


.home-ao-banner{
  height: 387px;
  background-position: left -360px top -185px;
  background-size: auto 750px;
  position: relative;
  overflow: hidden;

  @include m {
    background-position: left -150px top -185px;
  }

  &::before{
    width: 100%;
    height: 100%;
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    background: linear-gradient(90deg, rgba(0, 145, 210, 0.03) 0%, #0091D2 100%);
    filter: blur(0.5px);
  }
  &-wrap{
    padding: 50px 0 0;
    display: block;
    &.is-clf{
      padding-top: 0;
      padding-bottom: 32px;
    }
    &:empty{
      display: none;
    }
  }
  &__logo{
    display: block;
    width: 339px;
    height: 339px;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    left: -27px;
    top: 24px;
    @include s {
      width: 456px;
      height: 456px;
      left: 48px;
      top: -34px;
    }
    @include m {
      left: 64px;
    }
  }
  &__hero{
    width: 504px;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 0;
    left: 75px;
    @include s {
      left: 305px;
    }
    @include m {
      left: 313px;
    }
  }
  &__title{
    color: #FFF;
    text-align: right;
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    text-transform: uppercase;
    margin-bottom: 16px;
  }
  &__text{
    color: #ECFD00;
    text-align: right;
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    text-transform: uppercase;
  }
  &__content{
    padding: 48px;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-left: auto;
    position: relative;
    align-items: flex-end;
  }
  &__btn{
    --btnHeight: 56px;
    margin-top: auto;
  }
}
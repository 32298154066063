$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.cashbox-form{
  min-height: 484px;
  display: flex;

  body.rtl-lang & {
    direction: rtl;
  }

  &__l{
    height: 100%;
    width: 50%;
    flex-shrink: 0;
    border-right:solid 1px #F0F5FC;
    padding:24px 33px 40px 33px;
  }
  &__r{
    width: 50%;
    flex-shrink: 0;
    border-inline-start:solid 1px #F0F5FC;
    height: 100%;
    padding:24px 44px 40px 33px;
  }

  &__title{
    font-size: 20px;
    line-height: 120%;
    text-align: center;
    color: #0C1221;
  }


  &__scroll{
      margin-top:20px;
      height: 384px;
      overflow: auto;
      width: calc(100% + 15px);
  }
  &__scroll-content{
    position:relative;
    width: 411px;
  }

  &__back{
    position:absolute;
    top: 16px;
    inset-inline-start: 0;
    height: 40px;
    display: flex;
    align-items: center;
    padding-inline-start: 8px;
    padding-inline-end: 20px;
    background: #F0F5FC;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #0C1221;
    cursor:pointer;
    &:hover{ background: none;}
    &::before{
      content: '';
      display: block;
      width: 10px;
      height: 18px;
      background-image: var(--icon-arr-l);
      background-size: auto 18px;
      margin-inline-end:6px;
      cursor:pointer;
      
      body.rtl-lang & {
        background-image: var(--icon-arr-r);
        background-position: 100% 0;
      }
    }
  }
  &__img{
    margin:0 auto;
    width: 180px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    & img{
      width: 153px;
      height: 30px;
    }
  }
  &__desc{
    margin-top:20px;
    font-size: 18px;
    line-height: 120%;
    color: #596475;
    text-align: center;
  }
  &__desc-sum {
    display: inline-flex;
    gap: 2px;
    &:first-child {
      margin-inline-end: 5px;
    }
  }
  &__desc-currency {
    body.rtl-lang & {
      order: 1;
    }
  }
  &__field{
    padding-bottom:20px;
  }
  &__amount{
    min-height: 356px;
    padding-bottom:12px;

  }
  &__btns{ position:relative; }
  &__sum {
    display: inline-block;
    margin-left: 4px;
  }
  &__rules{
    position:absolute;
    bottom: -28px;
    inset-inline-start: 0;
    width: 100% ;
    height: 20px;
    font-size: 14px;
    line-height: 20px;
    color: #596475;
    text-align: center;
    &::before{
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-inline-end:4px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      margin-bottom:-3px;
    }
  }

  &__rules-sum {
    body.rtl-lang & {
      display: inline-block;
    }
  }

  &__interac {
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    color: #576477;
    margin: 8px 0;
  }

  &.has-qr-code .cashbox-form__r > *:not(.cashbox-qr) {
    display: none;
  }
}

.hide-amount .if-hide-amount{ display: none !important;}
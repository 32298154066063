$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.btn-par {
    --gradient: linear-gradient(180deg, #CC0034 -27.4%, rgba(188, 31, 57, 0) 100%);
    position: relative;

    display: flex;
    align-items: center;
    height: 32px;
    padding: 0 14px;

    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    color: #fff;

    box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.03);
    clip-path: parallelogram(14px);

    body.rtl-lang & {
        clip-path: rtl-parallelogram(14px);
    }

    & + .btn-par {
        margin-inline-start: -9px;
    }

    &::after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: var(--gradient);
        opacity: .8;
        animation: attention 10s linear infinite;
    }

    &::before {
        content: '';
        width: 28px;
        height: 28px;
    }

    &:hover {
        color: $yellow;
        &::after { opacity: 1; }
    }
    &__text { margin-inline-start: 4px; }
    &__icon {
        display: block;
        width: 28px;
        height: 28px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 16px;
    }
    & .label-free {
        display: none;
        flex-shrink: 0;
        background-color: #BC1F39;
        min-width: 40px;
        height: 16px;
        padding: 2px 8px;
        margin-inline-start: 4px;
        font-size: 12px;
        color: #ffffff;
        line-height: 12px;
        text-transform: uppercase;
        text-shadow: none;
        text-align: center;
        clip-path: polygon(0 100%,8px 0,100% 0,calc(100% - 8px) 100%);

        &.is-shown { display: block;}
    }

    &.is-collection::before{ background-image: var(--icon-i-collection); }
    &.is-collection:hover::before{ background-image: var(--icon-i-collection-hover);}
    &.is-cup::before{ background-image: var(--icon-i-cup); }
    &.is-cup:hover::before{ background-image: var(--icon-i-cup-hover);}

    &.is-bonus::before{ background-image: var(--icon-i-gift); }
    &.is-bonus:hover::before{ background-image: var(--icon-i-gift-hover);}
    &.is-vip::before{ background-image: var(--icon-i-vip);}
    &.is-vip:hover::before{ background-image: var(--icon-i-vip-hover);}
    &.is-crab::before{ background-image: var(--icon-i-crab);}
    &.is-crab:hover::before{ background-image: var(--icon-i-crab-hover);}
}

@keyframes attention {
    0% {
        opacity: .8;
    }
    74% {
        opacity: .8;
    }
    75% {
        opacity: 1;
    }
    80% {
        opacity: .5;
    }
    85% {
        opacity: 1;
    }
    90% {
        opacity: .5;
    }
    95% {
        opacity: 1;
    }
    100% {
        opacity: .8;
    }

}

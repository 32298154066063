$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.card-list {
  display: flex;
  flex-wrap: wrap;
  position:relative;
  margin-top: -17px;
  justify-content: center;
  padding:0 10px;
  min-height: 400px;
  transition: height 0.2s;

  body.rtl-lang & {
    direction: rtl;
  }

  &__item {
    position: relative;
    width: calc(16.6667% - 20px);
    margin: 0 10px 42px;
    z-index: 30;

    &::before{
      content: '';
      display: block;
      width: 100%;
      padding-top: 149.3%;
    }
  }

  @include s{
    padding: 0 30px;
    margin-top: -25px;
  }

  @include m{
    padding: 0 24px;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    &__item { width: calc(16.6667% - 32px); margin:0 16px 44px; }
  }
}




 /*            ANIMATION NG-REPEAT    */
/*    !!! Для мобайла НЕ підходить   */

.card-list__item.ng-move,
.card-list__item.ng-enter{
  -webkit-transition:all linear 0.5s;
  transition:all linear 0.5s;

}

.card-list__item.ng-move,
.card-list__item.ng-enter {
  opacity:0;
  transform: translateY(10px);
}


.card-list__item.ng-move.ng-move-active,
.card-list__item.ng-enter.ng-enter-active {
  opacity:1;
  transform: translateY(0px);
}


.card-list__item.ng-move-stagger,
.card-list__item.ng-enter-stagger {
  transition-delay: 0.1s;
  transition-duration: 0s;
}
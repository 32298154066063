$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.footer__list {
    grid-row: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &-title {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 12px;
        line-height: 1.15em;
        letter-spacing: 0.2em;
        color: rgba(#A5AFC0, 0.5);
        margin-bottom: 10px;
        min-height: 28px;
    }

    &-link {
        font-weight: 400;
        font-size: 16px;
        line-height: 1.2em;
        color: #A5AFC0;
        text-transform: capitalize;

        &:not(:last-child) {
            margin-bottom: 21px;
        }

        &:not(.active):hover {
            text-decoration: underline;
        }

        &.active {
            color: #ECFD00;
        }
    }

    &-links {
        margin-top: 21px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    &-links-item {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 12px;
        line-height: 1.15em;
        letter-spacing: 0.2em;
        color: rgba(#A5AFC0, 0.5);

        &:not(:last-child) {
            margin-bottom: 17px;
        }

        &:hover {
            text-decoration: underline;
        }

        &:active {
            text-decoration: none;
        }
    }
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.cashbox-balance{

  margin-top:20px;
  border: 1px solid #F0F5FC;
  padding: 4px 6px 8px;
  display: flex;

  &__item{
    position:relative;
    width: 117px;
    height: 44px;
    margin:0 6px;
    padding:4px 0;
    padding-right:14px;
  }

  &__label{
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #596475;

    body.rtl-lang &,
    [lang="pt"] &,
    [lang="sl"] & {
      font-size: 10px;
    }
  }
  &__sum{
    margin-top:4px;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #0C1221;
    display: flex;
    gap: 3px;
  }
  &__sum-currency {
    body.rtl-lang & {
      order: 1;
    }
  }
  &__i{
    position:absolute;
    bottom: 4px;
    inset-inline-end: 0;
    width: 10px;
    height: 10px;
    background-size: contain ;
    background-repeat: no-repeat;
    &.is-refresh{
      cursor: pointer;
      &.linda-has-preloader{
        animation: rotate360 linear 0.7s infinite;
      }

    }
  }
}

$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.cashbox-crypto {
  width: 100%;
  max-width: 448px;
  margin: 0 auto;
  padding: 20px 0 12px 0;

  body.rtl-lang & {
    direction: rtl;
  }

  &__back {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 400;
    font-size: 14px;
    line-height: 1em;
    color: #0b1222;
    background-color: #eff5fd;
    border-radius: 2px;
    transition: background-color 0.2s ease;
    border: none;
    height: 36px;
    padding-inline-start: 8px;
    padding-inline-end: 16px;
    margin-bottom: 20px;

    .no-btn-back & {
      display: none;
    }

    &:hover {
      background-color: transparent;
    }

    &:before {
      content: '';
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      width: 16px;
      height: 16px;
      margin-inline-end: 4px;
      flex-shrink: 0;
      background-image: var(--icon-arr-l);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      
      body.rtl-lang & {
        background-image: var(--icon-arr-r);
      }
    }
  }

  &__image {
    height: 108px;
    margin-bottom: 20px;
    background-color: #eff5fd;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-height: 60px;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 1.2em;
    text-align: center;
    color: #0b1222;
    margin-bottom: 20px;
  }

  &__list {
    margin-bottom: 20px;
  }

  &__info {
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.4em;
    color: #2a394e;
  }

  &__info-sum {
    display: inline-flex;
  }

  &__info-currency {
    body.rtl-lang & {
      order: 1;
    }
  }

  &__form {
    margin-bottom: 20px;
  }

  &__form-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2em;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    color: #0b1222;
    min-height: 20px;
    margin-bottom: 8px;
  }

  &__form-field {
    margin-bottom: 20px;
  }

  &__submit {
    font-weight: 700;
  }

  &__link {
    font-size: 14px;
    line-height: 16px;
    color: #a7afbe;
    cursor: pointer;
    display: block;
    margin: 24px auto;
    width: max-content;
    &::after {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      background: var(--icon-arr-r);
      filter: $grayFilter;
      margin-inline-start: 4px;
      
      body.rtl-lang & {
        background: var(--icon-arr-l);
      }
    }
  }
}

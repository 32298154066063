$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.tooltip {
  position:absolute;
  left: calc(50% - 108px);
  width: 216px;
  background: $red;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  padding: 20px;
  z-index: 50;
  display: none;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #FFFFFF;
  
  .has-tooltip:hover & {
    display: block;
  }

  &.is-top {
    bottom: calc( 100% + 10px);
  }

  &.is-bottom {
    top: calc( 100% + 10px);
  }

  &::before {
    height: 0;
    content: '';
    position:absolute;
    left: calc(50% - 8px);
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
  }

  &.is-left-dir {
    right: calc(100% + 16px);
    left: auto;
    top: 50%;
    transform: translateY(-50%);
    width: 168px;

    &::before {
      border-width: 8px 0 8px 10px;
      border-style: solid;
      left: 100%;
      top: 50%;
      transform: translateY(-50%);
      border-color: transparent transparent transparent $red;
    }

    body.rtl-lang & {
      left: calc(100% + 10px);
      right: auto;

      &::before {
        left: auto;
        right: 100%;
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  &.is-top::before {
    border-top: 8px solid $red;
    bottom: -8px;
  }

  &.is-bottom::before {
    border-bottom: 8px solid $red;
    top: -8px;
  }

  &.is-blue {
    background: #F0F5FC;
    color: #0C1221;
    padding: 8px;

    &::before {
      border-top-color: #F0F5FC;
      border-bottom-color: #F0F5FC;
    }
  }




  &__row{
    display: flex;
    justify-content: space-between;

    &:not(:first-child) {
      margin-top:7px;
    }
  }

  &__key {
    text-align: left;
    width: 120px;
    flex-shrink: 0;
    overflow: hidden;
  }

  &__val {
    text-align: right;
    width: 50px;
    flex-shrink: 0;
    overflow: hidden;

    &-percent {
      &::before {
        content: 'الرهان';
        font-size: 0;
        line-height: 0;
        pointer-events: none;
      }
    }
  }
}



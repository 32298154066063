$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.challenge-page {
    display: grid;
    grid-template-areas:
        'info1 info1 info1 info1 info1'
        'ach   ach   ach   ach   ach';
    grid-template-columns: 33px 1fr 26px 1fr 33px; 

    max-width: 1022px;
    margin: 23px auto 42px auto;

    &__info {
        grid-area: info1;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        min-height: 162px;

        box-shadow: inset 0 0 0 5px $wh;
        margin-bottom: 40px;
    }
    
    &__info + &__info {
        grid-area: info2;
    }

    &__achievements {
        grid-area: ach;
        display: grid;
        grid-auto-columns: 1fr;
    }
    &__achievements-box {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 48px;
        align-items: stretch;
    }
    &__tabs {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
    }
    &__tab-wrap {
        position: relative;
        &:after {
            content: "";
            cursor: pointer;
            position: absolute;
            inset-inline-end: 0;
            bottom: 0;
            width: 100px;
            height: calc(100% + 8px);
            background-size: 
                auto calc(100% - 8px),
                auto calc(100% - 8px);
            background-position: right bottom;
            background-repeat: no-repeat;
            transition: 
                background-size .2s ease,
                filter .2s ease;

            filter: grayscale(100%);

            body.rtl-lang & {
                transform: scale(-1, 1);
            }
        }
        &.is-sport-wrap {
            &:after {
                background-image: 
                    url($cdn + "/rabona/profile/sport-png-colour.png"),
                    url($cdn + "/rabona/profile/bg-sport-colour.png");
                
            }
        }
        &.is-casino-wrap {
            &:after {
                background-image: 
                    url($cdn + "/rabona/profile/casino-png-colour.png"),
                    url($cdn + "/rabona/profile/bg-casino-colour.png");
            }
        }
        .is-sport &.is-sport-wrap,
        .is-casino &.is-casino-wrap {
            &:after {
                filter: none;
                background-size: 
                    auto 100%, 
                    auto calc(100% - 8px);
            }
        }
    }
    &__tab {
        clip-path: polygon(34px 0,100% 0,100% 100%,0 100%,0 34px);
        display: flex;
        align-items: flex-end;
        padding-block: 20px;
        padding-inline-start: 48px;
        padding-inline-end: 84px;
        background-image: linear-gradient(135deg, #576477 0%, #576477 28px, #0B1222 28px);
        color: #576477;
        line-height: 1;
        text-transform: uppercase;
        position: relative;
        cursor: pointer;
        
        body.rtl-lang & {
            clip-path: polygon(34px -530%, 100% 34px, 100% 100%, 0 100%, 0 0);
            background-image: linear-gradient(-135deg, #576477 0%, #576477 28px, #0B1222 28px);
        }

        .is-sport .is-sport-wrap &,
        .is-casino .is-casino-wrap & {
            color: $yellow;
            background-image: linear-gradient(135deg, $yellow 0%, $yellow 28px, #2A394E 28px);
            
            body.rtl-lang & {
                background-image: linear-gradient(-135deg, $yellow 0%, $yellow 28px, #2A394E 28px);
            }
        }
    }
    &__tab-title {
        font-size: 36px;
        margin-inline-end: 12px;
    }
    &__tab-progress {
        font-size: 20px;
        padding-bottom: 4px;

        body.rtl-lang & {
            direction: ltr;
        }
    }
    &__tabs-content {
        display: none;
        background-color: #2A394E;
        padding: 30px 48px 40px;
        .is-sport ~  &.is-sport,
        .is-casino ~  &.is-casino {
            display: block;
        }
    }
}
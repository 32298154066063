$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
:root {
    --animation-duration: .15s;
}

body {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    
    font-family: $ff;
    background-color: $blueDark;

    min-width: 1020px;

    &.rtl-lang .ng-binding,
    &.rtl-lang [translate],
    &.rtl-lang table{
        direction: rtl;
    }

    > ui-view {
        overflow: hidden;
    }
}
a{
    text-decoration: none;
}
// helpers


$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.grid {
    --gap: 20px;
    --col: 4;

    display: grid;
    grid-template-columns: repeat(var(--col), 1fr);
    gap: var(--gap);

    &_gamehall {
        @include s {
            --col: 5;
            --gap: 24px;
        }

        @include m {
            --col: 6;
            --gap: 32px;
        }
    }

    &_gamehall-row-6 {
        --col: 4;
        margin-bottom: 32px;

        @include s {
            --col: 5;
        }
        @include m {
            --col: 6;
            margin-bottom: 40px;
        }

        @include s-max {
            .game:nth-child(6) {
                display: none;
            }
        }

        @include m-max {
            .game:nth-child(5) {
                display: none;
            }
        }
    }

    &_gamehall-row-5 {

        margin-bottom: 32px;

        @include s {
            --col: 4;
        }
        @include m {
            --col: 5;
            margin-bottom: 40px;
        }

        @include s-max {
            --col: 3;
            .game:nth-child(5) {
                display: none;
            }
        }

        @include m-max {
            .game:nth-child(4) {
                display: none;
            }
        }
    }
    &__full {
        margin-top: calc(60px - var(--gap));
        grid-column: 1 / -1;
        display: flex;
        justify-content: center;
        height: 48px;
    }
}
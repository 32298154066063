$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.entrance-collection {
  display: flex;
  height: 100%;
  min-height: 390px;
  overflow: hidden;
  flex-direction: column;
  @include s {
    min-height: 451px;
  }

  @include m {
    min-height: 583px;
  }

  &__panel {
    flex-shrink: 0;
    background-color: $blue;
    padding: 18px 24px;
    display: flex;
    flex-direction: column;
    min-height: 168px;
    position: relative;
    flex-grow: 1;
    margin-top: -10px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform: scale(-1, 1);
      background-image: url($cdn + '/rabona/elements/pattern-collections.svg');
      background-repeat: no-repeat;
      background-position: right 240px top -65px;
      background-position: right calc(100% + 270px) bottom -20px;
      opacity: 0.5;
      @include s {
        background-position: right calc(100% + 195px) bottom -20px;
      }
    }

    @media (min-width: 1280px) {
    }

    @include s {
      min-height: 194px;
      padding: 20px 29px;
    }

    @include m {
      min-height: 197px;
      padding: 24px 30px;
    }
  }

  &__bnr {
    position: relative;
    overflow: hidden;

    @media (min-width: 1680px) {
      padding-top: 0;
    }
  }

  &__text {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    @include combine(--lh, 28px, 28px, 35px);
    @include combine(font-size, 24px, 24px, 30px);

    font-weight: 700;
    text-transform: uppercase;
    color: $yellow;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    white-space: pre-wrap;

    .small-font & {
      font-size: 35px;

      @include s {
        font-size: 46px;
      }
    }
  }

  &__subtitle {
    @include combine(--lh, 35px, 35px, 49px);
    @include combine(font-size, 30px, 30px, 42px);

    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    white-space: pre-wrap;
    text-transform: uppercase;

    font-weight: bold;
    color: $wh;
    word-spacing: 2px;
    white-space: break-spaces;

    .small-font & {
      font-size: 27px;
      @include s {
        font-size: 34px;
      }
    }
  }

  &__btn {
    margin-top: 20px;
    max-width: 253px;
  }
}

$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.season-tournament-bnr {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 137px;
  margin-bottom: 78px;
  padding: 0 24px;
  background-position: top right 20%;
  background-repeat: no-repeat;
  background-size: cover;
  &__btn {
    min-width: 222px;
  }
  @include s {
    background-position: center;
  }
}

$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.entrance-category {
  position: relative;
  &-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 16px;
    &__link {
      --btnHeight: 32px;
      margin-left: auto;
      &.btn {
        font-size: 14px;
        min-width: unset;
        color: #162334;
      }
    }
  }

  &-arrow {
    border-radius: 2px;
    border: 1px solid #273242;
    background: #1c2738;
    width: 44px;
    height: 288px;
    z-index: 10;
    position: absolute;
    bottom: 0;
    cursor: pointer;
    @include s {
      height: 350px;
    }
    @include m {
      height: 390px;
    }
    &::after {
      display: block;
      position: absolute;
      content: '';
      width: 20px;
      height: 20px;
      top: 50%;
      left: 50%;
      margin-left: -10px;
      margin-top: -10px;
      filter: brightness(0) saturate(100%) invert(73%) sepia(19%) saturate(213%) hue-rotate(179deg) brightness(92%)
        contrast(92%);
    }
    &.is-disable {
      display: none;
    }
    &.is-next {
      right: 0;
      &::after {
        background-image: var(--icon-arr-r);
      }

      &::before {
        content: '';
        background: linear-gradient(270deg, #0c122180 0%, rgba(12, 18, 33, 0) 100%);
        position: absolute;
        right: 100%;
        width: 12px;
        height: 100%;
      }
    }
    &.is-prev {
      left: 0;
      &::after {
        background-image: var(--icon-arr-l);
      }
      &::before {
        content: '';
        background: linear-gradient(90deg, #0c122180 0%, rgba(12, 18, 33, 0) 100%);
        position: absolute;
        left: 100%;
        width: 12px;
        height: 100%;
      }
    }
  }
  &__wrap {
    display: flex;
    gap: 16px;
    width: 100%;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__item {
    width: 180px;
    height: 288px;
    flex-shrink: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    overflow: hidden;
    cursor: pointer;
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 58px;
      bottom: -30px;
      right: 0;
      mask: url('/img/vip-pattern3.svg');
      background-image: linear-gradient(to top, #a5afc0, rgba(165, 175, 192, 0));
      z-index: 10;
      -webkit-mask-size: 10px;
      @include s {
        -webkit-mask-size: 12px;
      }
    }
    &::after {
      content: '';
      position: absolute;
      height: 121px;
      width: 100%;
      background-color: #162334;
      border-top: 2px solid #bc1f39;
      bottom: -26px;
      transform: skewY(-12deg);
      @include s {
        height: 140px;
        border-top-width: 3px;
      }
      @include m {
        height: 140px;
      }
    }
    &:hover {
      &::after {
        background-color: #cc0034;
        border-top-color: #ecfd00;
        transition: all linear 0.2s;
      }
    }
    &-image {
      position: absolute;
      inset: 0;
      background-position: top center;
      background-size: 100%;
      background-repeat: no-repeat;
    }
    @include s {
      width: 222px;
      height: 350px;
    }
    @include m {
      width: 248px;
      height: 390px;
    }
  }
  &__content {
    z-index: 10;
    flex-direction: column;
    align-items: center;
    display: flex;
    position: absolute;
    top: 169px;
    @include s {
      top: 206px;
    }
    @include m {
      top: 246px;
    }
  }
  &__icon {
    width: 43px;
    height: 43px;
    margin-bottom: 18px;
    position: relative;
    @include s {
      width: 54px;
      height: 54px;
      margin-bottom: 26px;
    }
    @include m {
      width: 60px;
      height: 60px;
      margin-bottom: 32px;
    }
    &::before {
      content: '';
      border: 2px solid #fff;
      border-radius: 50%;
      background-color: #273242;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      //   z-index: -1;
    }
    &::after {
      content: '';
      position: absolute;
      width: 66px;
      height: 52px;
      bottom: -15px;
      left: -13px;
      background-image: var(--icon-round-leaves);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      filter: invert(78%) sepia(11%) saturate(398%) hue-rotate(179deg) brightness(90%) contrast(85%);
      @include s {
        width: 84px;
        height: 64px;
        bottom: -17px;
        left: -14px;
      }
      @include m {
        width: 93px;
        height: 55px;
        bottom: -12px;
        left: -16px;
      }
    }
    .entrance-category__item:hover & {
      &::before {
        background-color: #cc0034;
        transition: all linear 0.2s;
      }
      &::after {
        filter: invert(100%);
        transition: all linear 0.2s;
      }
    }
    &-image {
      width: 43px;
      height: 43px;
      position: relative;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 26px;
      .entrance-category__item:hover & {
        filter: contrast(1000%);
        transition: all linear 0.2s;
      }
      @include s {
        width: 54px;
        height: 54px;
        background-size: 34px;
      }
      @include m {
        width: 60px;
        height: 60px;
        background-size: 38px;
      }
    }
  }
  &__title {
    @include text(14px, 14px, 700, #ffffff);
    text-transform: uppercase;
    @include s {
      @include text(20px, 20px, 700, #ffffff);
    }
  }
}

$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.entrance-bnr-card {
  position: absolute;
  background: rgba(188, 31, 57, 0.2);
  top: 50%;
  inset-inline-end: -4px;
  width: 300px;
  height: 408px;
  padding: 7px;
  opacity: 1 !important;
  transform: translateY(-50%) !important;
  display: none;

  @media (min-width: 1280px) {
    display: block;
    width: 324px;
    height: 442px;
    inset-inline-end: 9px;
  }

  @include m {
    inset-inline-end: 60px;
  }

  &__img {
    width: 248px;
    height: 248px;
    z-index: 19;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 26px;
    left: 50%;
    transform: translateX(-50%);

    @include s {
      width: 265px;
      height: 268px;
    }
  }

  &__content {
    position: relative;
    z-index: 20;
    width: 100%;
    height: 100%;
    border: 8px solid #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 21px;
    text-align: center;

    @include s {
      padding: 18px 25px;
    }
  }

  &__btn {
    margin: 0 auto;
  }

  &__text {
    font-weight: bold;
    font-size: 22px;
    line-height: 1.2em;
    margin-bottom: 6px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    white-space: break-spaces;
  }

  &__sum {
    font-weight: bold;
    font-size: 36px;
    line-height: 1em;
    margin-bottom: 12px;
    text-transform: uppercase;
    color: #ECFD00;
    text-shadow: 0px -10px 10px rgba(0, 0, 0, 0.4);
  }

  &:hover {
    cursor: pointer;
  }

  body.rtl-lang & {
    direction: rtl;
  }
}

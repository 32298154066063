$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.tournaments {
    @include combine(--col, 3, 4, 5);
    @include combine(--gap, 60px 20px, 60px 24px, 60px 32px);

    display: grid;
    grid-template-columns: repeat(var(--col), minmax(280px, 324px));
    gap: var(--gap);
    margin: 0 auto 50px;
    min-height: 600px;

    &__title {
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        color: $wh;
        padding-top: 26px;
        margin-bottom: 18px;

        @include s {
            padding-top: 34px;
        }
        @include m {
            margin-bottom: 21px;
        }
    }

    body.rtl-lang & {
        direction: rtl;
    }
} 
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.challenge-timer {
    &__title {
        font-size: 14px;
        font-weight: 700;
        line-height: 17px;
        text-align: center;
        text-transform: uppercase;
        color: $wh;

        margin-bottom: 12px;
    }
}
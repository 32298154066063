$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.bankid-bnr {
    display: flex;
    flex-direction: column;

    &__title {
        font-size: 58px;
        font-weight: 700;
        @include max-height(--lh, 68px, 3);
        text-transform: uppercase;
        color: $wh;

        margin-bottom: 40px;
    }
    &__form {
       width: 360px;
    }
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.promo-item-big {
  position: relative;

  &__bnr {
    background-size: 100% 100%;
    background-position: center;
    cursor: pointer;

    &::before {
      content: '';
      display: block;
      padding-top: 36.6%;
    }
  }

  &__panel {
    position: relative;
    z-index: 10;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 10px 17px;
  }

  &__pwrap {
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: left bottom;
      background-repeat: no-repeat;
      background-size: auto 60%;
      background-color: $red;

      .is-big-celebrity &,
      .is-casino & {
        background-color: #1D2934;
      }

      .is-big-celebrity.is-casino & {
        background-color: $red;
      }
    }
  }

  &__offer {
    margin-right: 50px;
    font-weight: bold;
    font-size: 38px;
    line-height: 45px;
    text-transform: uppercase;
    color: #ffffff;
    cursor: pointer;

    span {
      &:first-child {
        font-size: 24px;
        color: #ECFD00;
        margin-bottom: 8px;
        display: block;
        line-height: normal;
      }

      &:empty {
        display: none;
      }
    }

    body.rtl-lang & {
      text-align: right;

      * {
        display: inline-block;
      }

      span {
        &:first-child {
          display: block;
        }
      }
    }
  }

  &__new {
    position: absolute;
    color: #ffffff;
    background-color: #BC1F39;
    top: -4px;
    right: 0px;
    width: 174px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 16px;
    font-style: italic;
    font-weight: 700;
    text-transform: uppercase;
    box-shadow: 8px 2px 0px 0px rgba(12,18,33,0.2);

    body.rtl-lang & {
      text-align: center;
    }

    .is-big-celebrity.is-casino & {
      right: auto;
      left: 0;
    }
  }

  &__btns {
    margin-left: auto;
    display: flex;
    align-items: center;

    .btn {
      min-width: 180px;
    }
  }

  &__link {
    display: inline-block;
    padding: 5px 30px;
    font-size: 20px;
    line-height: 23px;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: #ffffff;
    cursor: pointer;

    text-align: center;
  }

  @include s {
    display: flex;

    &__bnr {
      width: calc(100% - 445px);

      .is-big-celebrity &,
      .is-casino & {
        order: 2;
      }

      .is-big-celebrity.is-casino & {
        order: 0;
      }
    }

    &__panel {
      width: 445px;
      flex-shrink: 0;
      flex-direction: column;
      padding: 30px 36px;

      [lang="de"] & {
        padding: 30px;
      }

      .is-big-celebrity &,
      .is-casino & {
        order: 1;
      }
    }

    &__offer {
      margin-right: 0;
      width: 100%;
      font-size: 36px;

      [lang="de"] & {
        font-size: 35px;
      }
    }

    &__btns {
      width: 100%;
      margin-left: 0;
      margin-top: auto;
      flex-wrap: wrap;
    }

    &__link {
      display: block;
      margin: 15px 0;
      padding: 5px 20px;
      text-align: center;
    }
  }

  @include m {
    &__panel {
      width: 604px;
      padding: 50px 40px;

      [lang="de"] & {
        padding: 40px;
      }
    }

    &__offer {
      font-weight: bold;
      font-size: 50px;
      line-height: 61px;
      text-transform: uppercase;

      [lang="de"] & {
        font-size: 48px;
      }
    }
  }
}

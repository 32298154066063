$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.modal-wcancel {
  display: grid;
  gap: 8px;
  margin-top: 32px;
  color: #0b1222;
  font-size: 18px;
  line-height: 22px;

  &__item {
    background: #ffffff;
    min-height: 64px;
    box-sizing: border-box;
    padding: 10px 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
    align-items: center;
  }

  &__block {
    max-width: 150px;
    text-align: center;
  }

  &__img {
    width: 110px;
    height: 30px;
    object-fit: contain;
    display: block;
  }
}

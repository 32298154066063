$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.balance-filter{
  margin-top:45px;
  position:relative;
  &__header{
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    color: #A7AFBE;
    font-weight: bold;
  }
  &__count{ margin-inline-start: 12px; }
  &__desc{ margin-inline-start:auto; display: flex; }
  &__desc-i{
    margin-inline-start:53px;
    &::before{
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-inline-end:6px;
      border-radius: 50%;
      margin-bottom:-2px;
    }
    &.is-real::before{ background-color: $green; }
    &.is-bonus::before{ background-color: $red; }
  }

  &__filters{
    position:absolute;
    top: -25px;
    left: -13px;
    width: calc(100% + 26px);

    background: #2D394C;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 30 ;

    max-height: 0;
    opacity:0;
    transition:0.3s;
    overflow: hidden;
    &.shown{
      max-height: 3900px;
      opacity:1;
      overflow: visible;
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    padding: 16px 16px 0;
  }

  &__cancel{
    font-size: 16px;
    line-height: 24px;
    color: #A7AFBE;
    margin-inline-start:45px;
    cursor:pointer;
    text-decoration: underline !important;
  }

  &__section{
    padding:0 40px 0;
    &:last-child{ padding-bottom:20px;}
  }

  &__gt{
    font-size: 24px;
    line-height: 32px;
    color: #FFFFFF;
    margin:40px 0 18px;
  }
  &__btn{
    background:  #293241;
    height: 48px;
    display: block;
    padding:15px 24px;

    font-size: 16px;
    line-height: 19px;
    color: #A7AFBE;
    margin:10px;
    cursor:pointer;

    &.is-datepicker{
      position:relative;
      margin:10px 0;
      display: inline-block;
      border: none;
      min-width: 210px;
      text-align: center;
    }
    &:not(.linda-has-range) {
      & > span:nth-child(2) {
        display: none;
      }
    }
  }
  &__subtitle {
    display: none;
    font-size: 24px;
    line-height: 32px;
    color: #FFFFFF;

    &:not(.balance-filter__subtitle_visible){
      position: absolute;
      top: -58px;
      inset-inline-start: 10px;
      white-space: nowrap;
      margin-top:0;
    }

    &_visible,
    .linda-first-in-group & {
      display: block;
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    margin:0 -10px;

  }

  &__status {
    display: none;
    &.linda-is-active { display: block; }

    &.linda-first-in-group{
      position:relative;
      margin-top:100px;
    }
  }

  .linda-empty-element { width: 100%; }

}



[type='checkbox']:checked + .balance-filter__btn {
  color: $yellow;
  background: #1E2737;
}



$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.login-remembered {
    margin-top: 75px;
    display: flex;
    flex-wrap: wrap;
    @include text(18px, 26px, 400, $blueGray);

    p{
        width: 100%;
        margin-bottom: 4px;
        text-align: center;

        body.rtl-lang & {
          text-align: center;
        }
    }
    button{
        background: none;
        border: none;
        color: $yellow;
        cursor: pointer;
        &:hover{
            text-decoration: underline;
        }
    }
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.link {
  font-size: 20px;
  line-height: 20px;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &.icon-arr-l__before::before,
  &.icon-arr-r__after::after {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
  }
  &::before { margin-right:5px; }
  &::after { margin-left:5px; }

  &.is-yellow {
    color: $yellow;
    &::before,
    &::after { filter: $yellowFilter; }
  }

  &[disabled] {
    cursor: auto;
    color: #A5AFC0;
  }
}
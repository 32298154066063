$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.composite-popup {
  background: $blue2;
  padding: 24px 28px 64px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    @include text(38px, 44px, 400, $wh);
    margin-bottom: 8px;
  }
  &__description {
    @include text(16px, 24px, 400, #A5AFC0);
    margin-bottom: 30px;
  }
  &__section {
    height: 171px;
    background-color: $red;
    margin-bottom: 52px;
  }
  &__bg {
    position: absolute;
    min-height: 171px;
    background-color: $red;
    left: 0;
    width: 100%;
    z-index: 2;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 3;
    width: 444px;
    height: 100%;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"><circle r="2" fill="%23DA6436" /></svg>') repeat;
  }

  &__icon {
    height: 86px;
    width: 86px;
    background-image: var(--icon-i-points-with-shadow);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-bottom: 7px;
    position: relative;
    top: -9px;
  }

  &__text {
    @include text(40px, 48px, 400, $wh);
    text-transform: uppercase;
  }

  &__btn {
    min-width: auto;
    padding: 0 40px;
  }
}
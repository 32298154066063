$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.popup-page{

  width: 100% ;
  min-height: 100%;
  background: $blue;
  display: flex;
  position:relative;
  min-width: 1024px;
  overflow: hidden;

  .is-fullscreen & {
    width: 100vw;
  }


  &__close{
    position:absolute;
    z-index: 10;
    top: 20px;
    inset-inline-end: 20px;
    width: 32px;
    height: 32px;
    filter: $whFilter;
    cursor:pointer;
    opacity:0.8;
    &:hover{ opacity:1; }
  }


  &__header{
    &.have-border{
      border-bottom:solid 2px $red;
      padding-bottom:22px;
      margin-bottom:24px;
    }
  }

  &__section {
    width: 50%;
    &.is-bnr { 
        background: $red;
    }
  }

  &__content{
    position:relative;
    width: 352px;
    min-height: 100%;
    margin:0 auto;
    padding:44px 0 128px;

  }

  &__secure{
    position:absolute;
    width: 100%;
    bottom: 34px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    line-height: 20px;
    color: #A7AFBE;
    &::before{
      content: '';
      display: block;
      width: 75px;
      height: 30px;
      margin-right:9px;
    }
  }

  &__pnp-btn {
      position: absolute;
      bottom: 34px;
      width: 100%;
  }


  &__title{
    font-size: 42px;
    line-height: 120%;
    color:#fff;
    flex-grow: 1;
    overflow-wrap: break-word;
    overflow: hidden;
  }
  &__subtitle{
    font-size: 18px;
    line-height: 120%;
    color: #A7AFBE;
    margin-top:4px;
  }
  &__header-links{
    margin-top:7px;
    font-size: 16px;
    line-height: 120%;
    color: $blueGray;
    overflow: hidden;
    flex-grow: 1;
    overflow-wrap: break-word;

    & a{
      font-size: 19px;
      text-decoration: underline;
      margin-left:4px;
      color: $yellow;
      cursor:pointer;
    }
  }

  &__form{
    display: flex;
    flex-direction: column;
  }
  &__btns{
    padding-top:70px;
    display: flex;
    justify-content: space-between;
    align-items: center;

  }
  &__btns-i{ width: calc( 50% - 10px); }
  &__btns-recovery{
    width: calc( 50% - 10px);
    min-width: 190px;
  }

  @include xs{
    &__title {
      font-size: 38px;
    }
  }

  @include s{
    &__close{ top: 30px; inset-inline-end: 30px; }
    &__content{ width: 600px; padding:60px 0 128px; }
    &__header{
      overflow:hidden;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      &.have-border{
        padding-bottom:15px;
        margin-bottom:28px;
      }
    }
    &__title{
      font-size: 42px;
      line-height: 120%;
      padding-right:5px;
    }
    &__header-links{
      min-width: 50%;
      font-size: 18px;
      line-height: 120%;
      margin-bottom: 7px;
      padding-left:5px;
      text-align: right;

    }
    &__header-links a{ font-size: 22px; line-height: 120%; }

  }
  @include m{
    &__close{ top: 40px; inset-inline-end: 40px; }
    &__content{ width: 632px; padding:164px 0 128px; }
  }

}

body.rtl-lang .popup-page{
  &__secure{
   &::before{
     margin-left: 9px;
     margin-right: 0;
   }
  }
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.form-error{

  flex-direction: column;
  align-items: flex-start;
  text-align: start;

  &_check{ padding-left:42px;}
  
  &__amount {
    margin-left: 3px;
    body.rtl-lang & {
      display: inline-flex;
      direction: ltr;
      margin-left: initial;
      margin-right: 3px;
    }
  }

  & p{
    width: 100%;
    padding-top:8px;
    color: $pink;
    font-size: 12px;
    line-height: 15px;
  }
  
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.header-wrap {
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
}

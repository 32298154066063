$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.cup {
  width: 465px;
  min-height: 753px;
  position:absolute;
  bottom: 0;
  left: calc( 50% - 233px);
  z-index: 20;
  background-image: var(--icon-cup_l) , radial-gradient(46.2% 46.2% at 50% 50%, rgba(255,255,255,0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100% auto , 100% 70px;

  body.rtl-lang & {
    direction: rtl;
  }

  &__title {
    text-align: center !important;
    text-transform: uppercase;
    color: $yellow;
    overflow: hidden;
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 10px;
  }

  &__title-sum {
    body.rtl-lang & {
      display: inline-flex;
      gap: 10px;
    }
  }

  &__title-currency {
    body.rtl-lang & {
      order: 1;
    }
  }

  &__info {
    display: flex;
    text-align: center;
    justify-content: center;
    margin-bottom: 74px;
    position: relative;
    z-index: 40;
  }

  &__info-text {
    color: #fff;
    opacity: 0.5;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: normal;
  }

  &__wrap {
    width: 360px;
    height: 625px;
    position:relative;
    margin:0 auto;

    &::after {
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      width: 2px;
      position: absolute;
      transform: translateX(60px);
      background-color: #576477;
    }

    &:not(.linda-img-loaded) {
      background-image: url( $cdn + '/rabona/cup-page/cup_glass_frame.png');
      background-size: contain;
      background-position: center bottom;
    }
  }

  &__back{
    position:relative;
    left: 0;
    top: 0;
    width: 100% ;
    height: 100%;
    background-size: 11160px 625px;
    z-index: 22;

    .linda-img-loaded &{ background-image: url( $cdn + '/rabona/cup-page/cup_glass_cut.png'); }
  }

  &__front{
    position:absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 23;
    overflow: hidden;
  }

  &__front-img{
    position:absolute;
    bottom: 0;
    left: 0;
    width: 360px;
    height: 625px;
    background-size: 11160px 625px;
    z-index: 24;

    .linda-img-loaded & { background-image: url( $cdn + '/rabona/cup-page/cup_gold_cut.png' ); }
  }

  &__info-icon {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid #ECFD00;
    text-align: center;
    color: #ECFD00;
    font-size: 11px;
    padding-top: 1px;
    margin-inline-start: 5px;
  }
}
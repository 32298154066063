$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
// BP
@mixin s {
    @media screen and (min-width: #{$s}) {
        @content;
    }
}

@mixin s-max {
    @media screen and (max-width: #{$s - 1px}) { 
        @content;
    }
}

@mixin s-1499 {
    @media screen and (max-width: #{$s + 59px}) {
        @content;
    }
}

@mixin m {
    @media screen and (min-width: #{$m}) {
        @content;
    }
}

@mixin m-max {
    @media screen and (max-width: #{$m - 1px}) {
        @content;
    }
}
@mixin xs{
    @media screen and (min-width: #{$xs}) {
        @content;
    }
}

@mixin combine ($prop, $default, $s, $m: 'false') {
    #{$prop}: #{$default};
    @include s {
        #{$prop}: #{$s};
    }
    @if $m == 'false' {}
    @else {
        @include m {
            #{$prop}: #{$m};
        }
    }
}

@mixin max-height($custom-prop, $line-height, $lines) {
    @if $line-height != null {
        #{$custom-prop}: #{$line-height};
    }
    line-height: var(#{$custom-prop});
    max-height: calc(var(#{$custom-prop}) * #{$lines});
    overflow: hidden;
}

@mixin ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin scroll {
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar-button {
        width: 8px;
        height: 0px
    }

    &::-webkit-scrollbar-track {
        background: rgba(41, 50, 65, 0.1);
        border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background: #A7AFBE;
        border-radius: 8px;
    }

    &::-webkit-resizer {
        background-image: none;
        background-repeat: no-repeat;
        width: 8px;
        height: 8px
    }

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    &::-moz-scrollbarbutton {
        background-image: none;
        background-repeat: no-repeat;
        width: 4px;
        height: 0px
    }

    &::-moz-scrollbartrack {
        background: rgba(41, 50, 65, 0.1);
        border-radius: 8px;
    }

    &::-moz-scrollbarthumb {
        background: #A7AFBE;
        border-radius: 8px;
    }

    &::-moz-scrollbar {
        width: 8px;
        height: 8px;
    }
}

@mixin border ($width){
    background-image: linear-gradient($wh, $wh),
    linear-gradient($wh, $wh),
    linear-gradient($wh, $wh),
    linear-gradient($wh, $wh);
    background-size: 100% #{$width},
    #{$width} 100%,
    calc(100% - 100px) #{$width},
    #{$width} calc(100% - 70px);
    background-repeat: no-repeat;
    background-position: left top,
    right top,
    right bottom,
    left top;
}

@function parallelogram($width) {
    @return polygon(0 100%, $width 0, 100% 0, calc(100% - #{$width}) 100%);
}

@function rtl-parallelogram($width) {
    @return polygon(0 0, calc(100% - #{$width}) 0, 100% 100%, #{$width} 100%);
}

@mixin text( $size: null, $lineHeight: null, $weight: null, $color: null ) {
    @if $size != null {
        font-size: $size;
    }

    @if $lineHeight != null {
        line-height: $lineHeight;
    }

    @if $weight != null {
        font-weight: $weight;
    }

    @if $color != null {
        color: $color;
    }
}

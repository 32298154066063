$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.iconbox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    body.rtl-lang & {
        direction: rtl;
    }

    &__item,
    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 1px;
        width: 84px;
        height: 44px;
        background-color: #273242;
        border: 1px solid #2A394E;
        margin-left: 4px;
        margin-right: 4px;
        margin-bottom: 8px;

        img {
            width: 84px;
            height: 24px;
            object-fit: contain;
        }
    }
    &__link {
        color: $wh;
        text-decoration: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.25em;

        &:after {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            margin-left: 4px;
            background-image: url($cdn + '/rabona/icons/arrow-rounded.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 5px 8px;

            body.rtl-lang & {
                margin-left: initial;
                margin-right: 4px;
                rotate: 180deg;
            }
        }

        [lang="eu"] &,
        [lang="fr-BE"] & {
            width: 120px;
        }
    }

    &__link-text {
        text-align: center;
    }
}

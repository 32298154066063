$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.game-s2 {
    position: relative;
    width: 88px;
    height: 72px;
    flex-shrink: 0;
    border-radius: 2px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
    overflow: hidden;
    cursor: pointer;
    background-color: rgba(0,0,0,0.2);
    background-size: cover;
    background-position: center;



    &::after {
        content: '';

        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;



        background-image:
            var(--icon-i-play),
            linear-gradient(0deg, rgba(12, 18, 33, 0.6), rgba(12, 18, 33, 0.6));
        background-position: center;
        background-size: 27px 27px, 100%;
        background-repeat: no-repeat;

        clip-path: polygon(0 50%, 100% 50%, 100% 50%, 0 50%);
        transition-duration: var(--animation-duration);
        transition-property: clip-path;

    }

    &:hover::after {
        clip-path: polygon(0 0, 101% 0, 101% 101%, 0 101%);
    }
}
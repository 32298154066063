$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.card-guide-wrap{
  width: 1081px;
  max-width: calc(100% - 48px);
  margin:50px auto 116px ;


  &__bnr{
    padding-top:39%;
    //background-color: darkslategrey;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  &__title{
    margin-top:40px;
    font-size: 38px;
    line-height: 45px;
    text-align: center;
    color:#fff;
  }
  &__desc{
    font-size: 16px;
    line-height: 150%;
    color: #A5AFC0;
    margin-top:20px;
  }




  @include s{
    &__title{
      margin-top:50px;
      font-size: 42px;
      line-height: 49px;
      color:#fff;
    }

  }


}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.scroll {
    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        background-color: rgba(41, 50, 65, 0.1);
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $blueGray;
        border-radius: 4px;
    }

}



.scroll-custom{
    @include scroll;
}
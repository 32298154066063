$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.balance-table{

  margin-top:30px;

  &__thead{
    display: flex;
    align-items: center;
    padding: 0 13px;
    height: 80px;
    background: $red;
    color:#fff;
  }
  &__th{
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    text-transform: uppercase;
    color: #FFFFFF;

    width: calc( 20% - 6px);
    margin:0 3px;
    text-align: center;

  }

  &__item{
    background-color: #fff;
  }
  &__tr{
    position:relative;
    height: 56px;
    border-bottom: solid 1px  #E0E0E0;
    display: flex;
    align-items: center;


  }
  &__arr{
    position:absolute;
    right: 20px;
    top: 15px;
    width: 30px;
    height: 30px;
    opacity:0.5;
  }
  &__td{
    padding:0 13px;
    width: calc( 20% - 6px);
    margin:0 3px;
    font-size: 16px;
    line-height: 150%;
    color: #2D394C;
    text-align: center;

    &.is-amount{
      font-weight: bold;
      &.is-bonus{ color: $red;}
      &.is-real{ color: $green;}

      body.rtl-lang & {
        direction: ltr;
      }
    }
  }

  &__desc{
    display: none;
  }
  &__desc-key{
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    color: #192333;
    margin-right:20px;

  }

  &  [type=checkbox]:checked + label + .balance-table__desc{
    background-color: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    border-bottom: solid 1px #e0e0e0;
  }

  &  [type=checkbox]:checked + label{
    background-color: #eee;
    border-bottom:solid 1px #eee;
    &     .balance-table__arr{ transform: rotate(180deg)}

  }
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.countdown {
    display: inline-flex;

    &__item {
        position: relative;

        display: flex;
        flex-direction: column;

        &:not(:last-child) {
            margin-inline-end: 20px;

            &::after,
            &::before {
                content: '';

                position: absolute;
                inset-inline-end: -12px;
                top: 28px;

                width: 4px;
                height: 4px;

                background-color: $wh;
                transform: skewY(-17deg);
            }

            &::before {
                top: 36px;
            }
        }
    }

    &__time {
        position: relative;
        width: 55px;

        font-size: 32px;
        font-weight: 700;
        line-height: 66px;
        text-align: center;
        color: $wh;

        background-color: $red;
        clip-path: polygon(0 13px, 100% 0, 100% calc(100% - 13px), 0 100%);

        &::before {
            content: '';

            position: absolute;
            z-index: 1;
            left: 0;
            top: 50%;

            width: 100%;
            height: 1px;

            background-color: $blue;
        }

        &_accent {
            color: $yellow;
        }
    }

    &__label {
        font-size: 10px;
        line-height: 10px;
        text-transform: uppercase;
        text-align: center;
        color: $wh;

        margin-top: 12px;
        width: 55px;
    }

    body.rtl-lang & {
        direction: ltr;
    }
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.tournament-wgt {
    display: flex;
    margin-bottom:26px;

    &__bnr {
        width: 280px;
        flex-shrink: 0;
    }
    &__divider {
        width: 8px;
        flex-shrink: 0;
        margin-top: 20px;
        margin-bottom: 16px;
        background-image: linear-gradient(
            to bottom,
            $red,
            $red 2px,
            transparent 2px,
            transparent calc(100% - 2px),
            $red calc(100% - 2px)
        );
    }

    &__games {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        max-height: calc(660px - 36px);
        margin-top: 20px;
        margin-bottom: 16px;
        padding: 30px 20px;
        overflow: hidden;

        border-top: 2px solid $red;
        border-bottom: 2px solid $red;
        background-color: #0C1221;
        background-position: center;
        background-size: cover;

        &::after {
            content: '';
            position: absolute;
            z-index: 1;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 242px;
            background: linear-gradient(360deg, #242D3D 0%, rgba(36, 45, 61, 0) 100%)
        }
    }

    &__title {
        font-size: 28px;
        font-weight: 700;
        line-height: 33px;
        text-align: center;
        text-transform: uppercase;
        color: $wh;
    }

    &__grid {
        //@include combine(--col, 2, 3, 4);
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        margin-top: 18px;
        @include s{
            grid-template-columns: repeat(3, 1fr);
        }
        @include m {
            gap: 32px;
            grid-template-columns: repeat(4, 1fr);
        }
    }

    &__btn {
        position: absolute;
        z-index: 2;
        max-width: calc(100% - 40px);
        min-width: 336px;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
    }
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.vip-item{


  --par: 60px;
  @include s{ --par: 90px; }
  @include m{ --par: 106px;}
  @include combine(height, 382px, 490px, 582px);
  position:relative;

  &::before{
    content: '';
    display: block;
    position:absolute;
    top: 0;
    left: 50% ;
    transform: translateX(-50%);
    @include combine(width, 70px, 84px, 96px);
    @include combine(height, 109px, 132px, 151px);
    z-index: 15 ;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &:nth-child(1)::before{ background-image: url( $cdn + '/rabona/vip/numbers/1.svg');}
  &:nth-child(2)::before{ background-image: url( $cdn + '/rabona/vip/numbers/2.svg');}
  &:nth-child(3)::before{ background-image: url( $cdn + '/rabona/vip/numbers/3.svg');}
  &:nth-child(4)::before{ background-image: url( $cdn + '/rabona/vip/numbers/4.svg');}
  &:nth-child(5)::before{ background-image: url( $cdn + '/rabona/vip/numbers/5.svg');}

  &:nth-child(1).active::before{ background-image: url( $cdn + '/rabona/vip/numbers/1-active.svg');}
  &:nth-child(2).active::before{ background-image: url( $cdn + '/rabona/vip/numbers/2-active.svg');}
  &:nth-child(3).active::before{ background-image: url( $cdn + '/rabona/vip/numbers/3-active.svg');}
  &:nth-child(4).active::before{ background-image: url( $cdn + '/rabona/vip/numbers/4-active.svg');}
  &:nth-child(5).active::before{ background-image: url( $cdn + '/rabona/vip/numbers/5-active.svg');}



  &__par{
    position:absolute;
    bottom: 0;
    width: 100%;
    background: #19273A;
    clip-path: polygon(0 var(--par), 100% 0, 100% calc(100% - var(--par) ), 0 100%);
    @include combine(height, 351px, 462px, 545px);

    display: flex;
    align-items: flex-end;
    z-index: 10;
    .vip-item.active & { background: $red;}

    &::before{
      content: '';
      display: block;
      width: 100% ;
      mask: url('./img/vip-pattern3.svg');
      mask-size: auto 100%;
      mask-position: left top;
      mask-repeat: repeat-x;
      background-image: linear-gradient(to top, #A5AFC0 , rgba(165, 175, 192, 0) );
      @include combine(height, 120px, 140px, 160px);
      .vip-item.active & { background-image: linear-gradient(to top, #ECFD00 0% , rgba(236, 253, 0, 0) 100% ); }
    }
  }


  &__line{
    position:absolute;
    left: -1px;
    bottom: -2px;
    width: calc(100% + 2px);
    z-index: 9 ;
    clip-path: polygon(0 var(--par), 100% 0, 100% calc(100% - var(--par) ), 0 100%);
    background: linear-gradient(to top, #8E99AB 0% , rgba(141, 152, 170, 0) 100% );
    @include combine(height, 351px, 462px, 545px);
    .vip-item.active &{ background: linear-gradient(to top, #ECFD00 0% , rgba(236, 253, 0, 0) 100% ); }
  }

  &__content{
    position:absolute;
    top: 143px;
    left: 0;
    padding:0 10px;
    width: 100%;
    z-index: 20;
    text-align: center;
    color:#fff;

    * {
      body.rtl-lang & {
        text-align: center;
      }
    }
  }
  &__title{
    font-weight: bold;
    text-transform: uppercase;
    @include combine( margin-bottom, 20px, 36px, 30px);
    @include combine( font-size, 20px, 28px, 34px);
    @include combine( line-height, 120%, 120%, 120%);
    .vip-item.active & { color: $yellow; }
  }
  &__val{
    font-weight: bold;
    @include combine( font-size, 16px, 20px, 24px);
    @include combine( line-height, 100%, 100%, 100%);
    &:not(.manager-off){
      .vip-item.active &{ color: $yellow;}
    }

  }
  &__key{
    @include combine( margin, 5px 0 12px, 5px 0 14px, 5px 0 25px);
    @include combine( font-size, 12px, 12px, 14px);
    line-height: 100%;
    color: #A5AFC0;
    .vip-item.active & { color: #fff;}
  }


}

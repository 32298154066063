$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.seasons-link {
  margin-right: 1px;
  flex-shrink: 0;
  flex-grow: 1;
  order: -4;

  &>.header__link {
    width: 100%;
  }

  &:empty {
    display: none;
  }

  .header__link-text {
    [lang="hi-IN"] & {
      padding: 0;
    }
  }
}

.seasons-christmas-link {
  position: relative;
  padding: 0 10px;

  @include s {
    padding: 0 14px;
  }

  .header__link-text {
    &::before {
      content: '';
      width: 14px;
      height: 14px;
      background: url(./img/gift.svg) center / 100% no-repeat;
      display: inline-block;
      margin-right: 4px;
      margin-bottom: -2px;

      @include s {
        width: 18px;
        height: 18px;
      }
    }
  }

  &::before {
    content: '';
    width: 110%;
    height: 44px;
    background-color: #ffffff;
    position: absolute;
    transform: skew(27deg);
    -webkit-mask: url(./img/snowflake.svg) center / cover no-repeat;
    mask: url(./img/snowflake.svg) center / cover no-repeat;

    @include s {
      height: 56px;
    }
  }

  &::after {
    content: '';
    width: 118%;
    height: 44px;
    background-color: #ffffff;
    position: absolute;
    transform: skew(27deg);
    -webkit-mask: url(./img/snow.svg) center / cover no-repeat;
    mask: url(./img/snow.svg) center / cover no-repeat;
    opacity: 0.5;

    @include s {
      height: 56px;
    }
  }

  &.active,
  &:hover {

    &::before,
    &::after {
      background-color: #ecfd00;
    }
  }
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.cash-crab-profile-rightbar {
  height: auto !important;
  flex-wrap: wrap;
  row-gap: 10px;
  & .cash-crab-section {
    body.rtl-lang & {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}

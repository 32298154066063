$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.maintenance {
  padding: 40px;
  min-height: 580px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 28px;
  position: relative;
  background: radial-gradient(50% 50% at 50% 50%, rgba(11, 17, 32, 0.4) 0%, #0b1120 100%),
    url($cdn + '/rabona/main-banner/banner_video_bg-poster.jpg');
  background-position: center;

  &__title {
    color: #ffffff;
    font-family: 'Roboto Condensed';
    font-size: 56px;
    font-weight: 700;
    line-height: 65.63px;
    text-align: center;
    text-transform: uppercase;
  }
  &__text {
    color: #ffffff;
    font-family: 'Roboto Condensed';
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    max-width: 776px;
  }
  &__btn {
    width: fit-content;
  }
  &__chat {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: $yellow;
    font-size: 14px;
    &::before {
      margin-right: 8px;
      content: '';
      display: block;
      width: 18px;
      height: 20px;
      background-image: var(--icon-i-support-hover2);
      background-position: -6px center;
      background-repeat: no-repeat;
      filter: brightness(0) $yellowFilter;
    }
  }
}

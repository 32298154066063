$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.lang {
    $this: &;
    position: relative;
    display: inline-flex;
    height: 40px;

    border-radius: 2px;
    background-color: $blueStroke;
    border: 1px solid transparent;
    cursor: pointer;

    &__img {
        width: 40px;
        height: 28px;
        object-fit: cover;
        object-position: center;
        margin-right: 9px;
    }
    &__item {
        display: flex;
        flex-basis: 100%;
        align-items: center;
        padding: 6px 15px 6px 10px;

        font-size: 16px;
        line-height: 16px;
        text-decoration: none;
        color: $blueGray;


        & + #{$this}__item {
            margin-top: 4px;
        }

        &_hidden {
            display: none;
        }

        &:hover {
            color: $wh;
            background: $blueStroke;
        }
    }
    &:hover {
        background-color: $blueLight;
        border-color: $yellow;
    }
}

body.rtl-lang .lang {
    &__img{
        margin-left: 9px;
        margin-right: 0;
    }
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.title {
    font-size: 20px;
    line-height: 23px;
    text-transform: uppercase;
    color: $wh;

    display: inline-block;
    padding: 5px 0;
    background-image: linear-gradient(to top, $red, $red);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% 2px;
    transition: var(--animation-duration) background-size;
    
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.paymentcb{



  text-align: center;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__logo{
    display: block;
    width: 162px;
    height: 116px;

  }

  &__title{
    margin-top:60px;
    font-size: 28px;
    line-height: 120%;
    text-transform: uppercase;
  }
  &__subtitle{
    margin-top:16px;
  }
  &__close{
    margin-top:32px;
    font-size: 18px;
    line-height: 150%;
  }
  &__timer{
    margin:20px 0 40px;
  }
  &__text{
    font-size: 18px;
    line-height: 150%;
  }
  &__btn{
    margin-top:20px;
    width: 300px;
  }
  &__link{
    margin-top:36px;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #A7AFBE;
    cursor:pointer;

    &::after{
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      filter: $grayFilter;
    }
  }


  &__table{
    width: 600px;
    margin-top:24px;
  }
  &__tr{
    display: flex;
    align-items: center;
    border-bottom:solid 1px #293241;
    height: 50px;
  }
  &__td{

    font-size: 16px;
    line-height: 120%;
    color: #A7AFBE;
    text-align: left;
    &:first-child{
      color:#fff;
      width: 235px;
      font-size: 14px;
      text-transform: uppercase;

    }
  }

  &__info {
    width: 100%;
    max-width: 404px;
    margin-top: 18px;
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5em;
    text-align: center;
    color: #A5AFC0;
  }

}


.is-fail .if-success,
.is-success .if-fail { display: none; }
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.slider {
    position: relative;

    display: flex;
    justify-content: space-between;
    height: 348px;
    padding: 44px 0 32px 28px;

    @include s {
        padding-left: 35px;
    }

    @include m {
        height: 452px;
        padding-top: 71px;
        padding-left: 37px;
    }

    body.rtl-lang & {
        direction: rtl;
    }

    &__bg {
        position: absolute;
        left: 0;
        bottom: 0;
        top: 16px;
        right: 0;
        border-radius: 2px;
        background-position: center bottom;
        background-repeat: no-repeat;
    }

    &__controls {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 10;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);

        body.rtl-lang & {
            direction: rtl;
        }
    }

    &__info {
        position: relative;
        z-index: 1;

        display: inline-flex;
        flex-direction: column;
        height: 100%;

        opacity: 0;
        transform: translateX(-100%);
        transition-duration: calc(var(--animation-duration) * 2);
        transition-property: transform, opacity;

        .slick-active & {
            transform: translateX(0);
            opacity: 1;
        }
    }

    &__img {
        position: absolute;
        inset-inline-end: 0;
        bottom: 3px;
        height: calc(100% - 3px);
        object-fit: contain;
        object-position: right bottom;

        opacity: 0;
        transform: translateY(100%);
        transition-duration: calc(var(--animation-duration) * 2);
        transition-property: transform,
            opacity;

        .slick-active & {
            transform: translateY(0);
            opacity: 1;
        }

        @include m {
            inset-inline-start: 50%;
            transform: translate(-50%, 100%);
            height: calc(100% - 4px);
            bottom: 4px;

            .slick-active & {
                transform: translate(-50%, 0);
            }
        }
    }

    &__title {
        --lh: 44px;

        font-size: 44px;
        font-weight: 700;
        line-height: var(--lh);
        text-transform: uppercase;
        color: $wh;

        width: 507px;
        max-height: calc(var(--lh) * 2);
        overflow: hidden;

        white-space: break-spaces;

        @include m {
            --lh: 63px;
            font-size: 54px;
        }
    }

    &__subtitle {
        font-size: 60px;
        font-weight: 700;
        line-height: 58px;
        text-transform: uppercase;
        color: $yellow;
        margin-top: 10px;

        @include m {
            font-size: 74px;
            line-height: 87px;

            margin-top: 7px;
        }
    }

    &__btn {
        width: 293px;
        margin-top: auto;
        cursor: pointer;
    }

    &__dots {
        display: flex;

        li button {
            display: block;
            font-size: 0;

            width: 17px;
            height: 12px;
            margin-right: 7px;
            border: 0;
            padding: 0;

            cursor: pointer;

            &:last-child {
                margin-right: 0;
            }

            clip-path: parallelogram(5px);

            background-color: rgba(233, 233, 233, 0.8);
            background-image: linear-gradient(0deg, $yellow, $yellow);
            background-position: center;
            background-size: 100% 0;
            background-repeat: no-repeat;
            transition: var(--animation-duration) background-size;
        }

        li.slick-active button {
            background-size: 100% 100%;
        }
    }

    &__arr {
        position: absolute;
        z-index: 20;
        display: block;
        width: 116px;
        height: 48px;
        top: 50%;

        border: none;
        background-color: #fff;
        background-size: 26px;
        background-repeat: no-repeat;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
            background-color: $yellow;
        }

        .game-hall__slider:not(:hover) & {
            opacity: 0;
            transform: scale(0.96)
        }

        &.is-prev {
            inset-inline-start: 8px;
            clip-path: polygon(0 0, calc(100% - 30px) 0, 100% 100%, 0 100%);
            background-image: var(--icon-arr-l);
            background-position: 17px center;
        }

        &.is-next {
            inset-inline-end: 8px;
            clip-path: polygon(30px 0, 100% 0, 100% 100%, 0 100%);
            background-image: var(--icon-arr-r);
            background-position: calc(100% - 17px) center;
        }

        body.rtl-lang & {
            scale: -1 1;
        }
    }

    &__bottom-arr {
        padding: 0;
        background-color: transparent;
        border: 0;

        position: absolute;
        top: -3px;
        width: 17px;
        height: 17px;
        filter: invert(77%) sepia(3%) saturate(1570%) hue-rotate(179deg) brightness(90%) contrast(91%);

        &.is-prev {
            inset-inline-start: -35px;
            background-image: var(--icon-arr-l);
        }

        &.is-next {
            inset-inline-end: -35px;
            background-image: var(--icon-arr-r);
        }

        &:hover {
            filter: invert(80%) sepia(99%) saturate(3683%) hue-rotate(9deg) brightness(107%) contrast(103%);
        }

        body.rtl-lang & {
            scale: -1 1;
        }
    }

    &__main-arr{

    }
}

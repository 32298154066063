$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.big-celebrity-page{
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding-top: 44px;
    padding-left: 44px;
    padding-bottom: 30px;
    width: 100%;
    position: relative;
    z-index: 1;
    min-height: 696px;
    @include s{
        min-height: 830px;
    }
    &__shadow{
        position: absolute;
        width: 100%;
        height: 213px;
        bottom: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(12, 18, 33, 0) 0%, #0C1221 100%);
    }
    &-wrap{
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom center;
        align-items: center;
        margin-top: -10px;
        overflow: hidden;
        &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, rgba(11, 17, 32, 0.6) 0.98%, rgba(11, 17, 32, 0.48) 42.41%, rgba(11, 17, 32, 0) 52.8%);
        }
        &.is-knockout-bonus::before {
            background: radial-gradient(67.89% 99.36% at 39.4% 6.32%, #0B1120 0%, rgba(11, 17, 32, 0.43) 35.5%, rgba(11, 17, 32, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
            linear-gradient(90deg, rgba(11, 17, 32, 0.6) 0.98%, rgba(11, 17, 32, 0.48) 83.93%, rgba(11, 17, 32, 0.21) 100%);
        }
        &::after{
            @include m{
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(90deg, #0c1221 0%, #0c122100 10% 90%, #0c1221 100%);            
            }
        }
    }
    &__content{
        display: flex;
        flex-direction: column;
        gap: 40px;
        width: 480px;
    }
    &__title{
        color: #FFF;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
    }
    &__subtitle{
        color:  #ECFD00;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
    }
    &__offer{
        color: #A5AFC0;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }
    &__quote{
        color: #FFF;
        font-size: 16px;
        font-style: italic;
        font-weight: 300;
        line-height: normal;
        width: fit-content;
        span{
            display: block;
            text-align: right;
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 8px;
        }
    }

    &__description{
        color: #A5AFC0;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }
    &__details{
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
        text-transform: uppercase;
        display: block;
        text-align: left;
        width: fit-content;
        margin-top: 16px;
    }
    &__ambassador{
        position: relative;
        display: flex;
        flex-direction: column;
        color: #FFF;
        font-size: 16px;
        font-weight: 700;
        line-height: 150%;
        text-transform: uppercase;
        padding-left: 8px;

        &:before {
            content: '';
            position: absolute;
            top: 4px;
            left: 0;
            height: 48px;
            width: 1px;
            background-color: #fff;
        }
    }

    &__hero{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        &-img{
            position: absolute;
            bottom: 0px;
            right: -20px;
            z-index: -1;
            width: 796px;
            height: 652px;
            mask-image: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 85%, transparent 100%);
            img {
                width: 100%;
                mask-image: linear-gradient(270deg, rgba(0, 0, 0, 1) 65%, transparent 100%);
                @include s {
                    mask-image: none;
                }
            }
        }
    }
    &__sign{
        margin-top: auto;
        display: flex;
        gap: 9px;
        margin-right: 55px;
        & > img{
            height: 68px;
        }
    }

    &-promo{
        background: #192333;
        box-shadow: 0px 2px 8px 0px #0B1222;
        padding: 16px 24px;
        background-position: -8px 2px;
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9InllcyI/Pjxzdmcgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxjaXJjbGUgY3g9IjEwIiBjeT0iMTAiIHI9IjEiIGZpbGw9IiNFRkZCMzciIGZpbGwtb3BhY2l0eT0iLjM1IiBzdHlsZT0iIi8+PC9zdmc+');
        border: 1px solid #273242;
        border-left: 2px solid #CC0034;
        &__offer{
            color:  #FFF;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
            margin-top: 4px;
            margin-bottom: 16px;
        }
        &__details{
            color: #FFF;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-decoration-line: underline;
            text-transform: uppercase;
            display: block;
            text-align: left;
            width: fit-content;
            margin-top: 16px;
        }
        &__actions{
            display: flex;
            gap: 32px;
        }
    }

    &-pagination{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        height: 38px;
        margin-top: -38px;
        padding-bottom: 10px;

        .slider__dots{
            gap: 8px;
        }

        &__arrow{
            position: relative;
            background-color: transparent;
            border: 0;
            background-position: center;
            background-repeat: no-repeat;
            filter: invert(77%) sepia(3%) saturate(1570%) hue-rotate(179deg) brightness(90%) contrast(91%);
            border: none;
            height: 17px;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: transparent;
            width: 17px;
            appearance: none;

            &.is-prev{
                background-image: var(--icon-arr-l);
                &:hover {
                    filter: invert(80%) sepia(99%) saturate(3683%) hue-rotate(9deg) brightness(107%) contrast(103%);
                }
                order: 0;
            }
            &.is-next{
                background-image: var(--icon-arr-r);
                &:hover {
                  filter: invert(80%) sepia(99%) saturate(3683%) hue-rotate(9deg) brightness(107%) contrast(103%);
                }
                order: 2;
            }
        }
    }

    @include s {
        // max-width: 1260px;
        &__title{
            font-size: 58px;
        }
        &__subtitle{
            font-size: 36px;
        }
        &__offer{
            font-size: 20px;
        }
        &__quote{
            font-size: 20px;
        }
        &-promo{
            max-width: 560px;
            &__offer{
                font-size: 32px;
            }
        }
        &__description{
            font-size: 20px;
        }
        &__ambassador{
            font-size: 20px;

            &:before {
                height: 60px;
            }
        }
        &__content{
            min-width: 630px;
        }
        &__hero{
            &-img{
                height: 770px;
                width: 940px;
                bottom: 0px;
                right: 0px;
                img{
                    mask-image: none;
                }
            }
        }
        &__sign{
            margin-top: -50px;
            margin-right: 180px;
            & > img{
                height: 71px;
            }
        }
    }

    @include m {
        max-width: none;
        padding-left: 60px;
        padding-bottom: 40px;

        .big-celebrity-page__hero {
            flex-grow: 1;
            padding-left: 80px;
        }

        .big-celebrity-page__hero-img {
            right: 120px;
        }
    }

    .is-knockout-bonus &{
        .big-celebrity-page__hero-img {
            width: 531px;
            height: 620px;
            img{
                mask-image: none;
            }
            @include s{
                width: 713px;
                height: 810px;
            }
        }
    }
}

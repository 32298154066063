$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.vip-bnr{
  height: 360px;
  padding-top: 84px;
  background-image: url( $cdn + '/rabona/vip/vip_lvl_bg/2500.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: repeat-x;

  color: #FFFFFF;
  font-size: 24px;
  line-height: 40px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;

  body.rtl-lang & {
    text-align: center;
  }

  @include s{
    height: 365px;
    font-size: 32px;
    line-height: 40px;
  }
  @include m{
    height: 480px;
    padding-top: 125px;
    font-size: 40px;
    line-height: 40px;
  }

}

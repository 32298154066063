$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;

.card-teams {
  min-height: 600px;
  margin-top:-17px;
  padding:0 40px;
  color: #FFFFFF;
  margin-bottom:80px;

  background-image: url( $cdn + "/rabona/cards-page/background-collections.jpg");
  background-size: 1920px auto;
  background-repeat: no-repeat;
  background-position: center top;

  &__verification {
    padding-top: 34px ;
    margin: 0 auto;
    max-width: 944px;
  }

  &__title{
    body.rtl-lang & {
      text-align: center;
    }

    font-weight: bold;
    font-size: 28px;
    line-height: 120%;
    padding:45px 0 60px;
    text-align: center;
    text-transform: uppercase;
  }

  &__guide {
    position:absolute;
    inset-inline-end: 0;
    top: -85px;
    z-index: 50;
    display: flex;
    align-items: center;
    color: $yellow;

    &::before{
      content: '';
      display: block;
      width: 18px;
      height: 18px;
      margin-inline-end: 10px;
      filter: brightness(0) $yellowFilter;

      background-image: var( --icon-i-info);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &__content{
    display: grid;
    grid-template-columns: repeat( 3, 1fr);
    grid-gap: 52px;
    width: 944px;
    margin:0 auto;
    position:relative;

    body.rtl-lang & {
      direction: rtl;
    }
  }

  &__item{
    height: 565px;
    position:relative;
  }

  @include s{
    padding: 0 57px;
    &__content{
      grid-gap: 55px;
      grid-template-columns: repeat( 4, 1fr);
      width: 1317px;
    }
    &__verification { max-width: 1317px; }
    &__item{ height: 588px; }
  }

  @include m{
    background-size: 2500px auto;
    &__content{ width: 1717px; }
    &__item{ height: 792px; }
    &__title{ padding:50px 0 90px;}
    &__verification { max-width: 1717px; }
  }
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.point-count {
  position: relative;
  font-weight: bold;
  font-size: 32px;
  line-height: 37px;
  color: #FFFFFF;
  padding-block: 2px;
  padding-inline: 40px 24px;
  transform: translateX(5px);
  z-index: 1010;

  &::after {
    content: '';
    position:absolute;
    z-index: 1009;
    display: block;
    width: 100% ;
    height: 100%;
    background: $red;
    top: 0;
    left: 0;
    transform: skewX(-20deg);
  }

  &::before {
    content: '';
    position:absolute;
    top: -6px;
    inset-inline-start: -20px;
    display: block;
    width: 51px;
    height: 51px;
    z-index: 1014;
  }

  & span{
    position:relative;
    z-index: 1012;
  }
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.article-popup{
  font-size: 18px;
  line-height: 150%;
  color: #FFFFFF;

  & p{
    margin:12px 0;
    &:first-child{ margin-top:30px;}
  }
  & a{
    color:#fff;
    text-decoration: underline;
    font-weight: bold;
    margin:0 3px;
    &:hover{ color:$yellow; }
  }

  & table{
    min-width: 620px;
  }
  & tr{
    border-bottom:solid 1px #2D394C;

  }
  & td{
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    padding:3px 10px 3px 0px;
    &:nth-child(2){
      text-align: right;
      padding:3px 0px 3px 10px;
    }

  }
  & tr:nth-child(1){
    & td{
      font-size: 18px;
      line-height: 28px;
      padding:9px 10px 9px 0px;
      &:nth-child(2){
        padding:9px 0px 9px 10px;
      }

    }
  }
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.is-big-celebrity{
    &.entrance-bnr{
        &::after{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 213px;
            content: '';
            background: linear-gradient(0deg, rgba(12,18,33,1) 0%, rgba(12,18,33,0) 100%);
        }
        &::before{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: '';
            background: linear-gradient(90deg, #0c1221 0%, #0c122100 10%, #0c122100 90%, #0c1221 100%);        
        }
        
    }
    .entrance-bnr__background{
        @include m {
            &::after{
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 213px;
                content: '';
                background: linear-gradient(0deg, rgba(12,18,33,1) 0%, rgba(12,18,33,0) 100%);
            }
        }
    }
    .entrance-bnr__img{
        width: 567px;
        height: 465px;
        top: auto;
        bottom: 0;
        transform: unset !important;
        left: calc(50% - 107px);
        @include s {
            width: 733px;
            height: 600px;
            bottom: -57px;
            left: calc(50% - 136px);
        }
        @include m {
            left: calc(50% - 366px);
        }
        & > div{
            display: flex;
            z-index: 1;
            position: absolute;
            left: 33px;
            bottom: 54px;
            @include s {
                bottom: 129px;
                left: 0;
            }
            @include m {
                left: 140px;
            }
        }
    }
    
}
.is-big-celebrity-knockout{
    &.entrance-bnr{
        &::after{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 213px;
            content: '';
            background: linear-gradient(0deg, rgba(12,18,33,1) 0%, rgba(12,18,33,0) 100%);
        }
        &::before{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: '';
            background: linear-gradient(90deg, #0c1221 0%, #0c122100 10%, #0c122100 90%, #0c1221 100%);        
        }
        
    }
    .entrance-bnr__overlay{
        background: radial-gradient(67.89% 99.36% at 39.4% 6.32%, #0B1120 0%, rgba(11, 17, 32, 0.43) 35.5%, rgba(11, 17, 32, 0) 100%) linear-gradient(90deg, rgba(11, 17, 32, 0.6) 0.98%, rgba(11, 17, 32, 0.48) 83.93%, rgba(11, 17, 32, 0.21) 100%);
    }
    .entrance-bnr__background{
        @include m {
            &::after{
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 213px;
                content: '';
                background: linear-gradient(0deg, rgba(12,18,33,1) 0%, rgba(12,18,33,0) 100%);
            }
        }
    }
    .entrance-bnr__img{
        width: 567px;
        height: 480px;
        top: auto;
        bottom: 0;
        transform: unset !important;
        left: calc(50% - 107px);
        background-position: top right 35px;
        background-size: 420px;
        @include s {
            background-size: 504px;
            width: 733px;
            height: 635px;
            bottom: -57px;
            left: calc(50% - 136px);
        }
        @include m {
            left: calc(50% - 366px);
        }
        & > div{
            display: flex;
            z-index: 1;
            position: absolute;
            left: 33px;
            bottom: 54px;
            @include s {
                bottom: 129px;
                left: 0;
            }
            @include m {
                left: 140px;
            }
        }
    }
}
.is-big-celebrity-knockout,.is-big-celebrity{
    .entrance-bnr__sign{
        width: 167px;
        height: 68px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        @include s {
            width: 175px;
            height: 71px;
        }
    }
    .entrance-bnr__autograph{
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;
        padding-left: 8px;
        border-left: 1px solid #fff;
        color: #fff;
        display: flex;
        flex-direction: column;
        line-height: 150%;
        margin-left: 9px;
        height: fit-content;
        @include s {
            font-size: 20px;
        }
    }
}

.entrance-bnr-wrapper .slick-slider:has(.slick-slide.slick-active .is-big-celebrity-knockout) ~ batman-banner9,
.entrance-bnr-wrapper .slick-slider:has(.slick-slide.slick-active .is-big-celebrity) ~ batman-banner9 {
    .entrance-bnr-card {
        display: none;

        @include m {
            display: block;
        }
    }
}

$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.game-hall {
  padding: 0 24px 50px;
  margin: 0 auto;
  max-width: 1920px;

  &__slider {
    position: relative;
    margin-top: 20px;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
    height: 348px;
    border-radius: 3px;

    .slider__info {
      max-width: 50%;
      row-gap: 12px;
      justify-content: center;
    }

    .slider__title {
      text-shadow: 0 7px 10px rgba(0, 0, 0, 0.5);
      line-height: 1.2;
      overflow: visible;
      font-size: 24px;

      @media screen and (min-width: 1280px) {
        font-size: 28px;
      }

      @include m {
        font-size: 40px;
      }
    }

    .slider__subtitle {
      text-shadow: 0 7px 10px rgba(0, 0, 0, 0.5);
      line-height: 1;
      font-size: 40px;

      @include m {
        font-size: 64px;
      }
    }

    .slider__img {
      height: calc(100% - 52px);

      @media screen and (min-width: 1280px) {
        height: calc(100% - 3px);
      }

      @include m {
        transform: translateY(100%);
      }

      body.rtl-lang & {

      }
    }

    .slick-active {
      .slider__img {
        @include m {
          transform: translateY(0);
        }
      }
    }

    .slider__btn {
      margin-top: 16px;
      box-shadow: 0 7px 10px rgba(0, 0, 0, 0.5);
    }
  }

  .slider {
    padding: 40px 30px 20px;

    @include s {
      padding-left: 40px;
    }

    @include m {
      padding-left: 52px;
    }

    &__bg {
      &.is-big-celebrity {
        background-position: 68% 50%;
      }
    }

    &__img {
      &.is-big-celebrity {
        right: -50px;
      }
    }

    &__subtitle {
      margin-top: 0;
    }
  }

  &__row {
    display: flex;
    margin: 0 -10px;
    padding-top: 20px;

    body.rtl-lang & {
      direction: rtl;
    }
  }

  &__sidebar {
    width: calc(25% - 20px);
    margin: 0 10px;

  }

  &__content {
    width: calc(75% - 20px);
    margin: 0 10px;
    padding-top: 17px;
    display: flex;
    flex-direction: column;

    &--tournament {
      order: 0;
    }
    &--banner {
      order: 2;
    }
  }

  @include s {
    padding: 0 42px 50px;

    &__row {
      margin: 0 -12px;
    }

    &__sidebar {
      width: calc(16.6667% - 24px);
      margin: 0 12px;
    }

    &__content {
      width: calc(83.33334% - 24px);
      margin: 0 12px;
    }
  }

  @include m {
    padding: 0 42px 50px;

    &__slider {
      height: 452px;
    }

    &__row {
      margin: 0 -16px;
    }

    &__sidebar {
      width: calc(16.6667% - 32px);
      margin: 0 16px;
    }

    &__content {
      width: calc(83.33334% - 32px);
      margin: 0 16px;
    }
  }
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.vip-items{
  margin-top: -157px;
  display: grid;
  grid-template-columns: repeat(5 , 1fr);
  grid-gap: 24px;

  body.rtl-lang & {
    direction: rtl;
  }

  @include s { margin-top: -130px; }

  @include m {
    margin-top: -175px;
    grid-gap: 67px;
  }
}
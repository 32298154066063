$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.limit {
  &__btn-wrap {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
  }
}

$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.cashbox-contact{
  height: 44px;
  padding:0 28px;
  background: #192333;

  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  color: #A7AFBE;

  body.rtl-lang & {
    direction: rtl;
  }

  &__link{
    font-size: 14px;
    line-height: 16px;
    color: #A7AFBE;
    margin-right:22px;
    display: flex;
    align-items: center;
    cursor:pointer;
    &::after{
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      background: var(--icon-arr-r);
      filter:$grayFilter;
      margin-inline-start:4px;
      
      body.rtl-lang & {
        background: var(--icon-arr-l);
      }
    }
    &:hover{
      color: $yellow;
      &::after{ filter: $yellowFilter;}
    }
  }


  &__address{
    margin-left:auto;
  }
}
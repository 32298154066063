$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.timer{

  display: flex;
  justify-content: center;

  padding-top:24px;

  &__item{
    position:relative;
    width: 68px;
    height: 82px;
    text-align: center;
    &::before{
      content: '';
      display: block;
      position:absolute;
      top: 6px;
      height: 68px;
      width: 66px;
      background: $red;
      transform:  rotate(90deg) skew(13deg);
      z-index: 10  ;
    }
    &::after{
      content: '';
      display: block;
      position:absolute;
      width: 100px;
      height: 1px;
      z-index: 15;
      background: #0C1221;
      top: 50%;
    }

  }
  &__num{
    position:relative;
    z-index: 12;
    line-height: 82px;
    font-weight: bold;
    font-size: 42px;
  }
  &__label{
    position:absolute;
    z-index: 12;
    top: -24px;
    width: 100% ;
    text-align: center;
  }
  &__dots{
    margin:39px 6px 0;
    width: 16px;
    height: 4px;
    border-left:solid 4px #fff;
    border-right:solid 4px #fff;
    transform:  rotate(90deg) skew(13deg);
  }

}
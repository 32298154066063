$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
@mixin icon-filter($name, $filter) {
    .icon-filter-#{$name} {
        filter: #{$filter};
    }
    .icon-filter-#{$name}__before {
        &::before {
            filter: #{$filter};
        }
    }
    .icon-filter-#{$name}__after {
        &::after {
            filter: #{$filter};
        }
    }
}

@include icon-filter('yellow', $yellowFilter); 
@include icon-filter('blue-gray', $blueGrayFilter); 


.icon-before {
    &::before {
        content: '';
        width: 28px;
        height: 28px;

        flex-shrink: 0;
    }
}
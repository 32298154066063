$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.scroll-box {
    display: flex;
    align-items: center;
    padding: 16px 1px;
    overflow: hidden;

    &__arrow {
        position: relative;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 80px;

        transition: var(--animation-duration) transform;
        filter: drop-shadow(0px 4px 0px rgba(0, 0, 0, 0.25));
        cursor: pointer;

        :hover>& {
            transform: translateX(0);
        }

        &::after {
            content: '';

            width: 100%;
            height: 100%;

            background-color: $yellow;
        }

        &::before {
            position: absolute;
            z-index: 1;

            content: '';

            width: 28px;
            height: 28px;
            background-image: var(--icon-i-arrow);
        }

        &_prev {
            &::after {
                clip-path: polygon(0 0, 100% 13px, 100% calc(100% - 13px), 0 100%);
            }

            &::before {
                transform: rotate(90deg);
            }

            transform: translateX(-110%);
            margin-inline-end: 30px;

        }

        &_next {
            &::after {
                clip-path: polygon(0 13px, 100% 0, 100% 100%, 0 calc(100% - 13px));
            }

            &::before {
                transform: rotate(-90deg);
            }

            transform: translateX(110%);
            margin-inline-start: 30px;
        }

        &.is-disable {
            &::after {
                background-color: $blueDark;
            }

            &::before {
                filter: $blueGrayFilter;
            }
        }

        body.rtl-lang & {
            scale: -1 1;
        }
    }

    &__items {
        display: flex;
        flex-grow: 1;
        width: calc(100% - 124px);
        overflow: hidden;
    }

    &__game {
        margin-inline-end: 42px;

        &:last-child{
            margin-inline-end: 0;
        }
    }

    &--empty {
        display: flex;
        height: 112px;
        align-items: center;
        justify-content: center;
    }

    &__text-empty {
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: $blueGray2;
    }
}
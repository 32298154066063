$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.cashbox-crypro-loader {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &__inner {
        width: 100%;
        max-width: 480px;
        min-height: 506px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #EFF5FD;
        border-radius: 2px;
        padding: 30px;
    }

    &__icon {
        margin-bottom: 12px;

        img {
            width: 38px;
        }
    }

    &__text {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.4em;
        text-align: center;
        color: #A5AFC0;
        width: 100%;
        max-width: 250px;
    }
}
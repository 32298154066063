$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.circle {
    width: 9px;
    height: 9px;
    border: 2px solid #CC0034;
    border-radius: 100%;
    box-shadow: 
        0px 3px 4px rgba(188, 31, 57, 0.8),
        0px 2px 0px #111928;
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.info-page {
    flex-direction: row;
    align-items: flex-start;
    max-width: 1296px;
    margin-top: 36px;
    margin-bottom: 56px;

    @include s {
        margin-top: 40px;
    }

    &__info {
        flex-grow: 1;
    }

    &__menu {
        display: flex;
        flex-shrink: 0;
        flex-direction: column;

        width: 230px;
        margin-top: 76px;
        margin-left: 20px;
        position: sticky;
        top: 100px;

        @include s {
            margin-left: 32px;
            margin-top: 88px;
            width: 280px;
        }
    }
    &__menu-title {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
        text-transform: capitalize;
        padding: 24px 10px;
        color: $wh;
    }
    &__link {
        font-size: 16px;
        line-height: 44px;
        color: $wh;

        padding-left: 40px;
        padding-right: 10px;
        @include ellipsis;

        &.active {
            background-color: $red;
            color: $yellow;
        }

        &.is-ccp {
            text-overflow: none;
            overflow: unset;
            white-space: unset;
            line-height: 24px;
            padding: 10px 10px 10px 40px;
        }
    }

    h1 {
        font-size: 36px;
        font-weight: 700;
        line-height: 40px;
        text-align: center;
        text-transform: uppercase;
        color: $wh;

        margin-bottom: 36px;

        @include s {
            font-size: 42px;
            line-height: 48px;
            
            margin-bottom: 44px;
        }
    }
}
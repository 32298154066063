$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.date-picker-wrapper {
  position: absolute;
  z-index: 4;
  background: #1E2737;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);

  .month-wrapper {
    position: relative;
    overflow: hidden;
    width: 640px !important;

    table {
      float: left;
      width: 270px;
    }

    tbody {
      //border-bottom: 30px solid transparent;
      border-bottom: 10px solid transparent;
    }

    tbody td {
      cursor: pointer;
    }

    .day {
      font-weight: bold;
      height: 39px;
      padding: 10px 0;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #FFFFFF;
      border:solid 2px transparent;
    }

    // current day
    .day.real-today:not(.checked),
    .day.real-today:not(.hovering) {
      background-color: transparent;
      color: $red;
    }

    .day.checked,
    .day.toMonth.hovering {
      color: $yellow;
    }

    .day.checked {
      //border:solid 2px $yellow;
      //color: #0C1221;
    }

    .first-date-selected,
    .last-date-selected {
      font-weight: 700;
      background: $yellow !important;
      color: #0C1221 !important;


    }
    .day:hover{
      border:solid 2px $yellow;
      color: $yellow;
    }
  }

  &.single-date.single-month .month-wrapper {
    width: 320px !important;
    //width: 350px;
  }

  .caption {
    background: #1E2737;
  }

  .month-name {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    text-transform: capitalize;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    height: 64px;
  }

  .prev,
  .next {
    float: left;
    height: 40px;
    width: 25px;
    font-size: 0;
    cursor: pointer;

    background-position: center;
    background-size: 20px;
    background-repeat: no-repeat;


    filter: $whFilter;
    margin-top: 11px;
  }

  .prev {
    transform: translateX(20px);
  }
  .next {
    transform: translateX(-20px);
  }

  .week-name {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: .05em;
    text-transform: uppercase;
    color: #686B6C;

    th {
      height: 35px;
      width: 38px;
      color: #A7AFBE;
      opacity: .8;
      text-align: center;
    }
  }

  .date-range-length-tip {
    display: none;
    position: absolute;

    font-size: 12px;
    line-height: 16px;
    color: #fff;

    margin-top: -4px;
    margin-left: -8px;
    padding: 0 6px;

    background-color: #686B6C;
    border-radius: 2px;

    &::after {
      content: '';

      position: absolute;
      left: 50%;
      bottom: -4px;

      margin-left: -4px;

      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid #686B6C;
    }
  }
}

.date-picker-wrapper .select-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}


.date-picker-wrapper .month-element {
  display: inline-block;
  vertical-align: middle
}

.date-picker-wrapper .select-wrapper select {
  position: absolute;
  margin: 0;
  padding: 0;
  left: 0;
  top: -1px;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  text-transform: inherit;
  color: inherit;
  cursor: pointer;
  appearance: none;
  background: 0 0;
  border: 0;
  outline: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=1)";
  filter: alpha(opacity=1);
  opacity: .01
}

.date-picker-wrapper .month-wrapper table div.day.lastMonth,
.date-picker-wrapper .month-wrapper table div.day.nextMonth {
  color: #999;
  cursor: default
}


.date-picker-wrapper .month-wrapper table .day.has-tooltip {
  cursor: help !important
}

.date-picker-wrapper .month-wrapper table .day.has-tooltip .tooltip {
  white-space: nowrap
}

.date-picker-wrapper .time label {
  white-space: nowrap
}

.date-picker-wrapper .month-wrapper table .day.lastMonth,
.date-picker-wrapper .month-wrapper table .day.nextMonth {
  display: none
}


.date-picker-wrapper .selected-days {
  display: none
}



.date-picker-wrapper .time {
  position: relative
}

.date-picker-wrapper.single-month .time {
  display: block
}


.date-picker-wrapper .hide {
  display: none
}

$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.tournament-page {
    --container-gap: 24px;
   
    display: grid;
    grid-template-areas: 'empty1 side main empty2';
    grid-template-columns: minmax(var(--container-gap), 1fr) 311px minmax(665px, 1250px) minmax(var(--container-gap), 1fr); 
    margin: 40px auto 56px auto;

    @include s {
        --container-gap: 42px;
        grid-template-columns: minmax(var(--container-gap), 1fr) 321px minmax(665px, 1250px) minmax(var(--container-gap), 1fr);
    }
    @include m {
        grid-template-columns: minmax(var(--container-gap), 1fr) 280px minmax(665px, 1250px) minmax(var(--container-gap), 1fr); 
    }
    &__side {
        grid-area: side;
        background-repeat: no-repeat;
        background-size: 100% calc(100% - 4px);
        background-position: 0 4px;
        background-image: linear-gradient(0deg, $blue, $blue);

        padding-bottom: 127px;
    }
    &__main {
        grid-area: main;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    &-wrapper{
        &.preloader {
            min-height: 300px;
        }
    }

    body.rtl-lang & {
        direction: rtl;
    }
}
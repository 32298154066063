$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.crypto-popup {
  background: $blue2;
  display: block;
  color: $wh;
  padding: 24px 80px 43px;
  position: relative;
  &__close {
    position: absolute;
    top: 32px;
    inset-inline-end: 32px;
    width: 32px;
    height: 32px;
    margin-inline-start: 24px;
    filter: $whFilter;
    opacity: 0.8;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
  &__btns {
    text-align: center;
    .btn {
      width: 300px;
    }
  }
  h2 {
    height: 48px;
    @include text(28px, 48px, 400, $wh);
    text-align: center;
    margin-bottom: 20px;
  }
  section {
    padding: 32px 0;
    border-top: 2px solid $red;
    & > *:last-child {
      margin-bottom: 0;
    }
  }
  p,
  ul,
  table {
    @include text(18px, 27px, 400, $wh);
    margin: 0 auto 20px;
  }
  h3 {
    @include text(24px, 29px, 400, $wh);
    margin: 0 auto 24px;
    text-transform: uppercase;
  }
  h4 {
    @include text(24px, 29px, 400, $wh);
    margin: 0 auto 12px;
    text-transform: uppercase;
    &:not(:first-child) {
      margin-top: 24px;
    }
  }
  ul {
    list-style: disc;
    padding-left: 40px;
  }

  a {
    color: $yellow;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
  .screenshots-crypto {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 24px auto;
    & > div {
      max-width: max-content;
      &:nth-child(odd) {
        justify-self: self-end;
      }
      &:nth-child(even) {
        justify-self: self-start;
      }
    }

    img {
      width: 360px;
      height: 440px;
      object-fit: contain;
    }
  }
  .crypto-cashier-mob {
    display: none;
  }
  .crypto-cashier-desk {
    img {
      width: 534px;
    }
  }
  .video-crypto {
    margin: 50px 0;
    iframe {
      width: 640px;
      height: 360px;
      display: block;
      margin: 0 auto;
    }
  }
}

$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.cup-details {
  margin-top:20px;

  body.rtl-lang & {
    direction: rtl;
  }

  &__title {
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
    color: #FFFFFF;
  }

  &__wrap{
    margin-top: 30px;
    background-color: #162334;
    display: flex;
    align-items: stretch;

  }

  &__l {
    position:relative;
    width: 235px;
    flex-shrink: 0;
    background-image: var(--icon-pattern-dots-yellow) , linear-gradient( -59.5deg  , rgba(0,0,0,0) 50%   , #CC0034 40%  , #CC0034 100%) ;
    background-size: 21px , 100% 400px;
    background-repeat: repeat , no-repeat;

    body.rtl-lang & {
      direction: rtl;
    }

    &::before {
      content: '';
      display: block;
      width: 285px;
      position: absolute;
      top: -30px;
      left: 0;
      bottom: -42px;
      background-size: cover;
      background-position: bottom;
      background: url( $cdn + '/rabona/cup-page/cup-banner.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &__r {
    body.rtl-lang & {
      text-align: right;
    }

    flex-grow: 1;
    padding:40px 46px 40px 37px;
  }

  &__item {
    padding-inline-start: 38px;
    font-size: 20px;
    line-height: 150%;
    color: #FFFFFF;
    background-image: linear-gradient( $yellow , $yellow) , linear-gradient( $yellow , $yellow);
    background-size: 18px 2px , 2px 18px;
    background-repeat: no-repeat , no-repeat;
    background-position: 0 15px , 8px 7px;

    body.rtl-lang & {
      background-position: 100% 15px , calc(100% - 8px) 7px;
    }

    & + .cup-details__item { margin-top:16px;}
    & a.is-yellow{ color: $yellow; margin:0 4px; }
  }

  &__btns {
    margin-top:30px;
    min-width: 318px;
    display: inline-block;
  }


  @include s{
    margin-top: 75px;
  }
}
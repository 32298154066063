$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.entrance-bnr-wrapper {
  height: 494px;
  margin-bottom: 30px;
  position: relative;
  z-index: 9;

  @include s {
    height: 580px;
  }

  @include m {
    height: 676px;
  }
}

.entrance-bnr {
  position: relative;

  display: flex !important;
  align-items: center;
  justify-content: space-between;
  height: 494px;
  padding: 40px 18px;
  background-position: center;
  position: relative;

  @include s {
    height: 580px;

    .pay-n-play & {
      margin-top: -10px;
    }
  }

  @include m {
    height: 676px;
    margin-top: -16px;
  }

  &.is-pnp {
    background-image: url($cdn + '/rabona/pay-and-play/bg_banner_payandplay.png');
    width: 100vw;
    left: 50%;
    transform: translate(-50%);
    justify-content: center;
    padding-top: 75px;
    overflow: hidden;
    margin-top: 0;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 108px;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(0deg, rgba(12,18,33,1) 0%, rgba(12,18,33,0) 100%);
    z-index: 2;
    pointer-events: none;
  }

  &__background {
    position: absolute;
    z-index: -2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: auto 600px;
    background-position: bottom center;
    background-repeat: no-repeat;
    @include s {
      background-size: cover;
    }
  }

  &__video {
    position: absolute;
    z-index: -2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    object-fit: cover;
  }

  &__overlay {
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(43.3% 49.85% at 50% 50%, rgba(11, 18, 34, 0.4) 0%, #0b1222 100%);
  }

  &__img {
    position: absolute;
    bottom: -67px;
    inset-inline-start: calc(50% - 82px);
    width: 566px;
    height: 536px;
    opacity: 1 !important;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    &:hover {
      cursor: pointer;
    }

    @media (min-width: 1280px) {
      width: 570px;
      height: 488px;
      top: 25px;
      bottom: 0;
      inset-inline-start: calc(50% - 285px);
    }

    @include s {
      inset-inline-start: calc(50% - 313px);
      top: auto;
      bottom: 46px;
      width: 648px;
      height: 508px;

      .entrance-bnr--unauthorized & {
        inset-inline-start: calc(50% - 174px);
      }
    }

    @include m {
      width: 705px;
      height: 660px;
      bottom: 0;
      inset-inline-start: calc(50% - 343px);

      .entrance-bnr--unauthorized & {
        inset-inline-start: calc(50% - 240px);
      }
    }

    .entrance-bnr--unauthorized & {
      pointer-events: none;
    }

    .linda-has-user & {
      // left: 50%;
    }
  }

  &__content {
    flex: 0 0 400px;
    z-index: 2;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    opacity: 1 !important;

    @include s {
      flex: 0 0 590px;
    }

    @include m {
      flex: 0 0 790px;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 505px;
    width: 738px;
    padding: 30px 55px;
    text-align: center;
    margin: 0 auto;

    border: 8px solid #fff;
    position: relative;

    &:before,
    &:after {
      content: '';
      position: absolute;
      background-repeat: no-repeat;
      top: -35px;
    }

    &:before {
      width: 665px;
      height: 665px;
      right: 100%;
      margin-right: -48px;
      background-image: url($cdn + '/rabona/pay-and-play/cup-gold.png'),
        url($cdn + '/rabona/pay-and-play/cup-silver.png');
      background-position: right center, left center;
      background-size: 372px 665px, 294px 520px;
    }

    &:after {
      width: 926px;
      height: 658px;
      left: 100%;
      margin-left: -150px;
      background-image: url($cdn + '/rabona/pay-and-play/desktop-players.png');
      background-size: 100%;

      background-position: center;
    }

    .pay-n-play & {
      padding: 30px 55px 22px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 1.2em;
    text-transform: uppercase;
    color: $yellow;
    text-shadow: 0 0 6px #0C1221;

    @include s {
      font-size: 36px;
    }

    @include m {
      font-size: 42px;
    }
  }

  &__offer {
    font-weight: 700;
    font-size: 42px;
    line-height: 1.15em;
    color: $wh;
    text-shadow: 0 0 6px #0C1221;

    @include s {
      font-size: 48px;
    }

    @include m {
      font-size: 56px;
    }
  }

  &__btn {
    margin-top: 24px;
    min-width: 300px;
  }

  &__form {
    position: absolute;
    left: 0;
    top: 82px;

    @include s {
      top: 158px;
      left: 55px;
    }

    @media screen and (min-width: 1920px) {
      top: 105px;
      left: 142px;
    }
  }

  &__pagination {
    width: fit-content;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;

    & .slider__dots {
      left: 0;
      transform: none;
      position: relative;
      bottom: 0;

      body.rtl-lang & {
        direction: rtl;
      }
    }

    & .slider__main-arr {
      position: absolute;
      filter: invert(77%) sepia(3%) saturate(1570%) hue-rotate(179deg) brightness(90%) contrast(91%);
      border: none;
      height: 17px;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      width: 17px;
      bottom: -2px;
      appearance: none;

      &.is-prev {
        left: -35px;
        background-image: var(--icon-arr-l);

        &:hover {
          filter: invert(80%) sepia(99%) saturate(3683%) hue-rotate(9deg) brightness(107%) contrast(103%);
        }
      }

      &.is-next {
        right: -35px;
        background-image: var(--icon-arr-r);

        &:hover {
          filter: invert(80%) sepia(99%) saturate(3683%) hue-rotate(9deg) brightness(107%) contrast(103%);
        }
      }
    }
  }
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.cashbox-guide {
  margin-top: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  color: #2a394e;

  ul,
  ol {
    list-style: none;
    counter-reset: list;
  }

  li {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    &:not(:last-child) {
      margin-bottom: 6px;
    }

    &:before {
      counter-increment: list;
      content: counter(list);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      border: 1px solid #2a394e;
      flex-shrink: 0;
      margin-inline-end: 6px;
      font-weight: 400;
      font-size: 12px;
      line-height: 1em;
      color: #2a394e;
      margin-top: 3px;
    }
  }
}
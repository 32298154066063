$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.cta {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    background-color: $red;

    &__text {
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        color: $wh;

        margin-inline-end: 50px;
    }

    &__btn {
        margin-inline-end: 48px;
    }
    &__link {
        font-size: 20px;
        line-height: 24px;
        text-transform: uppercase;
        text-decoration: none;
        color: $yellow;

        cursor: pointer;
        
        &:hover {
            text-decoration: underline;
        }
    }
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.header {
  z-index: 100;
  width: 100%;
  min-width: 1024px;
  filter: drop-shadow(0px -1px 3px #1d2534);
  --height: 36px;
  --hoverHeight: calc(var(--height) + 4px);

  display: grid;
  grid-template-rows: 58px auto;
  grid-template-areas:
    'empty1 logo top empty2'
    'empty1 links links empty2';
  grid-template-columns: 12px 164px 1fr 20px;

  background-image: linear-gradient(to bottom, #111928 0, #111928 99px, #1d2534 99px, #1d2534 100px);
  background-repeat: no-repeat;
  background-size: 100% 100px;

  @include s {
    --height: 56px;

    grid-template-areas:
      'empty logo top empty2'
      'empty logo links empty2';
    grid-template-columns: auto minmax(227px, 227px) minmax(992px, 1570px) minmax(20px, auto);
  }

  @include m {
    grid-template-columns:
      minmax(0, 1fr) minmax(210px, 210px) minmax(992px, 1570px)
      minmax(0, 1fr);
  }

  body.rtl-lang & {
    direction: rtl;
  }

  &__logo {
    grid-area: logo;
    background-repeat: no-repeat;
    background-position: top -11px left 26px;
    background-size: 127px auto;
    // background-image: var(--icon-logo);
    background-image: url('../src/sprite/logo.svg');

    @include s {
      background-size: 162px auto;
      background-position: 40px top;
      height: 116px;
    }

    @include m {
      background-position: center 0;
    }
  }

  &__links {
    grid-area: links;
    display: flex;
    align-items: center;
    height: var(--height);
    padding: 0 1px;
    transform: skew(-27deg);
    margin: 0 5px;
    background-color: #0c1221;

    @include s {
      margin: 0 13px;
    }

    body.rtl-lang & {
      transform: skew(27deg);
    }
  }

  &__link-label {
    flex-shrink: 0;
    background-color: #bc1f39;

    body.rtl-lang & {
      text-align: center;
    }

    &.is-full {
      display: block;
      min-width: 40px;
      height: 16px;
      padding: 2px;
      margin-inline-start: 4px;
      font-size: 12px;
      color: #ffffff;
      line-height: 12px;
      text-shadow: none;
      transform: skewX(27deg);
      clip-path: polygon(0 100%, 8px 0, 100% 0, calc(100% - 8px) 100%);

      .small-font & {
        @include s {
          display: none;
        }
        @include m {
          display: block;
        }
      }
    }

    &.is-seasons {
      font-weight: 700;
      line-height: 14px;
      display: flex;
      font-size: 7px;
      align-items: center;
      justify-content: center;
    }
  }

  &__link {
    flex-grow: 1;
    font-size: 16px;
    font-style: italic;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.4), 1px 2px 0px #1e2737;
    @include ellipsis;
    color: $blueGray;
    cursor: pointer;
    order: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--height);
    margin-inline-end: 1px;
    padding: 0 14px;

    background-image: linear-gradient(to right, #2d394c, #2d394c);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 0;
    background-color: $blueMenu;
    box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.03);
    transition-duration: 0.15s;
    transition-property: background, height, color;

    &.is-oktoberfest {
      height: var(--height);
      min-height: initial;
      padding: 0 24px;

      @include s {
        padding: 0 27px;
      }

      &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        background: linear-gradient(rgba(28, 39, 56, 0), rgba(255, 77, 0, 0.6));
      }

      & img {
        width: 18px;
        height: 18px;
        margin-inline-end: 4px;
        transform: skew(27deg);
        z-index: 1;
        flex-shrink: 0;

        @include s {
          width: 24px;
          height: 24px;
          margin-inline-end: 6px;
        }
      }
    }

    &.is-easter,
    &.tyson-promo {
      height: var(--height);
      min-height: initial;
      padding: 0 24px;

      @include s {
        padding: 0 27px;
      }

      &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        background: linear-gradient(180deg, rgba(1, 91, 159, 0) 0%, rgba(1, 91, 159, 0.8) 89.85%);
      }

      .header__link-label {
        width: 45px;
        padding: 3px 10px;
        color: #ffffff;
        text-align: center;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        position: relative;
        z-index: 1;
        margin-inline-start: 15px;
        transform: skew(27deg);
        background-color: transparent;

        &::before {
          content: '';
          position: absolute;
          inset: 0;
          background-color: #BC1F39;
          transform: skew(-27deg);
          box-shadow: 2px 3px 0 rgba(42, 51, 65, 0.25);
          z-index: -1;
        }
      }
    }

    &.is-easter {
      &::before{
        content: none;
      }
      .header__link-label{
        display: none;
        @media (min-width: 1280px) {
          display: block;
        }
      }
    }

    &.is-santa {
      display: flex;
      align-items: center;
      column-gap: 4px;

      @include s {
        column-gap: 8px;
      }

      &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        background: linear-gradient(rgba(28, 39, 56, 0), rgba(227, 42, 42, 0.6));
      }

      img {
        position: relative;
        width: 16px;
        transform: skew(27deg);
        z-index: 1;

        @include s {
          width: 18px;
        }

        body.rtl-lang & {
          transform: skew(-27deg);
        }
      }
    }

    &.australian-tournament {
      position: relative;
      background-image: linear-gradient(to right, #ecfd00 0 1px, #2d394c 2px calc(100% - 2px), #ecfd00 calc(100% - 1px));

      .header {
        &__link-icon {
          z-index: 1;
          position: relative;
          width: 18px;
          height: 18px;
          background-position: 50%;
          background-repeat: no-repeat;
          background-size: contain;
          margin-top: -2px;
          margin-inline-end: 8px;
          transform: skew(27deg);

          @include s {
            width: 24px;
            height: 24px;
          }
        }

        &__link-text {
          position: relative;
          color: #A5AFC0;
          z-index: 1;
        }
      }

      &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        box-shadow: 0 1px 0 0 rgba(0, 145, 210, 0.05) inset;
        background: linear-gradient(rgba(28, 145, 210, 0), rgba(0, 145, 210, 0.8));
      }

      &::before {
        content: '';
        mask: url('./img/dots.svg');
        mask-size: cover;
        mask-position: center;
        mask-repeat: no-repeat;
        background-color: #576172;
        position: absolute;
        width: 118%;
        height: 44px;
        transform: skew(27deg);

        @include s {
          height: 56px;
        }

        @include m {
          height: 66px;
        }
      }

      &:hover,
      &:active,
      &.active {
        background-size: 100% 100%;

        &::before {
          background-color: rgba(255, 255, 255, 0.40);
        }

        .header__link-text {
          color: #ECFD00;
        }
      }
    }

    a {
      transform: skew(27deg);
      color: $blueGray;
      text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.4), 1px 2px 0px #1e2737;
    }

    &:last-child {
      margin-inline-end: 0;
    }

    &:hover,
    &.active {
      height: var(--hoverHeight);

      background-size: 100% 100%;
      color: #ecfd00;
      text-shadow: 0px 0px 4px rgba(239, 251, 55, 0.5), 1px 2px 0px #1e2737;

      a {
        color: #ecfd00;
        text-shadow: 0px 0px 4px rgba(239, 251, 55, 0.5), 1px 2px 0px #1e2737;
      }
    }

    @include s {
      padding: 0 12px;
    }

    &_mask {
      position: relative;
      background-image: linear-gradient(
        to right,
        #ecfd00 0,
        #ecfd00 1px,
        #2d394c 2px,
        #2d394c calc(100% - 2px),
        #ecfd00 calc(100% - 1px)
      );

      &::before {
        content: '';
        mask: url('./img/dots.svg');
        mask-size: cover;
        mask-position: center;
        mask-repeat: no-repeat;
        background-color: #576172;
        position: absolute;
        width: 118%;
        height: 44px;
        transform: skew(27deg);

        @include s {
          height: 56px;
        }

        @include m {
          height: 66px;
        }

        body.rtl-lang & {
          transform: skew(-27deg);
        }
      }

      &:hover::before,
      &.active::before {
        background-color: #ecfd00;
      }

      &.is-santa {
        background-image: linear-gradient(
          to right,
          #FD0000 0,
          #FD0000 1px,
          #2d394c 2px,
          #2d394c calc(100% - 2px),
          #FD0000 calc(100% - 1px)
        );

        &:hover,
        &.active {
          &::before {
            background-color: rgba(253 0 0 / 50%);
          }

          .header__link-text.is-santa {
            color: #FF7A7A;
          }
        }
      }
    }

    &.is-canadian {
      &__first {
        order: -2;
      }
      &__second {
        order: -1;
      }
    }

    [lang="hi-IN"] & {
      padding: 0 8px;
    }
  }

  &__link-text {
    font-size: 10px;
    transform: skew(27deg);

    @media (min-width: 1280px) {
      font-size: 12px;
    }

    body.rtl-lang & {
      transform: skew(-27deg);
    }

    &.is-oktoberfest {
      color: #c0a7a5;
      text-shadow: 0 0 4px rgba(255, 255, 255, 0.4), 1px 2px 0 #1e2737;
    }

    &.is-easter,
    &.tyson-promo {
      position: relative;
      z-index: 1;
      text-shadow: 1px 2px 0 #1E2737;
    }

    &.is-santa {
      position: relative;
      z-index: 1;
      color: #C6A8A8;
      text-shadow: 1px 2px 0 #1E2737;
    }

    @include ellipsis;

    @include s {
      font-size: 14px;
    }

    @include m {
      font-size: 16px;
    }

    &_desktop {
      display: none;

      @include m {
        display: block;
      }
    }

    &_mobile {
      @include m {
        display: none;
      }
    }

    .small-font & {
      font-size: 10px;
      @include xs {
        font-size: 11px;
      }
      @include s {
        font-size: 13px;
      }
    }

    [lang="hi-IN"] & {
      padding: 5px 6px;
    }
  }

  &__top {
    grid-area: top;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include s {
      padding-inline-start: 31px;
    }
  }

  &__left {
    display: flex;
    align-items: center;
  }

  &__aviator {
    position: relative;

    @include s-max {
      &:hover .header__tooltip {
        opacity: 1;
        z-index: 1;
      }
    }
  }

  &__item {
    body.rtl-lang & {
      font-size: 14px;
    }
    font-size: 16px;
    line-height: 28px;
    color: $wh;
    position: relative;
    display: flex;
    align-items: center;
    height: 28px;
    min-width: 28px;
    padding-inline-start: 28px;

    background-position: 0 -1px;
    background-repeat: no-repeat;

    &:hover {
      .header__tooltip {
        opacity: 1;
        z-index: 1;
      }
    }

    @include s {
      margin-inline-start: 15px;
    }
  }

  &__points {
    background-image: var(--icon-i-points);
    margin-inline-start: 15px;

    @include s {
      margin-inline-start: 4px;
    }

    body.rtl-lang & {
      background-position: 100% 0;
    }

    span {
      padding-inline-end: 6px;
      margin-inline-start: 4px;
    }
  }

  &__challenges {
    position: relative;
    margin-inline-start: 12px;
    background-image: var(--icon-i-challenges);
    color: $blueGray;

    @include s {
      margin-inline-start: 18px;
    }

    body.rtl-lang & {
      background-position: 100% 0;

      a {
        display: flex;
      }
    }

    & a {
      color: $blueGray;
      height: inherit;

      body.rtl-lang & {
        direction: ltr;
      }
    }
  }

  &__ibtn {
    &.unlogged {
      margin-inline-start: 0;
    }

    &:hover {
      color: $yellow;
    }

    & span {
      margin-inline-start: 4px;
    }
  }

  &__ibtn-circle {
    margin-inline-start: 10px;
  }

  &__right {
    display: flex;
    align-items: center;
  }

  &__btn {
    margin-inline-end: 13px;
  }

  &__reminder {
    position: relative;
    font-size: 12px;
    line-height: 28px;
    color: $wh;

    padding-inline-start: 8px;
    padding-inline-end: 16px;

    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));
    background-color: $red;
    clip-path: polygon(
      0 0,
      calc(100% - 8px) 0,
      calc(100% - 8px) 6px,
      100% 50%,
      calc(100% - 8px) calc(100% - 6px),
      calc(100% - 8px) 100%,
      0 100%
    );
    animation: shake-horizontal 2.5s infinite cubic-bezier(0.455, 0.03, 0.515, 0.955) both;

    body.rtl-lang & {
      clip-path: polygon(
        8px 0,
        100% 0,
        100% 100%,
        8px 100%,
        8px calc(100% - 6px),
        0 50%,
        8px 6px
      );
    }
  }

  &__reminder-container {
    animation: reminder 10s linear infinite;
    margin-inline-end: 20px;
  }

  &__balance {
    margin-inline-end: 16px;
  }

  &__dep {
    margin-inline-end: 11px;
  }

  &__tooltip {
    position: absolute;
    bottom: 0;
    left: 14px;
    transform: translateX(-50%) translateY(40px);
    opacity: 0;
    z-index: -1;

    &.is-aviator {
      left: 50%;
    }
  }

  &__tooltip-wrap {
    position: relative;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #0b1222;
    background: #eff5fd;
    height: 33px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    white-space: nowrap;

    &::before {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateY(-7px) translateX(-50%) rotate(45deg);
      width: 16px;
      height: 16px;
      top: 0;
      background-color: inherit;
    }
  }
}

@keyframes reminder {
  0% {
    opacity: 0;
  }

  70% {
    opacity: 0;
  }

  75% {
    opacity: 1;
  }

  95% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes shake-horizontal {
  0%,
  100% {
    left: 0;
  }

  10%,
  30%,
  50%,
  70% {
    left: -5px;
  }

  20%,
  40%,
  60% {
    left: 5px;
  }

  80% {
    left: 4px;
  }

  90% {
    left: -4px;
  }
}

$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.bnr-login {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 100%;

    background-size: cover;
    background-repeat: no-repeat;

    &__info {
        display: flex;
        flex-direction: column;

        max-width: 750px;
        @include combine(width, calc(100% - 100px), calc(100% - 212px));
        @include combine(padding, 39px 42px, 44px 42px, 44px 100px);
        @include combine(margin-bottom, 35px, 100px);


        @include border(8px);
        background-color: rgba($red, .7);
    }

    &__text {
        @include combine(font-size, 42px, 56px);
        @include combine(line-height, 49px, 66px);
        font-weight: 700;
        text-transform: uppercase;
        color: $wh;
        white-space: break-spaces;

        .small-font & {
          @include combine(font-size, 32px, 40px)
        }
    }
}
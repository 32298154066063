$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.bnr {
    display: flex;
    height: 452px;

    &__info {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        width: 500px;
        padding: 30px 20px 50px 30px;
        background-color: $blue;

        &::before{
            content: '';
            position: absolute;
            inset: 0;
            pointer-events: none;
            z-index: -1;

            background-image: url($cdn + '/rabona/elements/pattern-collections.svg');
            background-repeat: no-repeat;

            background-position: left bottom;

            body.rtl-lang & {
                scale: -1 1;
            }
        }

        @include m {
            width: 608px;
            padding-top: 40px;
        }

    }

    &__promo {
        //background-image: url($cdn + '/rabona/collections/pic-collection-main.jpg');
        background-position: left top;
        background-size: cover;
        flex-grow: 1;

        body.rtl-lang & {
            scale: -1 1;
        }
    }

    &__title {
        @include max-height(--lh, 54px, 3);
        font-size: 46px;
        font-weight: 700;
        text-transform: uppercase;
        color: $wh;
        white-space: break-spaces;

        .small-font & {
          font-size: 40px;
        }

        @include m {
          font-size: 44px;

          .small-font & {
            font-size: 38px;
          }
        }
    }

    &__subtitle {
        @include max-height(--lh, 40px, 2);
        font-size: 34px;
        font-weight: 700;
        color: $yellow;

        margin-top: 10px;
        white-space: break-spaces;

        .small-font & {
          font-size: 30px;
        }

        @include m {
            margin-top: 15px;
        }
    }

    &__btn {
        align-self: flex-start;
        min-width: 253px;
        margin-top: auto;
    }
}
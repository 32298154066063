$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.easter-banner {
  position: relative;
  display: flex;
  justify-content: flex-end;
  text-align: right;
  padding: 48px 24px 40px;
  overflow: hidden;
  min-height: 388px;
  margin-top: 76px;

  @include s {
    padding-right: 48px;
  }

  @include m {
    padding-right: 164px;
    margin-top: 97px;
  }

  &__bg {
    background-size: cover;
    background-position: 50% 50%;
    position: absolute;
    inset: 0;

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      background-image: radial-gradient(rgba(53, 29, 87, 0), rgb(42, 61, 117)), linear-gradient(90deg, rgba(42, 61, 117, .03), rgb(42, 61, 117));
    }
  }

  &__img {
    position: absolute;
    top: 0;
    left: 70px;
    width: 334px;
    height: 493px;

    @include s {
      left: 140px;
    }

    @include m {
      left: 234px;
    }
  }

  &__content {
    position: relative;
    max-width: 55%;
    font-weight: 700;
    line-height: 1.17;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-size: 32px;
    color: #fff;
    margin-bottom: 16px;

    @include s {
      font-size: 42px;
    }
  }

  &__description {
    font-size: 40px;
    color: #ECFD00;
    margin-bottom: 16px;

    @include s {
      font-size: 52px;
    }
  }

  &__button {
    margin-top: auto;
    font-weight: 400;
  }
}

$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.promo-steps {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  margin-bottom: 40px;

  &__step {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    width: 296px;
    padding: 12px;
    font-size: 12px;
    line-height: 14px;
    background-color: $blueMenu;
    position: relative;
    counter-increment: item;

    body.rtl-lang & {
      direction: rtl;
    }
  }

  &__link {
    position: absolute;
    inset: 0;
  }

  &__number {
    width: 37px;
    height: 37px;
    flex-shrink: 0;
    background-color: $yellow;
    color: $blueDark;
    border-radius: 100%;
    font-weight: 700;
    font-size: 20px;
    line-height: 37px;
    text-align: center;
    &::after {
      content: counter(item);
    }
  }

  &__step {
    &-info {
      display: flex;
      flex-direction: column;
      gap: 4px;
      flex-grow: 1;
    }

    &-name {
      text-transform: uppercase;
      font-weight: 700;
      color: $wh;
    }

    &-text {
      font-weight: 400;
      color: $blueGray;
    }
  }

  &__arrow {
    width: 13px;
    height: 13px;
    flex-shrink: 0;
    filter: $grayFilter;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}

$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.auth-deposit-payment {
    position: relative;

    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        height: 10px;
        left: -1px;
        right: 4px;
        pointer-events: none;
        background-image: linear-gradient(180deg, rgba(22, 35, 52, 0.00) 0%, #162334 100%);
    }

    &::before {
        top: -1px;
        transform: rotate(180deg);
    }

    &::after {
        bottom: -1px;
    }

    @include s {
        max-height: 536px;
    }

    &__inner {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 8px;
        overflow: hidden;
        overflow-y: auto;
        max-height: 430px;
        padding: 8px 4px 8px 0;

        -webkit-overflow-scrolling: touch;

        &.preloader {
            min-height: 300px;
        }

        &::-webkit-scrollbar-track {
            width: 4px;
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 2px;
            background: rgba(165, 175, 192, 0.2);
        }

        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
        }

        &::-moz-scrollbartrack {
            width: 4px;
            background: transparent;
        }

        &::-moz-scrollbarthumb {
            border-radius: 2px;
            background: rgba(165, 175, 192, 0.2);
        }

        &::-moz-scrollbar {
            width: 4px;
            height: 4px;
        }
    }

    &__item {
        border-radius: 2px;
        background-color: #EFF5FD;
        padding: 12px;
        min-height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
        }
    }
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.cup-info {
  margin: 40px 0 40px;
  border: 1px solid #293241;
  padding: 30px;
  padding-inline-start: 149px;
  background-image: url( $cdn + '/rabona/cup-page/icon-info.svg');
  background-size: 62px 62px;
  background-repeat: no-repeat;
  background-position: 40px center;

  body.rtl-lang & {
    direction: rtl;
    background-position: calc(100% - 40px) center;
  }

  &__title {
    font-size: 28px;
    line-height: 120%;
    text-transform: uppercase;
    color: #FFFFFF;
  }

  &__text {
    margin-top: 8px;
    font-size: 16px;
    line-height: 150%;
    color: #A5AFC0;
  }
}
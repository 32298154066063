$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.challenge-progress {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border: 3px solid $red;
  column-gap: 12px;

  &__text {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include text(20px, 140%, 700, $wh);
    text-transform: uppercase;

    &.is-big {
      font-size: 32px;
      color: $yellow;

      body.rtl-lang & {
        direction: ltr;
      }
    }

    &.is-small {
      width: 100%;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      text-transform: initial;
    }

    &.is-points-icon {
      position: relative;
      line-height: normal;
      padding-inline-start: 32px;
      &::before {
        content: '';
        display: block;
        background-image: var(--icon-i-points-hover);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        width: 44px;
        height: 44px;
        position: absolute;
        inset-inline-start: -8px;
        top: -3px;
      }
    }

    &-first {
      body.rtl-lang & {
        order: 0;
      }
    }

    &-second {
      body.rtl-lang & {
        order: 1;
      }
    }
  }
}
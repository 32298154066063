$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.card-guide {
  &__row {
    margin: 50px 0;
    display: flex;
    justify-content: space-between;

    body.rtl-lang & {
      direction: rtl;
    }
  }

  &__img {
    width: 478px;
    flex-shrink: 0;
  }

  &__content {
    flex-grow: 1;
    margin-inline-start: 20px;
  }

  &__title {
    font-size: 26px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    margin-bottom:22px;
  }

  &__wgt {
    max-width: 160px;
    margin-bottom: 14px;

    &.is-custom {
      height: 32px;
      display: inline-flex;
      align-items: center;
      background-image: var(--icon-i-challenges);
      background-size: 28px;
      background-repeat: no-repeat;
      background-position: 2px center;
      padding-inline-start: 34px;
      padding-inline-end: 10px;
      background-color: #232A38;
      font-size: 16px;
      line-height: 32px;

      body.rtl-lang & {
        background-position: calc(100% - 2px) center;
        padding-inline: 10px 34px;
      }
    }

    &.is-chalanges {
      line-height: 19px;
      color: #A5AFC0;
    }

    &.is-point {
      color:#fff;
      background-image: var(--icon-i-points);
    }

    body.rtl-lang & {
      direction: ltr;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 150%;
    color: #A5AFC0;
  }

  & p + p { margin-top: 12px;}


  @include s {
    &__img { width: 489px; }
    &__content { margin-inline-start: 34px;}
  }
}
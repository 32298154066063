$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.cashbox-warning {
    width: 100%;
    max-width: 866px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 2px;
    border: 1px solid #CC0034;
    background-color: #EFF5FD;
    padding: 6px 8px;
    text-align: center;

    &__title {
        width: 100%;
        color: #BC1F39;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.4;
    }

    &__text {
        width: 100%;
        color: #2A394E;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.4em;
    }
}

$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.entrance-cards{
  width: 100%;  
  video{
    min-height: 100%;
    object-fit: cover;
  }

}
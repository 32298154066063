$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.entrance-streaming {
    background-color: #273242;
    border-radius: 2px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    &::after {
        content: '';
        display: block;
        flex-grow: 1;
        background-position: 2px bottom;
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9InllcyI/Pjxzdmcgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxjaXJjbGUgY3g9IjEwIiBjeT0iMTAiIHI9IjEiIGZpbGw9IiNFRkZCMzciIGZpbGwtb3BhY2l0eT0iLjM1IiBzdHlsZT0iIi8+PC9zdmc+');
    }

    body.rtl-lang & {
        direction: ltr;
    }
}
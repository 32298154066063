$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.tournament-sport {
  position: relative;
  grid-column: 1/-1;
  min-height: 376px;
  background-color: $blue1;
  &__inner {
    min-height: inherit;
    height: 100%;
    width: 100%;
    display: flex;
    background-repeat: no-repeat, no-repeat;
    background-position: bottom left 420px, top left -34px;
    background-size: auto 355px, cover;
    @include s {
      background-position: bottom center, top left;
    }
    @include m {
      background-position: bottom right 52%, top left 70px;
    }
    .is-euro &{
      background-position: left 391px bottom -50px, left 403px bottom -130px, 0px 0;
      background-repeat: no-repeat, no-repeat;
      background-size: auto 421px, auto 676px, cover;
      @include s {
        background-size: auto 451px, auto 676px, cover;
        background-position: left 351px bottom -80px, left 403px bottom -135px, 0 0;
      }
      @include m {
        background-position: left 436px bottom -80px, left 487px bottom -135px, 0 0;
      }
    }

    body.rtl-lang & {
      background-position: bottom right 420px, top right -34px;

      @include s {
        background-position: bottom center, top right;
      }
      @include m {
        background-position: bottom left 52%, top right 70px;
      }
    }
  }
  &__left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 430px;
    padding: 36px 46px;
    padding-inline-end: 90px;
    clip-path: polygon(0 0, 100% 0, 340px 100%, 0% 100%);
    background-color: #bc1f39;
    color: $wh;
    .is-euro &{
      width: 462px;
      padding: 35px 93px 36px 40px;
      justify-content: space-between;
      clip-path: polygon(0 0, 100% 0, 376px 100%, 0% 100%);
    }

    body.rtl-lang & {
      clip-path: polygon(0 0, 100% 0, 100% 100%, calc(100% - 340px) 100%);
    }

    // @include s {
    //   width: 550px;
    //   padding: 36px 140px 36px 70px;
    //   clip-path: polygon(0 0, 100% 0, 460px 100%, 0% 100%);
    // }
  }
  &__label {
    @include text(14px, 14px, 300, $wh);
    text-transform: uppercase;
    margin-bottom: 8px;
    letter-spacing: 0.1em;
    .is-euro &{
      @include text(20px, 20px, 400, $wh);
    } 
  }
  &__title {
    text-align: center;
    @include text(32px, 120%, 700, $wh);
    text-transform: uppercase;
    margin-bottom: 16px;
    .is-euro &{
      @include text(40px, 120%, 700, $wh);
    }
  }
  &__prize {
    @include text(60px, 80%, 700, $yellow);
    text-transform: uppercase;
    margin-bottom: 20px;
    .is-euro &{
      @include text(100px, 80%, 700, $yellow);
    }
  }
  &__info {
    display: flex;
    align-items: center;
    @include text(16px, 18px, 700, $yellow);
    text-transform: uppercase;
    margin-bottom: 23px;
    &::before {
      content: '';
      width: 17px;
      height: 17px;
      padding: 6px;
      margin-inline-end: 8px;
      background-image: var(--icon-min-bet-hover);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
  &__btn {
    height: 56px;
    line-height: 56px;
    width: fit-content;
    min-width: 212px;
  }
  &__board {
    display: none;
    align-self: center;
    margin-inline: auto 35px;
    margin-block: 0;
    width: 362px;
    @include s {
      display: block;
    }
    @include m {
      width: 400px;
      margin-inline-end: 45px;
    }
  }
  &.is-single-page {
    margin-top: 44px;
  }
  &__right {
    display: none;
    flex-direction: column;
    max-width: 297px;
    gap: 8px;
    justify-content: center;
    margin-inline: auto 54px;

    @include s {
      display: flex;
    }
  }
  &__link {
    @include text(16px, 18px, 700, $wh);
    text-transform: uppercase;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }

    &.is-visible {
      display: inline-block;
      align-self: center;
    }

    &.loading {
      pointer-events: none;
    }

    @include s {
      display: none;
      &.is-visible {
        display: inline-block;
      }
    }
  }

  &__right-visible {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #162334;
    border-radius: 2px;
    padding: 18px;
  }

  &__counter-title {
    margin-bottom: 18px;
    color: #fff;
    text-align: center;
    font-size: 20px;
    text-transform: uppercase;
  }

  &.has-two-blocks {
    background-color: transparent;
    border-radius: 2px;
  }

  &.has-two-blocks & {
    &__inner {
      position: relative;
      background-position: center;
      background-size: cover;
      border-radius: 2px;
      overflow: hidden;
    }
    &__left {
      @include s {
        width: 480px;
        clip-path: polygon(0 0, 100% 0, 390px 100%, 0% 100%);
      }
    }
    &__title {
      margin-bottom: 8px;
    }
    &__prize {
      font-size: 54px;
      margin-bottom: 24px;
    }
    &__person {
      position: absolute;
      top: 0;
      left: 315px;
      width: 377px;
      height: 413px;
      object-fit: contain;
      transform: scaleX(-1);
      z-index: 1;
      @include s {
        left: unset;
        right: 10%;
      }
    }
    &__btn {
      height: 48px;
      line-height: 48px;
      width: fit-content;
      min-width: 224px;
      margin: 4px 0 24px;
    }
  }
}

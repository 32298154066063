$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.popup {
  $root: &;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 150px 0;
  transition: 0.2s;

  max-height: 100%;
  overflow: auto;

  background-color: rgba(0, 0, 0, 0.8);

  @include scroll;

  &.is-cashbox {
    padding: 46px 0;
  }
  &.is-fullscreen {
    padding: 0;
    width: auto;
  }
  &.is-modal {
    z-index: 2000;
  }
  &.is-promo {
    padding: 33px 0;
  }
  &.is-fp {
    padding: 64px 0;
  }

  &__wrap {
    position: relative;
    width: 564px;
    background: $blue;
    padding: 24px 80px 44px;

    animation: popupShowWrap 0.2s linear;
    & *:not(.popup__optional-link):not(.popup__optional-login):not(.popup__optional-flex) {
      animation: popupShowWrapInside 0.5s linear;
      animation-delay: 0.1s;
      animation-fill-mode: backwards;
    }

    &.is-532 {
      width: 532px;
      padding: 24px 64px 44px;
    }
    &.is-740 {
      width: 740px;
      padding: 40px 80px;
    }
    &.is-848 {
      width: 848px;
      padding: 94px 106px;
    }
    &.is-924 {
      width: 924px;
      padding: 0;
    }
    &.is-wide {
      width: 976px;
    }

    &.is-buy-card {
      overflow: hidden;

      #{$root}__title {
        font-size: 38px;
        line-height: 44px;
      }

      #{$root}__box {
        margin-top: 24px;
        margin-bottom: 24px;
        text-align: center;
      }

      #{$root}__vbtns {
        margin-top: 24px;
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      #{$root}__point-count {
        margin-top: 30px;
      }
    }

    body.rtl-lang & {
      direction: rtl;
    }
  }

  &__close {
    position: absolute;
    top: 33px;
    inset-inline-end: 33px;
    width: 32px;
    height: 32px;
    filter: $whFilter;
    cursor: pointer;
  }

  &__title {
    font-size: 28px;
    line-height: normal;
    padding-bottom: 12px;
    color: #ffffff;
    text-align: center;
    &:not(.no-border) {
      border-bottom: solid 2px $red;
    }
    &_left {
      text-align: left;
      max-width: 350px;
    }
    &.has-fsz-38 {
      font-size: 38px;
    }
  }

  &__box {
    margin-top: 44px;
    border: 1px solid #293241;
    padding: 16px 18px;
    font-size: 16px;
    line-height: 24px;
    color: $blueGray;
    a {
      color: $yellow;
      &:hover {
        text-decoration: underline;
      }
    }
    batman-modal-wrapper1 &,
    batman-popup-wrapper8 & {
      white-space: break-spaces;
      &.small-font {
        font-size: 14px;
      }
    }
    .autobonus-popup & {
      border: none;
      padding: initial;
      margin-top: initial;
    }
  }
  &__box-sum {
    display: inline-block;
  }
  &__btn {
    margin-top: 44px;
    &_20 {
      margin-top: 20px;
    }
    &.is-w270 {
      width: 270px;
      margin-left: auto;
      margin-right: auto;
    }
    &.is-w300 {
      width: 300px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__table {
    margin: 20px 0 40px;
  }
  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 8px;
    border-bottom: solid 1px #293241;
    height: 48px;
  }
  &__key {
    font-size: 14px;
    color: #ffffff;
    width: 50%;
    flex-shrink: 0;
    text-transform: uppercase;
  }
  &__val {
    font-size: 16px;
    line-height: 120%;
    color: #a7afbe;
    width: 50%;
    flex-shrink: 0;
    text-align: end;
  }
  &__text {
    font-size: 16px;
    line-height: 24px;
    color: #a7afbe;

    &.has-border {
      border-bottom: solid 2px $red;
      padding-bottom: 20px;
      margin-bottom: -20px;
    }
    &.is-migration {
      font-size: 18px;
      line-height: 27px;
      color: #a5afc0;
      p {
        margin-top: 18px;
      }
    }

    &.is-center {
      text-align: center;
    }
  }
  &__vbtns {
    width: 274px;
    margin: 44px auto 0;
    text-align: center;
    &.is-300 {
      width: 300px;
    }
    .autobonus-popup & {
      margin: 24px auto 24px;
      width: auto;
    }
  }

  &__redbox {
    position: relative;
    background-color: $red;
    background-position: center top;
    min-height: 180px;
    margin: 20px -80px 0;
    border-top: solid 8px $red;
    border-bottom: solid 8px $red;
    border-left: solid 16px $red;
    border-right: solid 16px $red;
    .is-532 & {
      margin: 20px -64px 0;
    }
  }

  &__list {
    counter-reset: list;
    font-size: 16px;
    line-height: 24px;
    color: $blueGray;
  }
  &__list-item {
    counter-increment: list;
    display: flex;
    &:not(:first-child) {
      margin-top: 12px;
    }
    &::before {
      content: counter(list) '.';
      flex: 0 0 30px;
      margin-right: 8px;
      font-weight: 700;
      text-align: right;
    }
  }
  &__link {
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: $blueGray;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;

    a {
      color: $yellow;
      text-decoration: none;
      cursor: pointer;
    }
  }
  &__link + &__link {
    position: relative;
    margin-top: 10px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: -6px;
      width: 100%;
      height: 2px;
      background-color: $red;
    }
  }

  &__img-block {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
  }

  &__img {
    height: 38px;
    margin: 0 6px;

    &.is-lang {
      width: 52px;
    }
    &.is-trustly {
      width: 124px;
    }
  }

  // pnp
  &__pnp-title {
    font-size: 42px;
    line-height: 50px;
    color: $wh;
  }

  &__pnp-subtitle {
    font-size: 16px;
    line-height: 19px;
    color: $blueGray;

    margin-top: 6px;
  }

  &__pnp-divider {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 4px;
    margin-top: 20px;
    margin-bottom: 28px;

    &::after,
    &::before {
      content: '';

      flex: 0 calc(50% - 2px);

      background: $red;
      transform: skew(-20deg);
    }
  }
  &__pnp-form {
    display: flex;
    flex-direction: column;
  }

  &__pnp-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px 24px;
  }
  &__pnp-check {
    max-width: 430px;
    margin-top: 40px;
  }
  &__pnp-check + &__pnp-check {
    margin-top: 20px;
  }
  &__pnp-btn {
    justify-self: center;
    margin: 75px auto 0 auto;
    min-width: 344px;
  }

  &__point {
    width: 70px;
    height: 70px;
    margin: 15px auto 0;
  }
  &__summ {
    font-weight: bold;
    font-size: 40px;
    line-height: 120%;
    color: #ffffff;
    margin: 20px auto 0;
    text-align: center;
  }
  &__logo {
    width: 84px;
    height: 84px;
    margin: 10px auto 0;
    background-color: rgba(0, 0, 0, 0.2);
  }
  &__point-count {
    //display: inline-block;
    //margin: 46px auto 0;
    //position:relative;
    //.is-m50{ margin:50px auto;}
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }

  // Optional styles

  &__optional-login {
    background-color: #0b1222;
    padding: 14px 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: calc(100% + 28px);
    max-height: 47px;
    margin-left: -14px;
    margin-bottom: 28px;
    transition: max-height 0.4s;
    &.is-shown {
      max-height: 300px;
      transition: max-height 0.4s;
    }
  }

  &__optional-flex {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 38px;

    .form-box {
      width: calc(50% - 12px);
    }
  }

  &__optional-text {
    width: 50%;
    @include text(16px, 19px, normal, $wh);
    @include ellipsis;

    padding-left: 28px;
    background-size: 20px;
    background-repeat: no-repeat;
  }
  &__optional-link {
    width: 50%;
    @include text(16px, 19px, normal, $blueGray);
    @include ellipsis;
    text-decoration: underline;
    text-align: right;
    cursor: pointer;
  }

  &__lang-box {
    width: 290px;
    margin: 44px auto 0;
    $item: &;
    &__list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 24px;
      row-gap: 16px;
    }
    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:hover,
      &.active {
        #{$item}__img {
          background-color: #2a394e;
        }
        #{$item}__info {
          color: #fff;
        }
      }
    }
    &__img {
      border: 1px solid #273242;
      background: #1c2738;
      max-width: 56px;
      width: 100%;
      padding: 8px;
    }
    &__info {
      padding-block: 8px;
      padding-inline-start: 8px;
      color: #a7afbe;
      font-style: normal;
      font-weight: 400;
      width: 100%;
      span {
        font-size: 10px;
        line-height: 12px;
      }
      p {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

  .verification-popup {
    body.rtl-lang & {
      direction: rtl;
    }
  }
}

.is-animate-popup-content {
  animation: popupShowContent 0.8s linear;
  animation-delay: 0.05s;
  animation-fill-mode: backwards;
}

@keyframes popupShowContent {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  50% {
    opacity: 0.5;
    transform: translateY(2px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes popupShowWrap {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes popupShowWrapInside {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

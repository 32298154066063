$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.promo-item-sm {
  display: flex;
  height: 100%;

  &__bnr {
    width: 50%;
    flex-shrink: 0;
    background-size: cover;
    background-position: 50%;
    cursor: pointer;

    &::before {
      content: '';
      display: block;
      padding-top: 73.7%;
    }
  }

  &__panel {
    width: 50%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    padding: 24px 32px 32px;
  }

  &__offer {
    font-weight: bold;
    font-size: 42px;
    line-height: 49px;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: auto;
    cursor: pointer;

    span {
      &:empty {
        display: none;
      }
    }

    body.rtl-lang & {
      text-align: right;

      * {
        display: inline-block;
      }

      span {
        &:empty {
          display: none;
        }
      }
    }

    &.small-font {
      font-size: 32px;
    }
  }

  &__offer-title {
    font-size: 20px;
    line-height: 1.2;

    & ~ span {
      white-space: initial;
      font-size: 30px;
      line-height: 1.2;
      color: #ecfd00;

      @include s {
        font-size: 40px;
      }
    }
  }

  &__btns {
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px
  }

  &__link {
    display: block;
    font-size: 16px;
    line-height: 19px;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
  }

  &__social-btn {
    a {
      display: block;
    }
  }

  @include s {
    &__offer {
      font-size: 32px;
      line-height: 37px;

      &.small-font {
        font-size: 22px;
      }
    }
  }

  @include m {
    &__bnr {
      &::before {
        padding-top: 79.7%;
      }
    }

    &__pannel {
      padding: 25px 30px 32px;
    }

    &__offer {
      font-size: 42px;
      line-height: 49px;

      &.small-font {
        font-size: 32px;
      }
    }
  }
}

$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.profile-bonus {
  background: #293241;
  padding:20px 20px 32px;
  min-height: 100%  ;

  &.is-active {
    padding: 0;
    margin-top:10px;
    min-height: 284px;
    display: flex;
  }

  &__header {
    position:relative;
    border-top:solid 6px #fff;
    border-right:solid 6px #fff;
    padding:10px 20px 16px;
    text-align: center;
    min-height: 116px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &::before,
    &::after {
      content: '';
      display: block;
      position:absolute;
      background: #fff;
    }

    &::before {
      height: calc(100% - 65px);
      width: 6px;
      top: 0;
      left: 0;
    }

    &::after {
      height:6px;
      width:  calc(100% - 106px);
      right: 0;
      bottom: 0;
    }
  }

  &__active-header {
    min-height: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__offer{
    font-size: 24px;
    line-height: 30px;
    font-weight: bold;
    text-transform: uppercase;
    color: #FFFFFF;
    margin:auto 0;
  }
  &__name{
    font-size: 16px;
    line-height: 24px;
    color: #A7AFBE;
    min-height: 20px;
    & a{
      margin-inline-start:5px;
      color: $yellow;
      text-decoration: none;
    }

    body.rtl-lang & {
      &::before {
        content: 'الرهان';
        font-size: 0;
        line-height: 0;
        pointer-events: none;
      }
    }
  }

  &__table{
    margin-top:12px;
  }
  &__row{
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom:solid 1px #1E2737;
  }
  &__key{
    flex-shrink: 0;
    width: calc(50% - 5px);
    font-weight: bold;
    font-size: 14px;
    line-height: 120%;
    color: #A7AFBE;
  }
  &__val{
    flex-shrink: 0;
    width: calc(50% - 5px);
    font-weight: bold;
    font-size: 14px;
    line-height: 120%;
    color: #FFFFFF;
    text-align: end;

    display: flex;
    justify-content: flex-end;
    align-items: center;

    &.has-icon::before{
      content:'';
      display: inline-block;
      width: 10px;
      height: 10px;
      background-size: 10px;
      background-position: center;
      background-repeat: no-repeat;
      margin-right:3px;
    }
  }
  &__date {

  }
  &__icon{
    position:relative;
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-inline-end:4px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  &__btn{
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    & .link{ margin-top:20px; }
  }
  &__inner {
    > span {
      display: inline-block;

      &:last-child::before {
        content: 'الرهان';
        font-size: 0;
        line-height: 0;
        display: contents;
        pointer-events: none;
      }
    }
  }


  &__img{
    flex-grow: 1;
    background-color: $red;
    background-size: auto 100%;
    background-position: left center;
    background-repeat: no-repeat;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;



    &.is-sport{ background-image: url( $cdn + "/rabona/profile/pic-profile-activebonus.jpg"); }
    &.is-casino{ background-image: url( $cdn + "/rabona/elements/pattern-profile.svg"); }
  }
  &__content {
    width: 270px;
    flex-shrink: 0;
    background: #293241;
    padding: 10px 32px ;
  }

  &__btn-row {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 64px;
    background: rgba(188, 31, 57, 0.8);
    padding:8px 30px;
    & .link{
      margin-block: auto;
      margin-inline: 70px 0;
    }
  }

  &__big-name {
    margin-bottom: 10px;
    font-size: 48px;
    line-height: 120%;
    color: #FFFFFF;
    font-weight: bold;
    text-align: center;
    padding: 0 20px;
  }

  &__big-subname {
    margin-bottom: 44px;
    font-size: 24px;
    line-height: 120%;
    color: #FFFFFF;
    text-align: center;
    padding: 0 20px;
  }



  @include s {
    &__content {
      width: 391px;
    }
  }

  @include m {
    &__content {
      width: 463px;
    }
  }

}

.bonuse-line {
  display: inline-flex;
  gap: 3px;
  &__amount {
    order: 1;
    body.rtl-lang & {
      order: 0;
    }
  }
  &__currency {
    order: 0;
    body.rtl-lang & {
      order: 1;
    }
  }
  &__percentage {
    order: 2;
    body.rtl-lang & {
      order: 1;
    }
  }
  &__text {
    margin-inline-start: 4px;
    order: 2;
  }
}
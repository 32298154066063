$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.achievement-progress {
    display: flex;
    width: 100%;
    padding: 40px;
    margin-top: 40px;
    border: 1px solid #394557;
    gap: 16px;
    color: #FFF;

    body.rtl-lang & {
        direction: rtl;
    }

    &__title {
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;
    }

    &__price {
        body.rtl-lang & {
            display: flex;
            gap: 5px;
        }
    }
    
    &__currency {
        body.rtl-lang & {
            order: 1;
        }
    }

    &__icon {
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        &.is-yellow {
            width: 28px;
            height: 28px;
            color: #ecfd00;
            font-size: 20px;
            border: 2px solid #ECFD00;

            body.rtl-lang & {
                order: 1;
            }
        }

        &.is-white {
            width: 20px;
            height: 20px;
            color: #fff;
            font-size: 14px;
            border: 1px solid #fff;

            body.rtl-lang & {
                order: 1;
            }
        }
    }

    &__current {
        color: #ECFD00;
        font-size: 28px;
        font-weight: 500;
    }
    &__left {
        display: flex;
        align-items: center;

        gap: 16px;
    }
    &__text {
        display: flex;
        align-items: center;

        gap: 4px;
    }
    &__right {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }
    &__top {
        display: flex;
        align-items: center;
        font-size: 20px;
        font-weight: 400;
        column-gap: 4px;
    }
    &__bottom {
        display: flex;
        align-items: center;
        margin-top: 8px;

        font-size: 20px;
        font-weight: 500;
    }
    &__line {
        flex-grow: 1;
        height: 2px;
        margin-inline-end: 9px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        body.rtl-lang & {
            scale: -1 1;
        }

        &::before {
            content: "";
            width: 7px;
            height: 7px;
            transform: rotate(-45deg);
            background-color: #ECFD00;

            body.rtl-lang & {
                background-color: #576477;
            }
        }

        &::after {
            content: "";
            width: 7px;
            height: 7px;
            transform: rotate(-45deg);
            background-color: #576477;

            body.rtl-lang & {
                background-color: #ECFD00;
            }
        }
    }
    &__migration {
        font-size: 20px;
        font-weight: 400;
        text-align: center;

        margin: auto;
        align-self: center;
    }
    &__btn {
        flex-shrink: 0;
        margin-left: 16px;
        b {
            margin: 0 3px;
        }
    }
}

.achievement-progress__bottom.is-complete {
    .achievement-progress__line::after {
        background-color: $yellow;
    }
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.santa-banner {
  position: relative;
  display: flex;
  margin-top: 24px;
  cursor: pointer;

  body.rtl-lang & {
    direction: rtl;
  }

  &__bg {
    position: absolute;
    inset: 0;
    background-position: calc(50% + 250px) 100%;
    background-size: auto 100%;
    background-repeat: no-repeat;

    @include s {
      background-position: 230px 100%;
    }

    @media (min-width: 1680px) {
      background-position: 310px 100%;
    }

    body.rtl-lang & {
      scale: -1 1;
    }
  }

  &__content {
    position: relative;
    min-width: 310px;
    padding-block: 13px;
    padding-inline-start: 30px;
    background-color: #CC0034;
    font-size: 20px;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      inset-inline-start: 100%;
      width: 176px;
      background-image: linear-gradient(to right, #CC0034, transparent);
      background-repeat: no-repeat;

      body.rtl-lang & {
        background-image: linear-gradient(to left, #CC0034, transparent);
      }
    }
  }

  &__title {
    line-height: 1.2;
  }

  &__description {
    display: flex;
    column-gap: 8px;
    align-items: flex-end;
  }

  &__value {
    font-size: 30px;
    color: #ECFD00;
    line-height: 1;
  }

  &__button {
    margin-top: 12px;
    font-weight: 400;
    line-height: 0;

    > .btn {
      min-width: 180px;
    }
  }
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.board {
    display: flex;
    flex-direction: column;
    padding-top: 25px;
    border-radius: 2px;
    background-color: $blueDark;

    &__title {
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: $wh;
        text-transform: uppercase;
        margin-bottom: 9px;

        body.rtl-lang & {
            text-align: center;
        }
    }

    &__table-container {
        max-height: 532px;
        overflow-y: scroll;

        &.no-scroll {
            overflow: hidden;
        }
    }

    &__table {
        font-size: 16px;
        line-height: 24px;
        color: $blueGray;

        width: 100%;
        max-width: 100%;
    }
    &__row {
        height: 48px;
        padding-top: 11px;
        padding-bottom: 9px;
        border-top: 4px solid $blueDark;
        border-bottom: 4px solid $blueDark;
        text-align: center;
        &.is-sport {
            border-top: 4px solid $blue2;
            border-bottom: 4px solid $blue2;
        }
        &.is-current-user {
            background-color: $red1 !important;
            color: $yellow;
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
                .board__place {
                    color: $yellow;
                    background-image: url($cdn + '/rabona/tournament/tournament-item-win-icon.svg');
                }
            }
        }

        &_head {
            font-weight: 700;
            text-transform: uppercase;
            & td {
                padding: 0 8px;
            }
        }
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
            .board__place {
                background-image: url($cdn + '/rabona/tournament/tournament-item-icon.svg');
                background-repeat: no-repeat;
                background-size: 33px auto;
                background-position: center top 10px;

                font-size: 14px;
                font-weight: bold;
                color: $wh;
            }
        }
        &:nth-child(2){
            background-color: $blueLight;
        }
        &:nth-child(3) {
            background-color: $blueMenu;
        }
        &:nth-child(4) {
            background-color: $blueMenu;
        }
    }
    &__place {
        width: 60px;
        text-align: center;
        body.rtl-lang & {
            text-align: center;
        }
    }
    &__prize {
        font-weight: 700;
    }

    &__name {
        direction: ltr !important;
    }
}
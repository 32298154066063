$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.tournament {
    $this: &;
    
    --date-text: #{$wh};
    --date-bg: #{$red};
    --label-bg: #{$yellow};
    --label-text: #{$blueDark};
    --info-text: #{$yellow};
    --bg: #{$red};

    position: relative;
    z-index: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 660px;
    
    //future
    &_status-0 {
        --date-text: #{$yellow};
        --label-text: #{$wh};
        --label-bg: #{$red};
        --bg: #{$blueLight};

        #{$this}__cup {background-image: none}
    }
    //past
    &_status-2 {
        --date-text: #{$blueGray};
        --date-bg: #{$blueLight};
        --info-text: #{$blueGray};
        --bg: #{$blueLight}; 

        #{$this}__label {display: none}
        #{$this}__bet {
            background-image: var(--icon-min-bet);
        }
    }

    &__bg {
        position: absolute;
        z-index: -1;
        top: 4px;
        right: 0;
        left: 0px;
        bottom: 0;

        background-size: 324px auto; 
        background-position: top center;
        background-repeat: no-repeat;

        body.rtl-lang & {
            direction: ltr;
        }
    }

    &__character {
        margin-top: 5px;
        width: 100%;
        max-width: 300px;
        height: auto;
    }

    &__date {
        --side: 13px;

        font-size: 14px;
        font-weight: 700;
        line-height: 26px;
        text-align: center;
        color: var(--date-text);
        @include ellipsis;

        padding: 0 20px;
        min-width: 210px;

        filter: drop-shadow(0px 4px 0px rgba(0, 0, 0, 0.25));

        &::after {
            content: '';

            position: absolute;
            z-index: -1;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;

            background-color: var(--date-bg);
            clip-path: polygon(0 0, 100% 0, calc(100% - var(--side)) 100%, var(--side) 100%);
        }

        strong {
            display: inline-block;
            margin-inline-start: 2px;
        }
    }

    &__info {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding-top: 9px;
        padding-bottom: 22px;

        &::before {
            content: '';
            z-index: -1;
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            top: 0;

            background-color: var(--bg);
            clip-path: polygon(0 33.9%, 100% 0, 100% 100%, 0 100%);
        }
    }

    &__cup {
        width: 116px;
        height: 90px;

        background-position: center;
        background-repeat: no-repeat;
        background-position: center top 14px, center;
        background-image: 
            url($cdn + '/rabona/tournament/tournament-cup.svg'),
            url($cdn + '/rabona/tournament/tournament-item-icon.svg');
        
    }

    &__title {
        font-size: 26px;
        font-weight: 700;
        line-height: 33px;
        text-align: center;
        text-transform: uppercase;
        color: $wh;

        height: 66px;
        width: 100%;
        padding: 0 8px;
        margin-top: 35px;
        overflow: hidden;

        white-space: break-spaces;
    }

    &__prize {
        font-size: 34px;
        font-weight: 700;
        line-height: 40px;
        text-transform: uppercase;
        color: var(--info-text);

        margin-top: 3px;
    }

    &__bet {
        font-size: 16px;
        font-weight: 700;
        line-height: 28px;
        color: var(--info-text);

        padding-inline-start: 31px;
        margin-top: 5px;

        background-image: var(--icon-min-bet-hover);
        background-position: left top;
        background-size: 28px;
        background-repeat: no-repeat;
        display: flex;

        body.rtl-lang & {
            background-position: right top;
        }
    }

    &__link,
    &__anchor {
        width: 100%;
        margin-top: 13px;
        padding-left: 24px;
        padding-right: 24px;
    }

    // anchor show on tournament page
    // link by default
    &__anchor {
        display: none;
    }
    .tournament-page &__anchor{
        display: inline-flex;
    }
    .tournament-page &__link{
        display: none;
    }

    &__label {
        position: absolute;
        left: -7px;
        top: 33.9%;
        transform: translateY(-50%);
    }

    .tournament-page &::after{
        content: '';

        position: absolute;
        bottom: 0;
        transform: translateY(100%);
        
        width: 100%;
        height: 127px;
        
        clip-path: polygon(0 0, 100% 0, 100% 5px, 0 100%);
        background-color: var(--bg);
    }

    .is-euro &{
        &__bg{
            background-size: cover;
            overflow: hidden;
        }
        &__character {
            max-width: none;
            height: 374px;
            object-fit: cover;
            margin-top: 30px;
        }
    }
}

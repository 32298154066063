$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.tournament-euro {
  display: grid;
  grid-template-columns: auto 324px;
  grid-template-rows: 376px 1fr;
  gap: 12px 12px;
  position: relative;
  width: 100%;
  max-width: 100%;
  margin-bottom: 12px;
  @include s {
    grid-template-columns: auto 420px;
  }

  &-banner {
    position: relative;
    &__inner {
      min-height: inherit;
      height: 100%;
      width: 100%;
      display: flex;
      position: relative;
      background-position: left 185px top -140px, top left;
      background-repeat: no-repeat, no-repeat;
      background-size: auto 542px, cover;
      overflow: hidden;
      @include s {
        background-position: left 395px top -170px, top left;
        background-size: auto 647px, cover;
      }
      @include m {
        background-position: left 535px top -150px, top left;
        background-size: auto 647px, cover;
      }
      body.rtl-lang & {
        justify-content: flex-end;
        background-position: right 265px top -140px, top left;
        @include s {
          background-position: right 335px top -170px, top left;
        }
        @include m {
          background-position: right 435px top -150px, top left;

        }
      }
    }
    &__info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;
      .tournament-sport__label,
      .tournament-sport__title,
      .tournament-sport__prize {
        margin-bottom: 0;
      }
      .tournament-sport__prize{
        font-size: 54px;
      }
    }
    &__left {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 408px;
      padding: 36px 118px 36px 30px;
      clip-path: polygon(0 0, 100% 0, 320px 100%, 0% 100%);
      background-color: #bc1f39;
      color: $wh;
      gap: 24px;
      @include s {
        width: 480px;
        clip-path: polygon(0 0, 100% 0, 392px 100%, 0% 100%);
      }
      .btn {
        min-width: 224px;
      }
      body.rtl-lang & {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 88px 100%);
        padding: 36px 30px 36px 118px;
        
      }
    }
    &__hero {
      position: absolute;
      bottom: -26px;
      left: 277px;
      width: 542px;
      height: 409px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top left -80px;
      @include s {
        left: 390px;
        bottom: -12px;
      }
      @include m {
        left: 440px;
        bottom: -12px;
      }
      body.rtl-lang & {
        left: inherit;
        right: 257px;
        @include s {
          right: 307px;
        }
        @include m {
          right: 357px;
        }
      }
    }
    .tournament-sport__link{
        display: block !important;
    }
  }
  &-timer {
    background: #162334;
    padding: 18px;
    position: relative;
    .tournament-info__cards {
      display: flex;
      flex-direction: column;
      gap: 4px;
      width: 100% !important;
    }
    .tournament-sport-info__counter {
      margin-bottom: 19px;
    }
  }
  &-info {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    max-width: 100%;
    min-width: 0;
    &__events {
      .entrance-sport {
        margin-bottom: 0;
      }
    }
    &__daily {
      body.rtl-lang & {
        .entrance-sport__header{
          flex-direction: row-reverse;
        }
      }
    }
    &__howtowin {
      background: #162334;
      padding: 20px 12px 12px;

      .tournament-sport-info__howto-content {
        grid-template-columns: repeat(2, 1fr);
        gap: 8px;
        margin-bottom: 0;

        body.rtl-lang & {
          direction: rtl;
        }

        @include s {
          grid-template-columns: 1fr 1fr 1fr 1fr;
        }
      }
      .tournament-sport-info__howto-item {
        margin-bottom: 0;
        background-color: #0b1222;
        padding: 6px;
      }
      .tournament-sport-info__howto-ico {
        background-color: #162334;
        body.rtl-lang & {
          margin-right: 0;
          margin-left: 16px;
        }
      }
      .tournament-sport-info__howto-text {
        font-size: 14px;
        line-height: 120%;
      }
    }
    &__terms {
      background: #162334;
      padding: 24px 20px 20px;
      scroll-margin: 100px;
      &__content {
        color: #a5afc0;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        height: 100px;
        overflow: hidden;
        body.rtl-lang & {
          text-align: right;
        }
        &.is-open {
          height: inherit;
          & + .missed-data-dropdown {
            .missed-data-dropdown__text_more {
              display: none;
            }
            .missed-data-dropdown__text_less {
              display: block;
            }
            &::after {
              transform: rotate(180deg);
            }
          }
        }
        p,
        ul {
          margin-bottom: 6px;
        }

        ul {
          list-style: inside;
        }
        body.rtl-lang & {
          text-align: right;
          p:has(span){
            display: flex;
            flex-direction: row-reverse;
            gap: 4px;
          }
          li{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 8px;
            &::after{
              width: 4px;
              height: 4px;
              background-color: #a5afc0;
              content: '';
              border-radius: 2px;
            }
          }
        }
      }
      .missed-data-dropdown {
        color: #ecfd00;
        text-decoration: underline;
        &::after {
          content: none;
        }
      }
    }
  }
  &-leaderboard {
    background: #162334;
    position: relative;
  }

  &-promo {
    width: 100%;
    margin-top: 12px;
    &__inner {
      width: 100%;
    }
    &__item {
      display: flex;
      height: 197px;
      width: 100%;
      @include s {
        height: 245px;
      }
    }
    &__img {
      background-size: cover;
      width: 50%;
      .is-disable & {
        filter: grayscale(100%);
      }
    }

    &__content {
      background-color: #cc0034;
      width: 50%;
      padding: 20px;
      display: flex;
      flex-direction: column;
      padding-right: 64px;
      .is-disable & {
        background-color: #162334;
      }
      body.rtl-lang & {
        text-align: right;
      }
      @include s {
        padding: 24px 72px 32px 32px;
      }
    }
    &__status {
      margin-bottom: 18px;
      background-color: #576477;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 19px;
      width: max-content;
      clip-path: polygon(11px 0, 100% 0, calc(100% - 11px) 100%, 0% 100%);
      span {
        color: #fff;
        text-align: center;
        font-size: 14px;
        font-style: italic;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
      }
    }
    &__title {
      color: #fff;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      margin-bottom: 8px;
      .is-disable & {
        color: #576477;
      }
    }
    &__offer {
      color: #ecfd00;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      text-transform: uppercase;
      .is-disable & {
        color: #576477;
      }
      @include s {
        font-size: 40px;
      }
    }

    &__btns {
      margin-top: auto;
      gap: 42px;
      body.rtl-lang & {
        flex-direction: row-reverse;
        flex-wrap: nowrap;
      }
      .btn {
        min-width: auto;
        body.rtl-lang & {
          font-size: 14px;
        }
      }
    }

    .slick-arrow {
      position: absolute;
      top: -2px;
      z-index: 1;
      width: 44px;
      height: 100%;
      font-size: 0;
      padding: 0;
      margin: 0;
      border: none;
      &::before {
        content: '';
        width: 32px;
        height: 100%;
        background: #1c2738;
        border-radius: 2px;
        border: 1px solid #273242;
        position: absolute;
        top: 0;
      }
      &::after {
        content: '';
        width: 32px;
        height: 100%;
        position: absolute;
        top: 0;
        background-size: 20px;
        background-position: center;
        background-repeat: no-repeat;
        filter: brightness(0) saturate(100%) invert(98%) sepia(77%) saturate(1419%) hue-rotate(177deg) brightness(80%)
          contrast(84%);
      }
    }

    .slick-prev {
      left: 0;
      background: linear-gradient(90deg, #0c1221 71.49%, rgba(12, 18, 33, 0) 100%);
      &::before {
        left: 0;
      }
      &::after {
        left: 0;
        background-image: var(--icon-arr-l);
      }
    }

    .slick-next {
      right: 0;
      background: linear-gradient(270deg, #0c1221 71.49%, rgba(12, 18, 33, 0) 100%);
      &::before {
        right: 0;
      }
      &::after {
        right: 0;
        background-image: var(--icon-arr-r);
      }
    }
  }
}

$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.preloader {
    --color: #{$wh};

    position: relative;
    pointer-events: none;

    &_dark {
        --color: #{$blue};
    }

    > * {
        opacity: .4;
    }

    &::after {
        content: '';

        position: absolute;
        z-index: 50;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: inline-block;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        box-shadow:
            inset 0 0 8px 0 var(--color),
            0 0 0 6px var(--color),
            0 0 6px 6px var(--color);
        animation: rise 1.5s linear infinite;
    }

    &::before {
        content: '';

        position: absolute;
        z-index: 50;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: inline-block;
        width: 42px;
        height: 42px;
        border-radius: 100%;
        box-shadow:
            0 0 6px 0 var(--color),
            inset 0 0 0 6px var(--color),
            inset 0 0 8px 6px var(--color);
        animation: fall 1.5s linear infinite;
    }

    &_filled {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: var(--header-h);
        background-color: #0c1221;
        z-index: 50;
    }
}

@keyframes rise {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 0;
    }

    10.00001% {
        box-shadow:
            inset 0 0 0 0 var(--color),
            0 0 0 0 var(--color),
            0 0 0 0 var(--color);
        opacity: 1;
    }

    50% {
        box-shadow:
            inset 0 0 8px 0 var(--color),
            0 0 0 6px var(--color),
            0 0 8px 6px var(--color);
        opacity: 1;
    }

    50.00001% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fall {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    50.0001% {
        opacity: 1;
        box-shadow:
            0 0 8px 0 var(--color),
            inset 0 0 0 6px var(--color),
            inset 0 0 8px 6px var(--color);
    }

    90% {
        box-shadow:
            0 0 0 0 var(--color),
            inset 0 0 0 0 var(--color),
            inset 0 0 0 0 var(--color);
        opacity: 1;
    }

    90.0001% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

// .preloader {
//     position: relative;
//     pointer-events: none;

//     &::after {
//         content: '';

//         position: absolute;
//         z-index: 50;
//         left: 50%;
//         top: 50%;
//         transform: translate(-50%, -50%);
//         display: inline-block;
//         width: 40px;
//         height: 40px;
//         border-radius: 100%;
//         box-shadow:
//             inset 0 0 10px 0 var(--color),
//             0 0 0 10px var(--color),
//             0 0 10px 10px var(--color);
//         animation: rise 2s linear infinite;
//     }

//     &::before {
//         content: ''; 

//         position: absolute;
//         z-index: 50;
//         left: 50%;
//         top: 50%;
//         transform: translate(-50%, -50%);
//         display: inline-block;
//         width: 60px;
//         height: 60px;
//         border-radius: 100%;
//         box-shadow:
//             0 0 10px 0 var(--color),
//             inset 0 0 0 10px var(--color),
//             inset 0 0 10px 10px var(--color);
//         animation: fall 2s linear infinite;
//     }
// }

// @keyframes rise {
//     0% {
//         opacity: 0;
//     }

//     10% {
//         opacity: 0;
//     }

//     10.00001% {
//         box-shadow:
//             inset 0 0 0 0 var(--color),
//             0 0 0 0 var(--color),
//             0 0 0 0 var(--color);
//         opacity: 1;
//     }

//     50% {
//         box-shadow:
//             inset 0 0 10px 0 var(--color),
//             0 0 0 10px var(--color),
//             0 0 10px 10px var(--color);
//         opacity: 1;
//     }

//     50.00001% {
//         opacity: 0;
//     }

//     100% {
//         opacity: 0;
//     }
// }

// @keyframes fall {
//     0% {
//         opacity: 0;
//     }

//     50% {
//         opacity: 0;
//     }

//     50.0001% {
//         opacity: 1;
//         box-shadow:
//             0 0 10px 0 var(--color),
//             inset 0 0 0 10px var(--color),
//             inset 0 0 10px 10px var(--color);
//     }

//     90% {
//         box-shadow:
//             0 0 0 0 var(--color),
//             inset 0 0 0 0 var(--color),
//             inset 0 0 0 0 var(--color);
//         opacity: 1;
//     }

//     90.0001% {
//         opacity: 0;
//     }

//     100% {
//         opacity: 0;
//     }
// }




// .preloader {
//     position: relative;
//     pointer-events: none;

//     &::after {
//         content: '';

//         position: absolute;
//         z-index: 50;
//         left: 50%;
//         top: 50%;
//         transform: translate(-50%, -50%);
//         display: inline-block;
//         width: 30px;
//         height: 30px;
//         border-radius: 100%;
//         box-shadow:
//            inset 0 0 8px 0 var(--color),
//            0 0 0 6px var(--color),
//            0 0 6px 6px var(--color);
//         animation: rise 2s linear infinite;
//     }

//     &::before {
//         content: '';

//         position: absolute;
//         z-index: 50;
//         left: 50%;
//         top: 50%;
//         transform: translate(-50%, -50%);
//         display: inline-block;
//         width: 42px;
//         height: 42px;
//         border-radius: 100%;
//         box-shadow:
//             0 0 6px 0 var(--color),
//             inset 0 0 0 6px var(--color),
//             inset 0 0 8px 6px var(--color);
//         animation: fall 2s linear infinite;
//     }
// }

// @keyframes rise {
//     0% {
//         opacity: 0;
//     }

//     10% {
//         opacity: 0;
//     }

//     10.00001% {
//         box-shadow:
//             inset 0 0 0 0 var(--color),
//             0 0 0 0 var(--color),
//             0 0 0 0 var(--color);
//         opacity: 1;
//     }

//     50% {
//         box-shadow:
//             inset 0 0 8px 0 var(--color),
//             0 0 0 6px var(--color),
//             0 0 6px 6px var(--color);
//         opacity: 1;
//     }

//     50.00001% {
//         opacity: 0;
//     }

//     100% {
//         opacity: 0;
//     }
// }

// @keyframes fall {
//     0% {
//         opacity: 0;
//     }

//     50% {
//         opacity: 0;
//     }

//     50.0001% {
//         opacity: 1;
//         box-shadow:
//              0 0 6px 0 var(--color),
//              inset 0 0 0 6px var(--color),
//              inset 0 0 8px 6px var(--color);
//     }

//     90% {
//         box-shadow:
//             0 0 0 0 var(--color),
//             inset 0 0 0 0 var(--color),
//             inset 0 0 0 0 var(--color);
//         opacity: 1;
//     }

//     90.0001% {
//         opacity: 0;
//     }

//     100% {
//         opacity: 0;
//     }
// }
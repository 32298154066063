$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.entrance{
  border:solid 1px red;
  padding:0 24px;
  max-width: 1920px;
  margin:0 auto;
  @include s{
    padding:0 40px;
  }

  &__seo{
    margin-top: 56px;
    .info_article{
      margin-top: 0;
    }
  }
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.registration{

  &__progress{
    margin-top:20px;
    display: flex;
    justify-content: space-between;
    margin-bottom:20px;
    &::before,
    &::after{
      content: '';
      display: block;
      width: calc( 50% - 2px);
      height: 4px;
      background: #2D394C;
      transform: skew(-20deg);
    }
    &::before{ background: $red;}
    &.is-step-2::after { background: $red; }
  }


  &__step{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }
  &__item{
    width: 100% ;
    align-self: flex-start;
    .form-box {
      margin: 0;
    }
  }
  &__btn{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  &__link{
    margin-top: 60px;
    width: calc(100% - 250px);
    margin-right:8px;
  }
  &__btn2{
    margin-top: 60px;
    width: 208px;
  }

  &__date{
    display: flex;
    flex-wrap: wrap;
    min-height: 56px;
    gap: 14px;
  }
  &__date-item{
    width: calc(100%/3 - 14px/3*2);
  }
  &__date-error {
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    padding-left: 7px;
    & p{
      width: 100%;
      padding-top:8px;
      color: $pink;
      font-size: 12px;
      line-height: 15px;
    }
  }
  &__gender{
    display: flex;
    align-items: center;
    & .form-name{
      flex-grow: 1;
      flex-shrink: 0;
      margin-bottom:0;
    }
    &_pnp {
      flex-wrap: wrap;
      margin-top:0;

      & .form-name {
        width: 100%;
        margin-bottom: 8px;
      }
    }
  }
  &__gender-i{
    display: flex;
    justify-content: center;
    flex-grow: 1;
    flex-shrink: 0;
    min-height: 46px;

    &_pnp {
      height: 56px;
      padding-top:16px;
    }
  }

  @include s{
    &__progress{ margin-top:13px; }
    &__btn{ margin-top: 72px; }
    &__btn{ width: 288px}
    &__link,
    &__btn2{ width: calc(50% - 24px) ; margin:60px 12px 0 ; text-align: center; }
    &__item.is-50{ width: calc(50% - 24px) ; }

    &__gender{
      flex-wrap: wrap;
      margin-top:0;
      & .form-name{
       width: 100%;
        margin-bottom:8px;
      }

    }

  }
  @include m{
    &__btn{ width: 304px}
  }
}

.registration__step.ng-invalid .btn {
  --mainColor: #A5AFC0;
  pointer-events: none;
}

.registration__step.ng-hide { opacity: 0; }


.registration__step.ng-hide-add{
  animation: registrationStepHide 0.6s linear ;
}
.registration__step.ng-hide-remove {
  animation: registrationStepShow 0.6s linear ;
}


@keyframes registrationStepShow {
  0%  {  opacity:0; max-height: auto;}
  100%{  opacity:1; max-height: auto;}

}
@keyframes registrationStepHide {
  0%{  opacity:0; max-height: 0;}
  100%{  opacity:0; max-height: 0;}
}


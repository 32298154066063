$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.bankid-icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    margin-bottom: 30px;

    &.is-bnr {
        width: 400px;
        margin: 22px auto 0px;

        & .bankid-icons__item {
            width: 119px;
            height: 32px;
            margin: 7px;

            & img {
                width: 100%;
            }
        }
    }

    &__item {
        width: 86px;
        height: 23px;
        object-fit: contain;
        object-position: center;
        margin: 5px;
    }
}
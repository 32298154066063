$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.btn-back {
    display: flex;
    align-items: center;
    height: 39px;
    padding-inline: 8px 16px;

    font-size: 14px;
    line-height: 14px;
    text-transform: uppercase;
    color: $wh;

    transition: var(--animation-duration) background-color;
    background-color: rgba($wh, .15);
    border-radius: 2px;
    border: none;
    box-shadow: none;

    cursor: pointer;

    &:hover {
        background-color: rgba($wh, 0);
    }

    &::before {
        content: '';

        width: 20px;
        height: 19px;
        margin-inline-end: 4px;

        background-image: var(--icon-i-arrow);
        background-position: center;
        background-size: 35px;

        transform: rotate(90deg);
        filter: $whFilter; 
    }

    body.rtl-lang & {
        &::before{
            transform: rotate(-90deg);
        }
    }
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.cup-paralax{

  width: 100%;
  position:relative;
  height: 814px;
  overflow: hidden;

  &::before{
    content: '';
    position:absolute;
    top: 0;
    left: calc( 50% - 400px);
    width: 800px;
    height: 90px;
    z-index: 30;

    background-image: url( $cdn + '/rabona/cup-page/m_bg-light.svg');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: contain;
    pointer-events: none;
  }

  &__wrap{
    position:absolute;
    top: 0;
    left: calc( 50% - 1049px);
    width: 2098px;
    height: 814px;
    text-align: center;

    z-index: 10;

  }
  &__bg{
    position:absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-image: url( $cdn + '/rabona/cup-page/bg-img.jpg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  &__ark{
    position:absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    background-image: url( $cdn + '/rabona/cup-page/bg-arka_l.png') , url( $cdn + '/rabona/cup-page/bg-arka_r.png');
    background-repeat: no-repeat;
    background-position: left top , right top;
    background-size: contain;
  }


}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.card-bnr {
  position:relative;
  height: 390px;
  margin-top: -15px;
  background-size: cover;
  background-position: center bottom;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 4px solid $red;

  body.rtl-lang & {
    direction: rtl;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 71px;
    left: 0;
    bottom: 0;
    z-index: 10;
    background: linear-gradient( rgba(0,0,0,0), rgba(0, 0, 0, 0.6) );
  }

  &__title {
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 43px 0 15px;
    width: 540px;
    position: absolute;
    top: 220px;

    body.rtl-lang & {
      text-align: center;
    }
  }

  &__img {
    margin-top: auto;
    width: 857px;
    height: 307px;
    background: rgba(0,0,0,0.2);
  }

  &__back {
    position: absolute;
    display: flex;
    align-items: center;
    height: 40px;
    top: 30px;
    inset-inline-start: 24px;
    background: rgba(255,255,255,0.1);
    line-height: 40px;
    padding-inline-start: 14px;
    padding-inline-end: 17px;
    color:#fff;
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
    border-radius: 2px;
    transition: 0.2s;

    &::before{
      content: '';
      display: inline-block;
      width: 10px;
      height: 17px;
      background-size: 17px;
      filter: $whFilter;
      margin-inline-end: 8px;

      body.rtl-lang & {
        scale: -1 1;
      }
    }

    &:hover{background: none;}
  }

  &__paral {
    position: absolute;
    width: 110px;
    height: 222px;
    top: 85px;

    &::before {
      content: '';
      display: block;
      background: #CC0034;
      width: 100%;
      height: calc(100% - 40px);
      margin-top: 20px;
      transform: skewY(-20deg) rotate(0deg) scale(1);
      opacity:1;
      animation: cardParal 0.4s linear ;
      animation-fill-mode:both;
    }

    &.is-l {
      inset-inline-end: calc(50% + 363px);

      &::before{ animation-delay: 0.2s;}
    }

    &.is-r {
      inset-inline-start: calc(50% + 363px);
      &::before { animation-delay: 0.6s;}

    }

  }
  &__paral-text{
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color:#fff;
    animation: cardShowText 0.2s linear ;
    animation-fill-mode:both;
    animation-delay: 0.4s;
  }

  &__sum{
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 10px;

    body.rtl-lang & {
      text-align: center;
    }
  }

  &__description{
    white-space: break-spaces;
  }

  &__link {
    @include text(10px, 16.4px, 400, $yellow);
    cursor: pointer;
    position: relative;
    padding-inline-start: 25px;
    align-self: flex-start;
    text-align: left;
    margin-top: 10px;

    &::before {
      content: '';
      background-image: var( --icon-i-info);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      filter: brightness(0) $yellowFilter;
      width: 14px;
      height: 14px;
      position: absolute;
      inset-inline-start: 6px;
      top: 1px;
    }
  }

  &__logo {
    position:absolute;
    top: 53px;
    inset-inline-start: calc(50% - 34px);
    width: 68px;
    height: 68px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;

    opacity:1;
    animation: cardShowlogo 0.2s linear ;
    animation-fill-mode:both;
    animation-delay: 0.8s;
  }

  &__points {
    position: absolute;
    top: 145px;
    inset-inline-start: 2px;
    transform: skew(-24deg) rotate(-23.8deg);
    background: $yellow;
    height: 20px;
    width: 100px;
    box-shadow: 2px 2px 0px 0 rgba(12, 18, 33, 0.2);
    opacity:1;
    animation: cardShowPoints 0.3s cubic-bezier(0.52, 0.81, 0.81, 1.3);
    animation-fill-mode:both;
    animation-delay: 1s;

    &::before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      bottom: -4px;
      left: 0px;
      border-top: solid 1px transparent;
      border-bottom: solid 3px transparent;
      border-right: solid 3px #73791C;
    }
  }

  &__points-text {
    position:absolute;
    top: 145px;
    inset-inline-start: -2px;
    height: 20px;
    width: 110px;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    color: #0C1221;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    transform: rotate(-20deg);

    animation: cardShowPointsText 0.3s cubic-bezier(0.52, 0.81, 0.81, 1.3);
    animation-fill-mode:both;
    animation-delay: 1.2s;

    & strong{ color:$red; margin:0 4px;}
  }

  @include s{
    height: 450px;

    &__back { inset-inline-start: 40px;}
    &__title {
      font-size: 26px;
      line-height: 30px;
      top: 264px;
    }

    &__img {
      width: 998px;
      height: 358px;
    }

    &__paral {
      width: 128px;
      height: 259px;

      &.is-l { inset-inline-end:calc(50% + 510px); }
      &.is-r { inset-inline-start:calc(50% + 510px);  }
    }

    &__paral-text {
      font-size: 14px;
      line-height: 16px;
    }

    &__sum {
      font-size: 42px;
      line-height: 49px;
    }

    &__link {
      inset-inline-start: -3px;
      align-self: center;
      font-size: 14px;
      margin-top: 15px;
    }

    &__logo {
      width: 80px;
      height: 80px;
      top: 62px;
      inset-inline-start: calc(50% - 40px);
    }

    &__points {
      top: 165px;
      inset-inline-start: -2px;
      height: 30px;
      width: 115px;

      &::before{
        border-top: solid 0px transparent;
        border-bottom: solid 7px transparent;
        border-right: solid 6px #73791C;
        bottom: -7px;
      }
    }

    &__points-text {
      top: 167px;
      inset-inline-start: -2px;
      height: 26px;
      width: 120px;
      line-height: 24px;
    }
  }

  @include m {
    height: 578px;
    background-position: center center;

    &__title{
      font-size: 34px;
      line-height: 40px;
      padding:30px 0 20px;
      top: 364px;
    }

    &__img{
      width: 1352px;
      height: 485px;
    }

    &__paral{
      width: 174px;
      height: 351px;

      &::before{
        height: calc(100% - 60px);
        margin-top: 30px;
      }

      &.is-l { inset-inline-end: calc(50% + 700px); }
      &.is-r { inset-inline-start: calc(50% + 700px);  }
    }

    &__paral-text {
      font-size: 20px;
      line-height: 23px;
    }

    &__sum {
      font-size: 56px;
      line-height: 66px;
    }

    &__link {
      inset-inline-start: 0;
      margin-top: 17px;

      &::before {
        width: 17px;
        height: 17px;
        top: -1px;
        inset-inline-start: 2px;
      }
    }

    &__logo {
      width: 107px;
      height: 107px;
      top: 83px;
      inset-inline-start: calc(50% - 53px);
    }

    &__points {
      top: 225px;
      inset-inline-start: 0px;
      height: 32px;
      width: 154px;
    }

    &__points-text {
      top: 228px;
      inset-inline-start: -2px;
      width: 160px;
      font-size: 16px;
    }
  }
}


@keyframes cardParal {
  0%{ transform: skewY(-20deg) rotate(40deg) scale(0.1); opacity:0; }
}
@keyframes cardShowText {
  0%{ transform: translateY(-40px); opacity:0; }
}
@keyframes cardShowlogo {
  0%{  opacity:0; }
}

@keyframes cardShowPoints {
  0%{ transform: skew(-24deg) rotate(-23.8deg) translateX( -25px ); opacity:0;  }
}
@keyframes cardShowPointsText {
  0%{ transform: rotate(-20deg) translateX( -25px ); opacity:0;  }
}


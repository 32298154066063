$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.entrance-sport {
  position: relative;
  z-index: 8;
  display: block;
  margin-bottom: -28px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    color: #fff;

    body.rtl-lang & {
      direction: rtl;
    }
  }
  &__title {
    font-size: 20px;
    line-height: 23px;
    text-transform: uppercase;
  }
  &__link {
    --btnHeight: 32px;
    --mainColor: #273242;
    --secondaryColor: #162334;
    border: 1px solid #2A394E;
    border-radius: 2px;

    &.btn {
      font-size: 14px;
      min-width: unset;
      color: #A5AFC0;
    }
  }

  .entrance-category + & {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.challenge-tooltip {
    &__ach-tooltip {
        position: absolute;
        width: 524px;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        padding-top: 18px;
        overflow: hidden;
    }
    &__ach-tooltip-wrap {
        display: flex;
        background-color: $red;
        position: relative;
        padding: 10px;
        &:before {
            content: "";
            position: absolute;
            width: 16px;
            height: 16px;
            top: -8px;
            left: 50%;
            transform: translateX(-50%) rotate(45deg);
            background-color: $red;
        }
        &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            background-image: url($cdn + "/rabona/elements/pattern-collections.svg");
            background-repeat: no-repeat;
            background-size: calc(100% - 100px);
            background-position: left calc(100% + 80px);
            width: 100%;
            height: 100%;
            z-index: 0;
            transform: scaleX(-1);
        }
    }
    &__ach-info {
        flex: 1 1 auto;
        padding: 10px 20px 10px 10px;
        z-index: 10;
    }
    &__ach-title {
        color: $yellow;
        font-size: 24px;
        .small-font & {
          font-size: 20px;
        }
    }
    &__ach-description {
        color: $wh;
        font-size: 16px;
        margin-bottom: 20px;
        white-space: break-spaces;
        .small-font & {
          font-size: 14px;
        }
    }
    &__ach-btn {
        button {
            max-width: 120px;
            min-width: 120px;
        }
    }
    &__ach-img {
        flex: 0 0 145px;
        z-index: 10;
        background-color: #1C2738;
        position: relative;
        img {
            position: absolute;
            top: -58px;
            transform: rotate(-12.5deg);
            right: -20px;
        }
    }
}

$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.sidebar {
    position: relative;
    background-color: $blue;
    border: 1px solid $blueStroke;
    border-radius: 2px;
    height: 100%;
    padding-top: 72px;
   
    &__collapse {
        display: flex;
        align-items: center;
        height: 43px;
        width: calc(100% + 2px);
        padding-top: 1px;
        padding-inline: 21px 12px;
        margin-inline-start: -1px;
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
        text-transform: uppercase;
        color: $wh;
        background-color: $red;
        border-bottom: 1px solid $yellow;
        cursor: pointer;

        &::after {
            content: '';
            width: 28px;
            height: 28px;
            margin-inline-start: auto;
            flex-shrink: 0;
            filter: $whFilter;
            background-image: var(--icon-i-arrow);
            transform: rotateX(0);
            transition: var(--animation-duration) transform;
        }

        &.active {
            &::after {
                transform: rotateX(180deg);
            }
            & + .sidebar__list {
                padding-top: 17px;
                padding-bottom: 21px;
            }
            & + .sidebar__list .sidebar__item {
                height: 44px;
                opacity:1;
            }
            & + .sidebar__list .sidebar__sort {
                height: 100%;
                opacity: 1;
                margin-bottom: 12px;
            }
        }
    }

    &__list {
        padding-top: 0;
        padding-bottom: 0;
        transition-duration: var(--animation-duration);
        transition-property:  padding;
    }
    &__item {
        display: flex;
        align-items: center;
        gap: 13px;

        padding: 0 20px;

        font-size: 16px;
        line-height: 16px;
        text-decoration: none;
        color: $blueGray;

        background-image: var(--icon-i-arrow);
        background-size: 0;


        height: 0 ;
        opacity:0;
        transition-property:  opacity ,  height;
        transition-duration: 0.2s;

        &::before {
            content: '';
            width: 28px;
            height: 28px;
            flex-shrink: 0;

            background-image: inherit;
            background-position: left top;
            background-repeat: no-repeat;
        }

        &:hover,
        &.active {
            color: $yellow;
            background-color: $blueLight;
            &::before { background-position-x: -28px; }
        }
        &.is-provider {
            &::before {
                background-size: contain;
                background-position: center;
            }
        }
    }
    &__item-text {
        flex-grow: 1;
        line-height: 1.1;
        @include ellipsis;
    }
    &__item-amount {
        flex-shrink: 0;
    }
    &__search {
        display: block;
        position: absolute;
        z-index: 1;
        left: -1px;
        top: -1px;
        right: -1px;
    }
    &__empty {
        font-size: 14px;
        line-height: 16px;
        color: $blueGray;
    }
    &__sort {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-column-gap: 8px;
        height: 0;
        padding: 0 12px;
        opacity: 0;
    }
    &__btn {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 0;
        padding: 13px 10px 12px;
        border-radius: 2px;
        background-color: $blueStroke;
        border: none;
        text-align: center;
        cursor: pointer;
        transition: background-color var(--animation-duration);

        &:hover { background-color: #2D394C; }

        &::before {
            content: attr(data-text);
            @include text(16px,19px,400,$blueGray);
            @include ellipsis;
            transition: color var(--animation-duration);
        }

        &::after {
            flex-shrink: 0;
            display: block;
            width: 15px;
            height: 15px;
            background: var(--icon-arr-sort) top 1px center / contain no-repeat;
            transition: transform var(--animation-duration);
        }
        
        &.linda-brands-ordered-by-name-asc,
        &.linda-brands-ordered-by-name-desc,
        &.linda-brands-ordered-by-count-asc,
        &.linda-brands-ordered-by-count-desc {
            &::before { 
                color: $wh; 
            }
            &::after { 
                content: ''; 
            }
        }

        &.linda-brands-ordered-by-count-desc::after,
        &.linda-brands-ordered-by-name-desc::after { 
            transform: rotate(180deg);
        }

        &.linda-brands-ordered-by-name-desc::before {
            content: 'z-A';
        }
    }
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.auth-popup {
    position: relative;
    width: 520px;
    border-radius: 5px;
    border: 1px solid #273242;
    background-color: #162334;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 24px;

    &-wrapper {
        padding-top: 50px;
        padding-bottom: 50px;
        align-items: center;
    }

    &-funid{
        margin-top: 20px;
        display: block;
        &.is-remembered{
            border-bottom: 1px solid #2a394e;
            margin-bottom: 20px;
            padding-bottom: 16px;
        }
        .g-or{
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            &::before,
            &::after{
                content: '';
                content: "";
                background-color: #2A394E;
                width: 100%;
                height: 1px;
            }
            span{
                color: #576477;
                font-size: 16px;
                font-weight: 400;
                margin: 0 10px;
            }
        }
    }

    &__wrap {
        padding: 6px;
        margin-bottom: 12px;
    }

    &__inner {
        padding: 6px;
    }

    &__info {
        padding-bottom: 16px;
        margin-bottom: 20px;
        border-bottom: 1px solid #2A394E;
    }

    &__info--centered {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    &__info-top {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 12px;
        white-space: nowrap;
        max-width: 100%;

        body.rtl-lang & {
            direction: rtl;
        }
    }

    &__info-text {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-align: left;
        flex-wrap: wrap;
        text-align: center;
        color: #A5AFC0;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.2em;
        gap: 5px;
        margin-top: 16px;

        body.rtl-lang & {
            direction: rtl;
        }

        a {
            color: #ECFD00;
            font-size: 18px;
            font-weight: 400;
            line-height: 1.2em;
            text-decoration-line: underline;
        }
    }

    &__info-title {
        color: #ffffff;
        font-family: Roboto Condensed;
        font-size: 32px;
        font-weight: 400;
        line-height: 1em;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        gap: 4px;
        text-wrap: wrap;

        &--truncated {
            flex-shrink: 0;
            overflow: hidden;
            max-width: calc(100% - 45px);
        }

        .is-yellow {
            color: #ECFD00;
        }
    }

    &__info-back {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 32px;
        height: 32px;
        border-radius: 2px;
        border: 1px solid #273242;
        background-color: #162334;
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9InllcyI/Pjxzdmcgd2lkdGg9IjI4IiBoZWlnaHQ9IjI4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik05LjAyNCAxMWw1LjEyNyA1LjA1NkwxOS4zNTIgMTFsMS4wOTIuOTVMMTQuMTUxIDE4IDggMTEuOTUgOS4wMjQgMTF6IiBmaWxsPSIjQTdBRkJFIiBzdHlsZT0iIi8+PC9zdmc+");
        transform: rotate(90deg);
        cursor: pointer;

        body.rtl-lang & {
            scale: -1 1;
        }
    }

    &__close {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--color-b-9, #273242);
        background: var(--color-b-10, #162334);
        width: 32px;
        height: 32px;
        border-radius: 50%;
        position: absolute;
        inset-inline-end: -12px;
        top: -12px;
        cursor: pointer;
        opacity: 1;
    }

    &__close-icon {
        width: 16px;
        height: 16px;
        background-image: var(--icon-i-close);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        filter: brightness(0) saturate(100%) invert(99%) sepia(2%) saturate(7%) hue-rotate(10deg) brightness(106%) contrast(100%);
    }

    &__title {
        margin-bottom: 8px;
        color: #ffffff;
        font-size: 14px;
        font-weight: 400;
        line-height: 1em;
        text-transform: uppercase;
    }

    &__dep {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 40px;
        margin-top: 1px;
        padding: 4px 4px 4px 16px;
        text-align: center;
        border-radius: 0px 0px 2px 2px;
        background-color: rgba(39, 50, 66, 0.50);
        color: rgba(#576477, 0.8);
        font-size: 16px;
        font-weight: 500;
        line-height: 1em;
        text-transform: capitalize;

        body.rtl-lang & {
            direction: rtl;
            padding: 4px 16px 4px 4px;
        }
    }

    &__dep-description {
        display: flex;
        column-gap: 3px;
    }

    &__dep-payment-icon {
        display: flex;
        height: 32px;
        padding: 2px;
        border-radius: 4px;
        background-color: #EFF5FD;
        margin-left: auto;
        min-width: 88px;

        body.rtl-lang & {
            margin-left: initial;
            margin-right: auto;
        }

        img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
        }
    }

    &__forgot-password {
        display: flex;
        align-items: center;
        justify-content: center;

        a {
            color: #ECFD00;
            text-align: center;
            font-size: 18px;
            font-weight: 400;
            line-height: 1.2em;
            text-decoration-line: underline;
        }
    }

    &__submit {
        width: 100%;
    }

    &__diff-account {
        margin-top: 18px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #A5AFC0;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.8em;
    }

    &__diff-account-link {
        color: #ECFD00;
        text-decoration: underline;
        cursor: pointer;
    }

    &__description {
        color: #A5AFC0;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.6em;
        text-align: center;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;

        p {
            width: 100%;
        }
    }

    &__dismiss {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    &__dismiss-link {
        color: #ffffff;
        padding: 16px 0;
        font-size: 18px;
        font-weight: 400;
        text-decoration: underline;
        transition: color .3s ease;

        &:hover {
            color: $yellow;
            text-decoration: none;
        }
        .is-optout-modal &{
            text-transform: uppercase;
        }
    }

    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
    }

    &__paynplay {
        display: block;
        margin-left: -24px;
        margin-right: -24px;
        margin-bottom: -24px;
        margin-top: 36px;
        background-color: #576477;
        border-radius: 0px 0px 5px 5px;
        overflow: hidden;
    }

    &__paynplay-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        min-height: 68px;
        color: #ECFD00;
        font-size: 24px;
        font-weight: 400;
        padding: 22px 3px;
        line-height: 1em;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    &__paynplay-inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 8px;
    }

    &__paynplay-text {
        color: #ffffff;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.8em;
    }

    &__paynplay-link {
        color: #ECFD00;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.8em;
        text-decoration-line: underline;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }
    }

    &__form-submit {
        width: 100%;
    }

    &__login {
        position: static;
    }

    .popup-reg-container {
        &.hide {
            display: none;
        }
    }

    &__form {
        display: grid;
        gap: 20px;
        width: 100%;

        .form-box {
            margin: 0;
        }

        .form-show-pass {
            width: 20px;
            height: 20px;
            top: 37px;
            inset-inline-end: 16px;
        }

        .form-select {
            background-size: 34px;
            background-position: right 8px center;
            background-color: #273242;
        }

        body.rtl-lang & {
            .form-select {
                background-position: 8px 50%;
            }
        }

        &.ng-invalid {
            .btn {
                 pointer-events: none;

                &::after {
                    background: #a5afc0;
                }
            }
        }
    }


    body.rtl-lang & {
        direction: rtl;
    }
}

$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.selfcontrol-deposit-form {

  &__input-box {
    min-height: 107px;
    position: relative;
  }

  &__input {
    &.ng-invalid.ng-dirty {
      color: $pink;
      background-image: var(--icon-i-error);
    }
  }


  &__increase-message {
    padding-top:8px;
    @include text(12px, 15px, 400, #EFF5FD);
    &.is-red { color: $pink; }

    &.ng-hide {
      opacity: 0;
      position: absolute;
    }
    &.ng-hide-add,
    &.ng-hide-remove {
      position: absolute;
      bottom: 0;
    }

  }

  & .ng-invalid:focus + .form-label .form-error { display: flex !important; }
}

$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.sport-tournament-page {
  max-width: 1613px;
  &__grid {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 324px;
    grid-gap: 0 12px;
    margin-top: 24px;
    &.has-margin-bottom-56 {
      margin-bottom: 56px;
    }
    &:last-of-type {
      margin-top: 12px;
    }
    @include s {
      grid-template-columns: 1fr 420px;
    }
  }

  &__promos {
    display: grid;
    gap: 20px;
    margin-top: 12px;
  }

  body.rtl-lang & {
    direction: rtl;
  }
}

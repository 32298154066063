$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.form-tooltip {
  display: none;

  position: absolute;
  right: 0;
  width: 216px;
  bottom: calc(100% - 17px);
  //transform: translateY(-50%);

  background: $red;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  padding: 20px;


  &::before {
    content: '';
    position: absolute;
    bottom: -8px;
    left: calc(50% - 8px);
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-top: 8px solid $red;

  }

  & p {
    font-size: 14px;
    line-height: 16px;

    &::before {
      content: '• '
    }

    &+p {
      margin-top: 7px;
    }
  }
}


.has-tooltip:focus+.form-label .form-tooltip {
  display: block;
}

.has-tooltip+.form-label {

  & .g-tooltip-min,
  & .g-tooltip-number,
  & .g-tooltip-low,
  & .g-tooltip-up {
    text-decoration-line: line-through;
    color: #FFFFFF;
  }
}

.has-tooltip.ng-valid-min+.form-label .g-tooltip-min,
.has-tooltip.ng-valid-number+.form-label .g-tooltip-number,
.has-tooltip.ng-valid-low+.form-label .g-tooltip-low,
.has-tooltip.ng-valid-up+.form-label .g-tooltip-up {
  text-decoration: none;
  color: $yellow;
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.card-team {
  width: 100%;
  height: 100%;
  position:relative;
  display: block;

  &::before,
  &::after {
    content: '';
    position:absolute;
    display: block;
    width: 60%;
    padding-top:100%;
    border-radius: 4px;
    border:solid 6px #162334;
    background: url( $cdn + "/rabona/cards-page/card-fon.jpg");
    z-index: 22;
    transition: 0.3s;
    transform-origin: 50% 70%;
  }

  &::after {
    top:7%;
    right: 10px;
    transform: rotate(7deg);

    .in-profile & { right: 27px; }
  }

  &::before {
    top: 35px;
    left: 10px;
    transform: rotate(-7deg);
    .in-profile &{ left: 27px; }
  }
  &:hover{
    &::after{ transform: rotate(10deg);}
    &::before{ transform: rotate(-10deg);}
  }

  &.is-inactive {
    .card-team__img,
    .card-team__logo {
      filter: grayscale(1);
    }
  }


  &__bg{
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc( 100% - 100px);
    background-color: $red;
    background-image: url( $cdn + "/rabona/cards-page/pattern-collections.png");
    background-size: 200px;
    border-radius: 5px;
    transition: 0.3s;
    z-index: 20;
    .card-team:hover &{ transform: translateY(7%);}
    .card-team.is-inactive & { background-color: #2A394E; }
  }

  &__img{
    position:absolute;
    width: 100%;
    padding-top:160%;
    z-index:23 ;
    transition: 0.3s;
    transform: translateY(-4%);
    .card-team:hover &{ transform: translateY(-7%);}


    background-image: url( '/img/paulo-dybala.png');
    background-size: contain;
    background-position: center top;
    background-repeat: no-repeat;
  }

  &__panel{
    position:absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    //height: 307px;
    height: 56%;

    background-image: url( $cdn + "/rabona/cards-page/down-card.png") ;
    background-size: 100% auto;
    background-position: right top;
    background-repeat: no-repeat;

    z-index: 24;
    border-radius: 0 0 4px 4px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding:0 18px 20px;

  }

  &__label{


    position: absolute;
    top: 42%;
    width: 95%;
    height: 31%;
    left: -3%;
    z-index: 30;
    clip-path: polygon(0 57%, 100% 0, 100% 39%, 3% 96%, 3% 100%, 0 98%);
    background-image: linear-gradient(160.5deg, #EFFB37 0%, #EFFB37 calc(64% - 1px), #686D0F 40%);


    .card-team.is-inactive & { background-image: linear-gradient(160.5deg, #A7AFBE 0%, #A7AFBE calc(64% - 1px), #2D394C 40%); }

    .in-profile &{
      top: 40%;
      background-image: linear-gradient(160.5deg, #EFFB37 0%, #EFFB37 calc(62% - 1px), #686D0F 40%);
      height: 32%;
    }
  }

  &__logo{
    width: 20%;
    padding-top: 25%;
    position: absolute;
    top: 56%;
    left: 1%;
    z-index: 37;

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    .in-profile &{ top: 55%; }
    .in-hall &{ padding-top: 29%; }
  }

  &__name {
    position: absolute;
    top: 57.6%;
    left: 20%;
    width: 69%;
    height: 8%;

    z-index: 36;

    display: flex;
    align-items: center;

    transform-origin: 0 0;
    transform: rotate(-21deg);
    transition:0.3s;
    transition-timing-function: ease-in-out;
    font-family: $ff;
    font-weight: 700;
    text-transform: uppercase;
    color:#000;
    font-size: 26px;
    line-height: 1.05;

    span {
      transform: skewX(-20deg);
    }

    .card-team:hover &{  transform: rotate(-21deg)  translate(5% , 1%)}

    .in-profile &{ top: 57%; transform: rotate(-22deg); }
  }

  &__btn {
    width: 100%;
    transition: 0.3s;
    transition-timing-function: ease-in-out;

    .btn {
      body.rtl-lang & {
        font-size: 15px;

        @include s {
          font-size: 16px;
        }

        @include m {
          font-size: 20px;
        }
      }
    }

    .card-team:hover &{ margin-bottom:4px;}
  }

  &__progress {
    background-color: #0C1221;
    height: 8px;
    width: 100% ;
    margin-bottom: 21px;
    background-image: linear-gradient($yellow , $yellow);
    background-position: left center;
    background-repeat: no-repeat;

    body.rtl-lang & {
      scale: -1 1;
    }
  }

  &__sum{
    color: $yellow;
    text-shadow: 0px 0.743119px 2.22936px rgba(0, 0, 0, 0.5);
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    text-transform: uppercase;
    margin-bottom:4px;

    .card-team.is-inactive &{ color: #A5AFC0;}
  }

  &__complete {
    @include text(24px, 30px, 700, $yellow);
    position: relative;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-left: 30px;

    &::before {
      content: '';
      display: block;
      background-image: var(--icon-choose);
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      background-size: 80%;
      top: 2px;
      left: -1px;
      width: 26px;
      height: 26px;
    }
  }

  &__stub-text{
    text-align: center;
    margin-bottom:40px;
    font-size: 22px;
    color:#fff;
  }



  @include m {
    .in-hall &{
      & .card-team{
        &__name{ font-size: 18px; line-height: 30px;}
        &__complete{
          font-size: 32px;
          line-height: 37.5px;
          margin-bottom: 22px;
          padding-left: 36px;
          &::before {
            width: 32px;
            height: 32px;
          }
        }
        &__progress{ height: 10px; margin-bottom:32px;}
        &__sum{ font-size: 64px; line-height: 75px; margin-bottom:30px;}
        &__stars{ right: 45px; top: 57%; }
      }
    }
  }

}


















//.card-team{
//
//  width: 100%;
//  height: 100%;
//  position:relative;
//
//  &::before,
//  &::after{
//    content: '';
//    position:absolute;
//    display: block;
//    width: 221px;
//    height: 329px;
//    border-radius: 4px;
//    border:solid 6px #162334;
//    background: url( $cdn + "/rabona/cards-page/card-fon.jpg");
//    z-index: 22;
//    transition: 0.3s;
//    //transition-timing-function: ease-in-out;
//  }
//  &::after{
//    top: 32px;
//    right: 10px;
//    transform: rotate(10deg);
//  }
//  &::before{
//    top: 35px;
//    left: 10px;
//    transform: rotate(-10deg);
//  }
//  &:hover{
//    &::after{ transform: rotate(14deg);}
//    &::before{ transform: rotate(-14deg);}
//  }
//
//
//  &__bg{
//    position:absolute;
//    top: 0;
//    left: 0;
//    width: 100%;
//    height: calc( 100% - 100px);
//    background-color: #2A394E;
//    background-image: url( $cdn + "/rabona/cards-page/pattern-collections.png");
//    background-size: 200px;
//    border-radius: 5px;
//    transition: 0.3s;
//    z-index: 20;
//    //transition-timing-function: ease-in-out;
//    .card-team:hover &{ transform: translateY(15px);}
//  }
//
//  &__img{
//    position:absolute;
//    top: -25px;
//    left: calc(50% - 151px);
//    width: 303px;
//    height: 374px;
//    z-index:23 ;
//    transition: 0.3s;
//    .card-team:hover &{ transform: translateY(-10px);}
//    background-image: url( '/img/paulo-dybala.png');
//    background-size: contain;
//    background-position: center bottom;
//    background-repeat: no-repeat;
//  }
//
//  &__panel{
//    position:absolute;
//    bottom: 0;
//    left: 0;
//    width: 100%;
//    height: 307px;
//    background-image: url( $cdn + "/rabona/cards-page/down-card.png") ;
//    background-size: 100% auto;
//    background-position: right bottom;
//
//    z-index: 24;
//    border-radius: 0 0 4px 4px;
//    clip-path: polygon(0 105px, 100% 0, 100% 100%, 0 100%);
//    display: flex;
//    flex-direction: column;
//    justify-content: flex-end;
//    align-items: center;
//    padding:0 18px 20px;
//
//  }
//
//  &__label{
//    position: absolute;
//    background: rgba(0, 0, 0, 0.5);
//    top: 286px;
//    left: 5px;
//    height: 81px;
//    width: 248px;
//    z-index: 30;
//    transform: skew(-24deg) rotate(-23.8deg);
//    background: #A7AFBE;
//    &::before{
//      content: '';
//      display: block;
//      width: 0;
//      height: 0;
//      position: absolute;
//      bottom: -8px;
//      left: 0px;
//      border-top: solid 3px transparent;
//      border-bottom: solid 7px transparent;
//      border-right: solid 6px #2D394C;
//      transform: skew(24deg) rotate(23.8deg);
//    }
//  }
//
//  &__logo{
//    width: 76px;
//    height: 76px;
//    position:absolute;
//    top: 323px;
//    left: 0px;
//    z-index: 30;
//    background-size: contain;
//    background-position: center;
//    background-repeat: no-repeat;
//  }
//  &__name{
//
//    position:absolute;
//    top: 276px;
//    left: 80px;
//    height: 70px;
//    width: 180px;
//    z-index: 36 ;
//    transform: rotate(-20deg);
//    display: flex;
//    align-items: center;
//    transition:0.3s;
//    transition-timing-function: ease-in-out;
//
//
//    font-family: Rabona;
//    color:#000;
//    font-size: 12px;
//
//
//    .card-team:hover &{  transform: rotate(-20deg)  translate(20px , 8px)}
//
//
//
//  }
//
//  &__btn{
//    width:100%;
//    transition: 0.3s;
//    transition-timing-function: ease-in-out;
//    .card-team:hover &{ margin-bottom:4px;}
//  }
//  &__progress{
//    background-color: #0C1221;
//    height: 8px;
//    width: 100% ;
//    margin-bottom: 21px;
//  }
//
//  &__sum{
//    color: #A5AFC0;
//    text-shadow: 0px 0.743119px 2.22936px rgba(0, 0, 0, 0.5);
//    font-weight: bold;
//    font-size: 48px;
//    line-height: 56px;
//    text-transform: uppercase;
//    margin-bottom:4px;
//  }
//
//
//  @include s{
//
//    &::before,
//    &::after{ width: 226px; height: 339px; }
//    &__img{ width: 315px; height: 389px; left: calc(50% - 157px); }
//    &__label{ top: 305px; }
//    &__name{ top: 295px; }
//    &__logo{ top: 343px; }
//    &__panel{ height: 320px; }
//  }
//
//  @include m{
//    &::before,
//    &::after{ width: 309px; height: 460px; }
//    &::after{ top: 50px; right: 20px; }
//    &::before{ top: 45px; left: 20px; }
//
//    &__bg{
//      .card-team:hover &{ transform: translateY(25px);}
//    }
//    &__img{ width: 424px; height: 524px; left: calc(50% - 212px); }
//
//    &__label{
//      top: 408px;
//      width: 350px;
//      height: 100px;
//      &::before{
//        bottom: -11px;
//        border-top: solid 5px transparent;
//        border-bottom: solid 9px transparent;
//        border-right: solid 10px #2D394C;
//      }
//    }
//    &__name{
//      top: 395px;
//      height: 86px;
//      width: 250px;
//      left: 110px;
//      font-size: 18px;
//      line-height: 30px;
//    }
//    &__logo{
//      top: 452px;
//      width: 107px;
//      height: 107px;
//    }
//    &__panel{ height: 431px; padding:0 20px 32px; }
//    &__progress{ height: 10px; margin-bottom:32px;}
//    &__sum{ font-size: 64px; line-height: 75px; }
//
//  }
//
//}

$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;


.profile{
  width: calc(100% - 48px);
  margin: 48px auto;
  display: flex;
  max-width: 1527px;
  min-height: 1016px;

  body.rtl-lang & {
    direction: rtl;
  }

  &__sidebar{
    background: #192333;
    width: 209px;
    flex-shrink: 0;
    overflow: hidden;
    padding-bottom:130px;

  }
  &__content {
    position:relative;
    background: #192333;
    flex-grow: 1;
    margin: 12px 0;
    border-top: solid 1px $red;
    border-bottom: solid 1px $red;
    z-index: 10 ;
    padding:30px 20px;
    &.is-white{
      background: $wh;
    }
    &::before{
      content: '';
      display: block;
      position:absolute;
      width: 6px;
      height: calc(100% + 2px);
      top: -1px;
      inset-inline-start: 0;
      background:rgba(12, 18, 33, 0.6);
      z-index: 21;
    }

  }
  @include s{
    width: calc(100% - 84px);
    &__content{
      padding:36px 48px;
    }
  }
  @include m{
    &__sidebar{
      width: 280px;
    }
  }
}

$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.cash-crab-guide {
    background: #020F20;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.35);
    box-sizing: border-box;
    align-self: baseline;
    position: relative;
    padding: 56px 20px;
    margin: 0 auto;
    width: calc(100% - 80px);
    min-width: 944px;
    max-width: 1240px;
    &__title {
        font-weight: 800;
        font-size: 28px;
        line-height: 34px;
        text-align: center;
        letter-spacing: 0.05em;
        color: #FFFFFF;
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    }
    &__prizes {
        display: flex;
        justify-content: center;
        margin: 50px 0 40px 0;
    }
    &__section {
        display: flex;
        justify-content: center;
        margin: 20px auto;
    }
    &__box {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
        //min-width: 50%;
        //max-width: 800px;
        margin: 0 60px;
        &.is-1 {
            width: 420px;
        }
        &.is-2 {
            width: 520px;
        }
        &.is-3 {
            width: 770px;
        }
        img {
            margin: 0 20px 0 0;
        }
        a {
            color: white;
            font-weight: 700;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
        &.has-column {
            flex-direction: column;
            img {
                margin: 0 0 3px;
            }
        }
    }
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.has-txt-crab {
  color: #fff;
}

.has-txt-crab-free {
  color: #EFFB37 !important;
}

.has-icon-crab {
  &::after {
    content: '';
    display: none;
  }
}

.has-icon-crab-free {
  &::after {
    content: '';
    width: 29px;
    height: 12px;
    position: relative;
    background-image: url($cdn + '/rabona/crab/icon-free.png');
    background-size: cover;
    display: flex;
    margin: 0;
    top: -1px;
    left: 4px;
  }
}
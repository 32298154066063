$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.tournament-welcome-popup {
  position: relative;
  max-width: 564px;
  width: 100%;
  margin: 0 auto;
  background: #162334;
  padding: 24px 0;
  text-align: center;

  &__title {
    font-size: 28px;
    line-height: 48px;
    color: #FFF;
    text-transform: uppercase;
    margin-bottom: 8px;
    padding: 0 70px;
  }

  &__text {
    font-size: 16px;
    color: #A5AFC0;
    line-height: 140%;
    margin-bottom: 22px;
  }

  .image-popup {
    position: relative;
    width: 100%;
    max-width: 300px;
    height: 244px;
    background-size: cover;
    background-position: 50% 50%;
    padding: 20px;
    display: flex;
    align-items: flex-end;
    overflow: hidden;
    margin-bottom: 24px;
    margin-left: auto;
    margin-right: auto;

    &::before {
      content: '';
      width: 100%;
      height: 48px;
      pointer-events: none;
      z-index: 3;
      background: linear-gradient(0deg, #162334 0%, #24242400 100%);
      position: absolute;
      bottom: 0;
      left: 0;
      pointer-events: none;
    }

    &__img {
      position: absolute;
      width: 340px;
      z-index: 2;
      bottom: 0;
      right: -55px;
      max-width: none;
    }

    &__img-2 {
      position: absolute;
      z-index: 1;
      top: -100px;
      right: -190px;
      width: 550px;
      max-width: none;
    }

    &__content {
      position: relative;
      z-index: 3;
      display: flex;
      flex-direction: column;
      row-gap: 5px;
      font-size: 13px;
      font-weight: 500;
      line-height: 1;
      color: #fff;
      text-transform: uppercase;
      text-align: left;
      text-shadow: 0 4px 12px rgba(0, 1, 34, 0.50);
    }

    // &__title {
    //   font-size: 32px;
    //   font-weight: 700;
    // }

    &__prize {
      font-size: 52px;
      color: #ECFD00;
      line-height: 1;
      text-transform: uppercase;
      font-weight: 700;
    }
  }

  &__close-btn {
    position: absolute;
    top: 32px;
    inset-inline-end: 32px;
    width: 32px;
    height: 32px;
    filter: contrast(0) brightness(2.2);
    cursor: pointer;
    transition: opacity .2s ease;

    &:hover {
      opacity:  .7;
    }
  }

  &__btns {
    width: 100%;
    max-width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 24px;
    margin-left: auto;
    margin-right: auto;
  }

  &__btn {
    width: 100%;
    font-size: 24px;
    padding: 20px;
    min-width: 320px;
    line-height: 1;
  }

  &__btn-details {
    padding: 16px 0;
    text-decoration: underline;
    color: #FFF;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.14px;
    text-transform: uppercase;
  }
}
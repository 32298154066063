$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.top-menu-ico-cash-crab {
  position: relative;
}
@media (min-width: 768px) and (max-width: 1200px) {
  .top-menu {
    &__list {
      justify-content: flex-start !important;
      padding: 0 !important;
    }
  }
}
@media (min-width: 768px) {
  .top-menu {
    &__list {
      overflow: auto;
      width: calc(100% - 60px);
      margin: 0 0 0 60px;
    }
  }
}
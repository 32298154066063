$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.cashbox-qamount{

  display: flex;
  flex-wrap: wrap;
  margin:10px -10px 0;

  &__item{
    body.rtl-lang & {
      text-align: center;
    }
    width: 120px;
    height: 44px;
    line-height: 42px;
    background: #F0F5FC;
    border: 1px solid #A7AFBE;
    font-weight: bold;
    font-size: 18px;
    color: #0C1221;
    text-align: center;
    cursor:pointer;
    margin:10px;

    &:hover{
      line-height: 40px;
      border:solid 2px $yellow;
    }
    &.is-active{
      background: $yellow;
      line-height: 40px;
      border: 2px solid $yellow;

    }
  }

}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.tournament-info {
    @include combine(--pad-l-r, 32px, 53px, 39px);
    @include combine(--pad-t, 20px, 25px, 30px); 

    position:  relative;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: 
        'main'
        'board'
        'games';
        
    padding: var(--pad-t) var(--pad-l-r) 0 var(--pad-l-r);
    
    border-top: 2px solid $red;
    border-bottom: 2px solid $red;
    background-repeat: no-repeat;
    background-size: auto 310px;
    background-position: center top;
    background-color: $blue;

    &::before {
        content: '';
        position: absolute;
        inset-inline-start: 0;
        top: -2px;
        bottom: -2px;
        width: 6px;

        background-color: rgba($blueDark, .6);
    }

    @include s {
        grid-template-areas: 
            'main board'
            'games games';
        grid-template-columns: 1fr 340px;
        background-size: contain;
    }

    @include m {
        grid-template-columns: 1fr 410px;
    }

    &__main {
        grid-area: main;
        display: flex;
        flex-direction: column;
        align-items: center;

        margin-bottom: 40px;
        @include s {
            margin-bottom: 65px;
        }
    }
    &__board {
        grid-area: board;
        margin-bottom: 45px;
        @include s {
            margin-bottom: 65px;
        }
    }
    &__games {
        grid-area: games;
        display: flex;
        flex-direction: column;
        @include combine(margin-bottom, 42px, 60px, 80px);
    }

    &__back {
        position: absolute;
        top: 26px;
        inset-inline-start: var(--pad-l-r);
        @include s{top: 31px}

        .sport-tournament-page & {
            inset-inline-start: 26px;
        }
    }

    &__counter-title {
        font-size: 14px;
        font-weight: 700;
        line-height: 17px;
        text-transform: uppercase;
        text-align: center;
        color: $wh;

        margin-bottom: 12px;
    }

    &__counter {
        margin-bottom: 25px;
    }

    &__title {
        font-size: 28px;
        line-height: 34px;
        text-transform: uppercase;
        color: $wh;

        margin-bottom: 8px;
        &.is-ended{ margin-top:40px;}
    }
    &__description {
        font-size: 18px;
        line-height: 27px;
        color: $wh;

        width: 550px;
        margin-bottom: 20px;

        @include m {
            width: 616px;
        }
    }

    &__terms {
        font-size: 18px;
        font-weight: 700;
        line-height: 38px;
        text-transform: capitalize;
        text-decoration: underline;
        color: $wh;

        margin-bottom: 13px;
        cursor: pointer;

        @include m {
            margin-bottom: 23px;
        }

        &:hover {
            color: $yellow;
        }

        &.loading {
            pointer-events: none;
        }
    }

    &__cards {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px 20px;
        
        @include combine(width, 100%, 550px, 616px);
    }
    &__card {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 44px;
        padding-left: 40px;
        padding-right: 16px;

        font-size: 16px;
        line-height: 16px;
        color: $wh;

        background-color: rgba($blueDark, .7);
        background-repeat: no-repeat;
        background-position: 6px 9px;
        background-size: 28px;
        body.rtl-lang & {
            direction: rtl;
            padding-left: 16px;
            padding-right: 40px;
            background-position: right 6px top 9px;
        }
    }

    &__games-title {
        margin: 0 auto 20px auto;
    }

    &__grid {
        --col: 3;
        --gap: 20px;

        display: grid;
        grid-template-columns: repeat(var(--col), 1fr);
        gap: var(--gap);

        @include s {
            --col: 4;
            --gap: 32px;
        }
        @include m {
            --col: 5;
        }
    }
}

$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.profile-rightbar{
  position:absolute;
  width: 312px;
  height: 100%;
  top: 0;
  inset-inline-end: 0;

  background-color: #1E2737;

  background-position: 7px 5px;


  &__panel{
    display: flex;
    align-items: center;
    height: 84px;
    background: #293241;
    padding:18px 20px;
    &.is-first{
      height: 100px;
      background: #2D394C;
      border-bottom:solid 2px $yellow;
    }
  }

  &__section{
    height: 100%;
    min-width: 33.3334%;
    flex-grow: 1;
    flex-shrink: 0;
    padding-right:5px;
    body.rtl-lang & {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
  &__label{
    font-size: 10px;
    line-height: 24px;
    color: #A7AFBE;
    text-transform: uppercase;
    font-weight: bold;
  }
  &__bigsum{
    margin-top:6px;
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
    color: #ECFD00;
    display: flex;
    gap: 4px;
  }
  &__bigsum-currency {
    body.rtl-lang & {
      order: 1;
    }
  }
  &__sum{
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    margin-top:4px;
    display: flex;
    gap: 2px;
    body.rtl-lang & {
      direction: rtl;
      text-align: right;
    }
  }
  &__sum-currency {
    body.rtl-lang & {
      order: 1;
    }
  }
  &__update{
    width: 26px;
    height: 26px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-right:8px;
    filter: $whFilter;
    cursor:pointer;
    &.linda-has-preloader{
      animation: rotate360 linear 0.7s infinite;
    }
  }


  &__title{
    margin:35px auto;
    font-weight: bold;
    font-size: 32px;
    line-height: 37px;
    text-align: center;
    text-transform: uppercase;
    color: $yellow;
  }

  &__card{
    width: 311px;
    height: 575px;
    margin:10px auto;
  }

  @include s{
    width: 324px;
    background-position: 2px 5px;
  }
  @include m{
    width: 384px;
  }
}

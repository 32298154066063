$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.swiper-slide {
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    box-sizing: border-box;
  }

$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.collection-popup {
    width: 532px;
    padding: 24px 64px 36px;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    background-color: $blue;

    &__title {
        @include text(38px, 44px, 400, $yellow);
        margin-bottom: 24px;
        padding-bottom: 0;
    }

    &__box {
        padding: 16px 18px;
        border: 1px solid $wh;
        @include text(24px, 28px, 400, $wh);
        text-align: center;
        margin-bottom: 24px;

        span {
            color: $yellow;

            &.is-uppercase {
                text-transform: uppercase;
            }

            &.is-block {
                display: block;
            }
        }
    }

    .card-team {
        width: 260px;
        height: 367px;
        margin: 0 auto;

        &:hover {
            .card-team__name {
                transform: rotate(-17deg) translate(5%, 1%)
            }
        }

        &__panel {
            padding: 0 16px 30px;
        }

        &__label {
            width: 102%;
            height: 142px;
            top: 38%;
        }

        &__name {
            transform: rotate(-17deg);
            top: 58%;
            left: 23%;
        }

        &__logo {
            left: 0;
        }

        &__sum {
            font-size: 60px;
        }
    }

    &__bg {
        height: 164px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20'%3E%3Ccircle cx='10' cy='10' r='1' fill='%23DA6436' /%3E%3C/svg%3E");
        background-repeat: repeat;
        background-position: center top;
        background-color: $red;

        &::before {
            content: '';
            position: absolute;
            top: -100px;
            left: 0;
            width: 100%;
            height: 100px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20'%3E%3Ccircle cx='10' cy='10' r='1' fill='%23DA6436' /%3E%3C/svg%3E");
            background-repeat: repeat;
            background-position: center top;
            background-color: $red;
            clip-path: polygon(100% 100%, 0 100%, 100% 0);
        }
    }

    &__btn {
        margin: 20px auto 0;
    }
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.form-check {
  display: none;

  &__label {
    display: flex;

  }

  &__box {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    margin-right: 8px;
    cursor: pointer;
    border-radius: 2px;
    background-color: #293241;
    border: 1px solid #2D394C;
  }

  &__text {
    padding-top: 2px;
    font-size: 14px;
    line-height: 1.4em;
    color: #A5AFC0;

    & a {
      font-size: 14px;
      line-height: 1.4em;
      color: $yellow;
      margin: 0 2px;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

}


.form-check:checked+label .form-check__box {
  border: solid 1px $yellow;
  background-color: $yellow ;
  background-image: var(--icon-i-choose);
  background-position: center 30%;
}

.form-check.ng-invalid.ng-touched+label,
.form-check.ng-invalid.ng-dirty+label {
  & .form-check__box {
    border: solid 1px $pink;
  }
}

body.rtl-lang .form-check {
  &__box {
    margin-left: 19px;
    margin-right: 0;
  }

  &__label {
    direction: rtl;
  }
}
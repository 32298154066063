$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.verification {
    width: 420px;
    padding: 14px 12px 14px 52px;
    font-size: 16px;
    line-height: 20px;
    background-image: var(--icon-info);
    background-size: 28px;
    background-repeat: no-repeat;
    background-position: 14px center;
    color: #2A394E;
    &__link {
        text-decoration: underline;
    }
    &.is-disabled {
        pointer-events: none;
    }
    &.is-yellow {
        background-color: $yellow;
    }
}

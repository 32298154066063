$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
.form-show-pass {
  position: absolute;
  top: 35px;
  inset-inline-end: 20px;
  width: 24px;
  height: 24px;
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
    font-weight: normal
}

ul {
    list-style: none
}

button,
input,
select,
textarea {
    margin: 0;
    font-family: inherit;
}

html {
    box-sizing: border-box
}

body {
    user-select: none;
}

*,
*::before,
*::after {
    box-sizing: inherit
}

img,
video {
    height: auto;
    max-width: 100%
}

iframe {
    border: 0
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

td,
th {
    padding: 0
}

:focus {
    outline: 0;
}

/*ng-style*/
[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
    display: none;
}

a,
button,
select {
    cursor: pointer;
}

//.asb-application * {
//  all: revert;
//}
//[name="asb-banners"] * {
//    all: inherit !important;
//}
//._asb_page-column-center [name="asb-banners"]:first-child {
//    height: 190px;
//}
$build: 1741784260679;
$cdn: "/joxi";
$cdnProject: undefined;
////////////////////////
// .btn initial height - 48px 
// .btn_h-28
// .btn_h-56
// .btn_h-64
// .btn_h-56-64   -default 56px , bp s+ 64-
// .btn_full      width 100% text overflow


// btn_t - transparent button with white border

////////////////////////

.btn {
    --btnHeight: 48px;
    --animationHeight: -3px;
    --animationDuration: .15s;
    --parallelogram: 21px;
    --parallelogramNegative: -21px;
    --degree: 115deg;
    --mainColor: #{$yellow};
    --secondaryColor: #{$cyan};

    position: relative;
    display: inline-block;
    padding: 0 20px;
    max-width: 100%;
    min-width: 160px;

    text-align: center;
    font-size: 20px;
    line-height: var(--btnHeight);
    text-transform: uppercase;
    color: $btnBlack;

    border: none;
    border-radius: 1px;
    background-color: transparent;
    z-index: 1;
    cursor: pointer;

    overflow: hidden;
    white-space: nowrap;
    text-decoration: none;
    text-overflow: ellipsis;
    // sizes
    &_h-28 {
        --parallelogram: 10px;
        --parallelogramNegative: -10px;
        --degree: 111deg;
        --btnHeight: 28px;

        padding: 0 8px;
        min-width: 120px;

        font-size: 14px;

    }

    &_h-56 {
        --degree: 111deg;
        --btnHeight: 56px;
    }

    &_h-64 {
        --btnHeight: 64px;
        --degree: 108deg;
        --parallelogram: 21px;
        --parallelogramNegative: -21px;
        font-size: 24px;
    }

    &_h-56-64{
        --degree: 111deg;
        --btnHeight: 56px;
        @include s{
            --btnHeight: 64px;
            --degree: 108deg;
            --parallelogram: 21px;
            --parallelogramNegative: -21px;
            font-size: 24px;
        }
    }

    &_font20 {
      font-size: 20px;
    }

    &_font15 {
      font-size: 15px;
    }

    &_w-auto {
        width: auto;
        min-width: 0;
    }
    &_full{
        width: 100%;
        min-width: 0;
    }
    &_t {
        color: $wh;
        transition: var(--animationDuration) color;
        --mainColor: transparent;
        --secondaryColor: #{$wh};
        &::after {
            color: $wh;
            box-shadow: inset 0px 0px 0px 1px $wh;
        }
        &:hover {
            color: $btnBlack;
        }
    }
    &_t-wide {
        &::after {
            color: $wh;
            box-shadow: inset 0px 0px 0px 2px $wh;
        }
    }
    &_trapezoid {
        overflow: initial;
        &::after {
            border-radius: 0 !important;
            transform: skewX(-27deg);
        }

    }
    &_pnp {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        // &::before {
        //     content: '';
        //     display: inline-flex;
        //     width: 20px;
        //     height: 20px;

        //     margin-right: 10px;
        //     background-image: var(--icon-trustly-white);
        // }

        span{
            max-width: calc(100% - 30px);
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    &_funid{
        --animationHeight: 0;
        
        &.btn{
            border: 1px solid #fff;
            font-size: 20px;
            box-sizing: border-box;
            font-family: Roboto Condensed;

            &::before {
                content: '';
                display: inline-flex;
                width: 40px;
                height: 40px;
                background-image: var(--icon-funid);
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                display: inline-flex;
                position: absolute;
                inset-inline-start: 12px;
                top: 50%;
                margin-top: -20px;
            }
            &::after{
                transition: unset;
                box-shadow: none;
            }
            &:hover {
                color: #fff;
                &::after {
                    animation: fanIdMoveColor 1s;
                    animation-fill-mode: forwards;
                    background: #3F38DD;
                    left: 0;
                }

                &::before {
                    animation: fanIdMoveIcon 1s;
                    animation-fill-mode: forwards;
                }
            }
        }
    }

    // disabled
    &:disabled,
    &.disabled {
        pointer-events: none;
        &::after {
            color: $blueLight;
            background: $blueGray;
        }

    }


    // animation
    &::after {
        content: '';
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;

        transition:
            var(--animationDuration) left,
            var(--animationDuration) top,
            var(--animationDuration) right,
            var(--animationDuration) bottom,
            .2s cubic-bezier(0.31, -0.105, 0.43, 1.59) background-position;

        border-radius: 1px;
        background-color: var(--mainColor);
        background-image: linear-gradient(
            var(--degree),
            var(--mainColor) 0,
            var(--mainColor) var(--parallelogram),
            var(--secondaryColor) var(--parallelogram),
            var(--secondaryColor) calc(50% + var(--parallelogram)),
            var(--mainColor) calc(50% + var(--parallelogram)),
            var(--mainColor) 50%,
            var(--mainColor) 100%
        );
        background-repeat: no-repeat;
        background-size: calc(200% + var(--parallelogram) * 2) auto;
        background-position: calc(100% - var(--parallelogram)) center;
    }

    &:hover::after {
        left: var(--animationHeight);
        top: var(--animationHeight);
        right: var(--animationHeight);
        bottom: var(--animationHeight);

        background-position: var(--parallelogramNegative) center;
    }
    &-transparent{
        background-color: none;
        background-image: linear-gradient(rgba(0,0,0,0) , rgba(0,0,0,0));
        border:none;
        color:#fff;
        text-decoration: underline;
        &::after{ display: none;}

    }
}

body.rtl-lang .btn {
    text-align: center;
    font-weight: 900;
}

@keyframes fanIdMoveColor {
    0% {
      width: 0;
    }
    25% {
      width: 0;
    }
    75% {
      width: 100%;
    }
    100% {
      width: 100%;
    }
}

@keyframes fanIdMoveIcon {
    0% {
        transform: scale(1);
    }
    25% {
        inset-inline-start: 6px;
        transform: scale(1.7);
    }
    75% {
        inset-inline-start: calc(100% - 57px);
        transform: scale(1.7);
    }
    100% {
        transform: scale(1);
        inset-inline-start: calc(100% - 57px);
    }
}
  